import {
  Box,
  Heading,
  Flex,
  Spacer,
  HStack,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import FilterBySearchKey from "../../components/FilterBySearchKey";
import HotelTable from "./HotelTable";
import HotelPaging from "./HotelPaging";
import FilterByPageSize from "../../components/FilterByPageSize";
import SortByCreatedTime from "../../components/SortByCreatedTime";
import FilterByCountry from "../../components/FilterByCountry";

function Hotel() {
  return (
    <Flex direction="column" p={6} h="full">
      <HStack mb={4} spacing={4}>
        <Heading size="md" textTransform="uppercase" color="gray.400">
          Hotel
        </Heading>
        <Spacer />
      </HStack>
      <SimpleGrid
        templateRows="auto 1fr"
        borderRadius="md"
        bg="gray.800"
        flex={1}
        overflow="auto"
      >
        <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
          <Flex mb={2}>
            <HStack>
              <FilterBySearchKey placeholder="Name" />
              <FilterByCountry />
              <SortByCreatedTime />
            </HStack>
            <Spacer />
            <FilterByPageSize />
            <HotelPaging />
          </Flex>
        </Box>
        <Box overflow="auto" flex={1} px={5}>
          <HotelTable />
        </Box>
      </SimpleGrid>
    </Flex>
  );
}

export function HotelWrapper() {
  return (
    <React.Suspense fallback="Loading...">
      <Hotel />
    </React.Suspense>
  );
}

export default HotelWrapper;

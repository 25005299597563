import { Form } from "react-final-form";
import { Suspense } from "react";
import {
  Flex, Stack, Text,
} from "@chakra-ui/react";
import { ReviewDocument } from "src/api/review";
import LabeledSelectPhotos from "src/components/LabeledSelectPhotos";
import LabeledNumberField from "../../components/LabeledNumberField";

interface ApproveReviewPhotoProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: ReviewDocument;
  selectedReview?: ReviewDocument
}

interface IApproveReviewFormError {
  review_content?: string,
  token_number?: string,
  image_ids?: string
}

const validate = (values) => {
  const errors: IApproveReviewFormError = {};

  if (!values.image_ids || values.image_ids.length === 0) {
    errors.image_ids = 'Please select at least an image';
  }

  if (!values.token_number) {
    errors.token_number = 'Please enter token number';
  } else if (+values.token_number < 0) {
    errors.token_number = 'Please enter token number >= 0';
  }

  return errors;
};

function ApproveReviewPhotoForm({
  onSubmit, formId, initialValues, selectedReview,
}: ApproveReviewPhotoProps) {
  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Flex alignItems="flex-start" mb="24px">
            <Flex flex={1}>
              <Text size="sm" color="gray.500" mr="8px">
                User:
              </Text>
              <Text size="md">
                {selectedReview?.full_name}
                {' '}
                (
                {selectedReview?.email}
                )
              </Text>
            </Flex>
            <Flex flex={1}>
              <Text size="sm" color="gray.500" mr="8px">
                Spot:
              </Text>
              <Text size="sm">
                {selectedReview?.spot_name}
              </Text>
            </Flex>
          </Flex>
          <Stack spacing={4}>
            <LabeledNumberField
              name="token_number"
              helperText="Giving token to the user"
              label="Token"
              type="number"
              unit="XTR"
            />

            <LabeledSelectPhotos
              name="image_ids"
              helperText="Select approve photos"
              label="Photos"
              photos={selectedReview?.photos}
            />
          </Stack>
        </form>
      )}
    />
  );
}

function ApproveReviewPhotoFormWrapper({
  onSubmit, formId, initialValues,
  selectedReview,
}: ApproveReviewPhotoProps) {
  return (
    <Suspense fallback="loading...">
      <ApproveReviewPhotoForm
        onSubmit={onSubmit}
        formId={formId}
        initialValues={initialValues}
        selectedReview={selectedReview}
      />
    </Suspense>
  );
}

export default ApproveReviewPhotoFormWrapper;

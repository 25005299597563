import { SpotStatus } from "src/api/spot";
import { TRAN_STATES } from "src/constants/transaction";
import { TripState } from "src/api/trip";

export const renderSpotStatusColor = (status: string) => {
  let color = "";
  switch (status) {
    case SpotStatus.Approved:
      color = "green";
      break;
    case SpotStatus.Rejected:
      color = "red";
      break;
    case SpotStatus.Waiting:
    default:
      color = "gray";
      break;
  }
  return color;
};

export const renderTripStateColor = (status: string) => {
  let color = "";
  switch (status) {
    case TripState.Traveling:
      color = "blue";
      break;
    case TripState.Finished:
      color = "green";
      break;
    case TripState.Cancel:
    default:
      color = "red";
      break;
  }
  return color;
};

export const renderTransferStateColor = (status: string) => {
  let color = "";
  switch (status) {
    case TRAN_STATES.success:
      color = "green";
      break;
    case TRAN_STATES.failed:
      color = "red.300";
      break;
    case TRAN_STATES.pending:
    default:
      color = "yellow.300";
      break;
  }
  return color;
};

export const renderUserStateColor = (state: number) => {
  let color = "";
  switch (state) {
    case 1:
      color = "green";
      break;
    case 2:
      color = "red.300";
      break;
    case 3:
      color = "yellow.300";
      break;
    case 4:
      color = "teal.300";
      break;
    case 0:
    default:
      color = "gray";
      break;
  }
  return color;
};

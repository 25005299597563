export enum TRAN_STATES {
  pending = 'pending',
  success = 'success',
  failed = 'failed',
}

export enum TRAN_FLOW {
  add = 'add',
  sub = 'sub',
}

export enum TRAN_TYPES {
  deposit = 'deposit',
  withdraw = 'withdraw',
  paidForLevelUp = 'paid-level-up',
  paidForRepair = 'paid-repair',
  buyShopItem = 'buy-shop-item',
  travelReward = 'travel-reward',
  spotReward = 'spot-reward',
  mintItems = 'mint-items',
  spotReviewOverview = 'spot-review-overview',
  spotReviewFeedback = 'spot-review-feedback',
  spotReviewPhoto = 'spot-review-photo',
}

export const TRAN_TYPE_OBJ = {
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  'paid-level-up': 'Level up item',
  'paid-repair': 'Repair item',
  'buy-shop-item': 'Buy shop item',
  'travel-reward': 'Travel reward',
  'spot-reward': 'Spot reward',
  'mint-items': 'Mint items',
  'spot-review-overview': 'Review overview',
  'spot-review-feedback': 'Review feedback',
  'spot-review-photo': 'Review photo',
  'spot-review-reward': 'Review reward',
};

export const TRANSACTION_STATE_OBJ = {
  pending: 'Pending',
  success: 'Success',
  failed: 'Failed',
};

export const TRANSACTION_STATE_OPTIONS = [{
  label: 'Pending',
  value: 'pending',
}, {
  label: 'Success',
  value: 'success',
}, {
  label: 'Failed',
  value: 'failed',
}];

export const EARN_TRANSACTION_OPTIONS = [{
  value: 'deposit',
  label: 'Deposit',
}, {
  value: 'travel-reward',
  label: 'Travel reward',
}, {
  value: 'spot-reward',
  label: 'Spot reward',
}, {
  value: 'spot-review-overview',
  label: 'Review overview',
}, {
  value: 'spot-review-feedback',
  label: 'Review feedback',
}, {
  value: 'spot-review-photo',
  label: 'Review photo',
}];

export const SPEND_TRANSACTION_OPTIONS = [{
  value: 'withdraw',
  label: 'Withdraw',
}, {
  value: 'paid-level-up',
  label: 'Level up item',
}, {
  value: 'mint-items',
  label: 'Mint items',
}, {
  value: 'paid-repair',
  label: 'Repair item',
}, {
  value: 'buy-shop-item',
  label: 'Buy shop item',
}];

export const TRANSACTION_TYPE_OPTIONS = [{
  label: 'Earn',
  value: 'add',
}, {
  label: 'Spend',
  value: 'sub',
}];

export const TRANSACTION_TYPE_OBJ = {
  add: 'Earn',
  sub: 'Spend',
};

export const TOKENS = [{
  label: 'XTR',
  value: 'XTR',
}, {
  label: 'GXT',
  value: 'GXT',
}, {
  label: 'AVAX',
  value: 'AVAX',
}];

import React from 'react';
import ShowMoreText from "react-show-more-text";

function MoreText({ numberOfLines, children }: any) {
  return (
    <ShowMoreText
    /* Default options */
      lines={numberOfLines}
      more="Show more"
      less="Show less"
      className="content-css"
      anchorClass="show-more-less-clickable"
      expanded={false}
      width={0}
      truncatedEndingComponent="... "
    >
      {children}
    </ShowMoreText>

  );
}

export default MoreText;

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import { deleteItem, itemKeys } from '../../api/item';

interface ItemModalDeleteProps {
  isOpen: boolean,
  onClose: () => void,
  id: string,
}
function ItemDelete({ isOpen, onClose, id }: ItemModalDeleteProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();
  const handleDelete = async () => {
    try {
      await deleteItem(id);
      onClose();
      toast({ status: "success", description: "Success delete" });
      queryClient.invalidateQueries(itemKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Error" });
    }
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure you want to delete?</ModalHeader>
        <ModalCloseButton />
        <ModalBody />
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="ghost" colorScheme="red" onClick={handleDelete}>Delete</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ItemDelete;

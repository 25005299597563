import React, { Suspense } from "react";
import { REVIEW_TYPE } from "src/constants/review";
import FilterByPage from "../../components/FilterByPage";
import useSearchParams from "../../utils/useSearchParams";
import { useReviews } from "../../api/review";
import { ReviewTableProps } from "./ReviewTable";

function ReviewPaging({ spotId, spotStatus }: ReviewTableProps) {
  const { params } = useSearchParams();
  const { data } = useReviews({
    ...params,
    review_type: REVIEW_TYPE.overview,
    spot_id: spotId,
    spot_status: spotStatus || '',
  });
  return (
    <FilterByPage totalDocs={data?.meta.totalItems} totalPages={data?.meta.totalPages} />
  );
}

function ReviewPagingWrapper({ spotId, spotStatus }: ReviewTableProps) {
  return (
    <Suspense fallback="">
      <ReviewPaging spotId={spotId} spotStatus={spotStatus} />
    </Suspense>
  );
}

export default ReviewPagingWrapper;

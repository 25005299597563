import React, { Suspense, useState } from "react";
import { CellValue, Column } from "react-table";
import Table from "src/components/Table";
import {
  Tooltip,
  Text,
  HStack,
  IconButton,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import SpotModalEdit from "./SpotTypeModalEdit";
import { useSpotTypes, SpotTypeDto } from "../../api/spot";
import useSearchParams from "../../utils/useSearchParams";

function SpotTable() {
  const { params } = useSearchParams();
  const { data } = useSpotTypes(params);
  const [editingData, setEditingData] = useState<SpotTypeDto>();
  const {
    isOpen: isEditOpen,
    onClose: onEditClose,
    onOpen: onEditOpen,
  } = useDisclosure();
  const editSpot = (spot: SpotTypeDto) => {
    onEditOpen();
    setEditingData(spot);
  };

  const columns = React.useMemo<Column[]>(
    () => [
      { Header: "Type Code", accessor: "type_code" },
      { Header: "Type name", accessor: "name" },
      { Header: "Adding Token", accessor: "check_in_token" },
      { Header: "Bonus Token", accessor: "bonus_token" },
      {
        Header: "Allow Check In",
        accessor: "check_in_earn",
        Cell: ({ value }: CellValue) => (
          <Text>{value ? "Allow" : "Not Allow"}</Text>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }: CellValue) => (
          <HStack spacing={4}>
            <Tooltip label="Edit">
              <IconButton
                icon={<Icon boxSize={4} as={FiEdit} />}
                variant="ghost"
                colorScheme="blue"
                aria-label="delete"
                onClick={() => editSpot(row.original as SpotTypeDto)}
              />
            </Tooltip>
          </HStack>
        ),
      },
    ], // eslint-disable-next-line
    []
  );

  return (
    <>
      <SpotModalEdit
        isOpen={isEditOpen}
        onClose={onEditClose}
        editingData={editingData as SpotTypeDto}
      />
      <Table columns={columns} data={data?.items || []} />
    </>
  );
}

function SpotTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <SpotTable />
    </Suspense>
  );
}

export default SpotTableWrapper;

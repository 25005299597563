import React, { Suspense } from "react";
import FilterByPage from "../../components/FilterByPage";
import useSearchParams from "../../utils/useSearchParams";
import { useUserAll } from "../../api/user";

function CustomerPaging() {
  const { params } = useSearchParams();
  const { data } = useUserAll(params);
  return (
    <FilterByPage totalDocs={data?.meta.totalItems} totalPages={data?.meta.totalPages} />
  );
}

function CustomerPagingWrapper() {
  return (
    <Suspense fallback="">
      <CustomerPaging />
    </Suspense>
  );
}

export default CustomerPagingWrapper;

import { useLocation } from "react-router-dom";
import queryString from "query-string";

function useSearchParams() {
  const { search } = useLocation();
  const params = queryString.parse(search);
  return { params };
}

export default useSearchParams;

import { Icon, Tooltip, IconButton } from "@chakra-ui/react";
import { RiDeleteBinLine } from "react-icons/ri";
import { Status } from "src/types/Status";
import { useSearchParams } from "react-router-dom";

function FilterByDeleteStatus() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isDeleted = searchParams.get("isDeleted");
  return (
    <Tooltip label={isDeleted === Status.soft_delete ? "Normal" : "Archived"}>
      <IconButton
        icon={<Icon boxSize={4} as={RiDeleteBinLine} />}
        colorScheme={isDeleted === Status.soft_delete ? "red" : "gray"}
        aria-label="delete"
        onClick={() => {
          if (isDeleted === Status.soft_delete) {
            searchParams.set("isDeleted", Status.active);
          } else {
            searchParams.set("isDeleted", Status.soft_delete);
          }
          setSearchParams(searchParams);
        }}
      />
    </Tooltip>
  );
}

export default FilterByDeleteStatus;

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,

} from "@chakra-ui/modal";
import {
  Text, Button, Flex, Icon, Box,
  Image,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useState } from "react";
import { AiFillStar, AiFillCheckCircle } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import Lightbox from 'react-image-lightbox';
import { DATE_TIME_FORMAT } from "src/constants/date";
import { REVIEW_STATUS, REVIEW_TYPE } from "src/constants/review";
import { ReviewDocument } from "../api/review";
import MoreText from "./MoreText";

interface ReviewDetailProps {
  isOpen: boolean;
  onClose: () => void;
  selectedReview: ReviewDocument | undefined
}

const statusColors = {
  approved: 'green.100',
  rejected: 'red.100',
  waiting: 'yellow.100',
};

function ReviewDetail({ isOpen, onClose, selectedReview }: ReviewDetailProps) {
  const [isOpenImages, setIsOpenImages] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const numberPhotos = selectedReview?.photos?.length || 0;

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Review detail
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex mb="10px">
            <Text size="sm" color="gray.500" mr="8px">
              Created at:
            </Text>
            <Text size="md">
              {dayjs(selectedReview?.created_at).format(DATE_TIME_FORMAT)}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" mb="10px">
            <Flex>
              <Text size="sm" color="gray.500" mr="8px">
                User:
              </Text>
              <Text size="md">
                {selectedReview?.full_name}
                {' '}
                (
                {selectedReview?.email}
                )
              </Text>
            </Flex>
            <Flex>
              <Text size="sm" color="gray.500" mr="8px">
                Spot:
              </Text>
              <Text size="sm">
                {selectedReview?.spot_name}
              </Text>
            </Flex>
            <Flex>
              <Text size="sm" color="gray.500" mr="8px">
                Spot type:
              </Text>
              <Text size="sm">
                {selectedReview?.spot_type}
              </Text>
            </Flex>
            <Flex>
              <Text size="sm" color="gray.500" mr="8px">
                Country:
              </Text>
              <Text size="sm">
                {selectedReview?.country}
              </Text>
            </Flex>
          </Flex>
          {selectedReview?.review_type === REVIEW_TYPE.feedback
            && (
              <Flex mb="10px" alignItems="center">
                <Text size="sm" color="gray.500" mr="8px">
                  Rating number:
                </Text>
                <Text size="md" mr="4px">
                  {selectedReview?.rating_number}
                </Text>
                <Icon as={AiFillStar} color="orange" />
              </Flex>
            )}

          {selectedReview?.review_type !== REVIEW_TYPE.photo
            && (

              <Box mb="10px" alignItems="center">
                <Text size="sm" color="gray.500" mb="10px">
                  Content:
                </Text>
                <MoreText size="md" numberOfLines={3}>
                  {selectedReview?.review_content}
                </MoreText>
                {selectedReview?.content_jp
                  && (
                    <>
                      <Text size="sm" color="gray.500" mt="12px">
                        JP:
                      </Text>
                      <MoreText size="md" numberOfLines={3}>
                        {selectedReview?.content_jp}
                      </MoreText>
                    </>
                  )}
                {selectedReview?.content_en
                  && (
                    <>
                      <Text size="md" color="gray.500" mt="12px">
                        EN:
                      </Text>
                      <MoreText size="md" mb="10px" numberOfLines={3}>
                        {selectedReview?.content_en}
                      </MoreText>
                    </>
                  )}
                {selectedReview?.content_vi
                  && (
                    <>
                      <Text size="sm" color="gray.500" mt="12px">
                        VI:
                      </Text>
                      <MoreText size="md" numberOfLines={3}>
                        {selectedReview?.content_vi}
                      </MoreText>
                    </>
                  )}
              </Box>

            )}

          {selectedReview?.review_type === REVIEW_TYPE.photo
            && selectedReview?.photos?.length > 0
            && (
              <>
                <Text size="sm" color="gray.500" mt="12px" mb="16px">
                  Images:
                </Text>
                <Flex wrap="wrap">
                  {selectedReview?.photos?.map((item, i) => (
                    <Box
                      key={item.id}
                      m="10px"
                      pb="10px"
                      borderRadius="8px"
                      overflow="hidden"
                      onClick={() => {
                        setIsOpenImages(true);
                        setPhotoIndex(i);
                      }}
                    >
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        bgColor={statusColors[item?.status as string]}
                      >
                        {item.status === REVIEW_STATUS.approved
                          && (
                            <>
                              <Icon boxSize={4} as={AiFillCheckCircle} mr="6px" color="green" />
                              <Text color="green">
                                Approved
                              </Text>
                            </>
                          )}
                        {item.status === REVIEW_STATUS.rejected
                          && (
                            <>
                              <Icon boxSize={4} as={MdCancel} mr="6px" color="red.500" />
                              <Text color="red.500">
                                Rejected
                              </Text>
                            </>
                          )}
                        {item.status === REVIEW_STATUS.waiting
                          && (
                            <>
                              <Box w="16px" h="16px" borderRadius="full" mr="6px" bgColor="yellow.500" />
                              <Text color="yellow.500">
                                Waiting
                              </Text>
                            </>
                          )}
                      </Flex>
                      <Image src={item.image} w="200px" mb="10px" />
                    </Box>
                  ))}

                </Flex>
              </>

            )}

        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>

      {isOpenImages && (
        <Lightbox
          mainSrc={selectedReview?.photos[photoIndex].image as string}
          nextSrc={selectedReview?.photos[(photoIndex + 1) % numberPhotos].image as string}
          prevSrc={
            selectedReview?.photos[(photoIndex + numberPhotos - 1) % numberPhotos].image as string
          }
          onCloseRequest={() => setIsOpenImages(false)}
          onMovePrevRequest={() => setPhotoIndex(
            (prev) => (prev + numberPhotos - 1) % numberPhotos,
          )}
          onMoveNextRequest={() => setPhotoIndex(
            (prev) => (prev + 1) % numberPhotos,
          )}
        />
      )}
    </Modal>
  );
}

export default ReviewDetail;

import * as React from "react";
import {
  Box,
  Flex,
  HStack,
  Heading,
  Spacer,
  SimpleGrid,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import FilterByCountry from "src/components/FilterByCountry";
import FilterBySpotType from "src/components/FilterBySpotType";
import FilterBySearchKeyAndSearchType from "src/components/FilterBySearchKeyAndSearchType";
import { SEARCH_TYPE_OPTIONS, SEARCH_TYPE_PLACEHOLDER } from 'src/api/review';
import CreateReview from 'src/components/CreateReview';
import { REVIEW_STATUS } from "src/constants/review";
import SortByCreatedTime from "../../components/SortByCreatedTime";
import ReviewTable from "./ReviewTable";
import ReviewPaging from "./ReviewPaging";
import FilterByPageSize from "../../components/FilterByPageSize";
import FilterBySpotStatus from '../spot/components/FilterBySpotStatus';
// import FilterByReviewType from "./components/FilterByReviewType";

function Review() {
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <Flex direction="column" p={6} h="full">
      <HStack mb={4} spacing={4}>
        <Heading size="md" textTransform="uppercase" color="gray.400">
          Spot Review Feedback
        </Heading>
        <Spacer />
        <Button onClick={onOpen} leftIcon={<AddIcon />}>
          Create review feedback
        </Button>
      </HStack>
      <SimpleGrid
        templateRows="auto 1fr"
        borderRadius="md"
        bg="gray.800"
        flex={1}
        overflow="auto"
      >
        <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
          <Flex mb={2}>
            <HStack>
              <FilterBySearchKeyAndSearchType
                searchTypes={SEARCH_TYPE_OPTIONS}
                searchTypePlaceholder={SEARCH_TYPE_PLACEHOLDER}
              />
              <FilterByCountry />
              <FilterBySpotStatus />
              <FilterBySpotType />
              <SortByCreatedTime />
              {/* <FilterByReviewType /> */}
            </HStack>
            <Spacer />
            <FilterByPageSize />
            <ReviewPaging spotStatus={REVIEW_STATUS.approved} />
          </Flex>
        </Box>
        <Box overflow="auto" flex={1} px={5}>
          <ReviewTable spotStatus={REVIEW_STATUS.approved} />
        </Box>
      </SimpleGrid>

      <CreateReview
        isOpen={isOpen}
        onClose={onClose}
        reviewType="feedback"
      />
    </Flex>
  );
}

export default Review;

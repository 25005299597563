import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTable, useSortBy, Column } from "react-table";

interface DataTableProps {
  columns: Column[];
  data: any[];
  onRowClick?: any
  setRowStyle?: any
}

function DataTable({
  columns, data, onRowClick, setRowStyle = () => { },
}: DataTableProps) {
  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,
  } = useTable({ columns, data }, useSortBy);

  return (
    <Table colorScheme="gray" size="sm" {...getTableProps()}>
      <Thead
        h="14"
        position="sticky"
        zIndex={1}
        top={0}
        bg={useColorModeValue("white", "gray.800")}
      >
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Th {...column.getHeaderProps()} key={index}>
                {column.render("Header")}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr
              _hover={{ bg: "gray.700", shadow: "md" }}
              {...row.getRowProps()}
              style={setRowStyle(row)}
              onClick={() => onRowClick && onRowClick(row)}

            >
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
              ))}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

export default DataTable;

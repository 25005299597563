import React from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  Button,
} from '@chakra-ui/react';

interface ConfirmDialogProps{
  title: string,
  description: string,
  isOpen: boolean,
  onClose: any,
  handleOk: any,
  loading?: boolean
}

function ConfirmDialog({
  isOpen,
  onClose,
  title,
  description,
  handleOk,
  loading,
}: ConfirmDialogProps) {
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {description}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose} ref={cancelRef} colorScheme="transparent">
              Cancel
            </Button>
            <Button
              onClick={handleOk}
              ml={3}
              isLoading={loading}
            >
              Ok
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default ConfirmDialog;

import {
  Box,
  Heading,
  Flex,
  Spacer,
  HStack,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import FilterBySearchKeyAndSearchType from "src/components/FilterBySearchKeyAndSearchType";
import { TRIP_SEARCH_PLACEHOLDER, TRIP_SEARCH_TYPE_OPTIONS } from "src/api/trip";
import TripTable from "./TripTable";
import TripPaging from "./TripPaging";
import FilterByPageSize from "../../components/FilterByPageSize";
import SortByCreatedTime from "../../components/SortByCreatedTime";
import FilterByTripState from "./components/FilterByTripState";

function Trip() {
  return (
    <Flex direction="column" p={6} h="full">
      <HStack mb={4} spacing={4}>
        <Heading size="md" textTransform="uppercase" color="gray.400">
          Trip
        </Heading>
        <Spacer />
      </HStack>
      <SimpleGrid
        templateRows="auto 1fr"
        borderRadius="md"
        bg="gray.800"
        flex={1}
        overflow="auto"
      >
        <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
          <Flex mb={2}>
            <HStack>
              <FilterBySearchKeyAndSearchType
                searchTypes={TRIP_SEARCH_TYPE_OPTIONS}
                searchTypePlaceholder={TRIP_SEARCH_PLACEHOLDER}
              />
              <FilterByTripState />
              <SortByCreatedTime />
            </HStack>
            <Spacer />
            <FilterByPageSize />
            <TripPaging />
          </Flex>
        </Box>
        <Box overflow="auto" flex={1} px={5}>
          <TripTable />
        </Box>
      </SimpleGrid>
    </Flex>
  );
}

export function TripWrapper() {
  return (
    <React.Suspense fallback="Loading...">
      <Trip />
    </React.Suspense>
  );
}

export default TripWrapper;

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button } from "@chakra-ui/react";
import SpotForm from "./SpotForm";
import { CreateSpotDto, useCreateSpot } from "../../api/spot";

const FORM_ID = "add-spot";

interface SpotModalAddProps {
  isOpen: boolean;
  onClose: () => void;
}

function SpotModalAdd({ isOpen, onClose }: SpotModalAddProps) {
  const { mutateAsync: createSpot, isLoading } = useCreateSpot();
  const onSubmit = async (values: CreateSpotDto) => {
    await createSpot(values);
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="sm"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add spot</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SpotForm onSubmit={onSubmit} formId={FORM_ID} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue" isLoading={isLoading}>
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SpotModalAdd;

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import history from "src/routes/history";
import { ACCESS_TOKEN, DEVICE_ID } from "../constants/localStorage";

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT_URL || "http://localhost:8082";

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_URL,
});

let deviceId = localStorage.getItem(DEVICE_ID) || "";

if (!deviceId) {
  deviceId = uuidv4();
  localStorage.setItem(DEVICE_ID, deviceId);
}
instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const newConfig: any = config;
    if (config.headers === undefined) {
      newConfig.headers = {};
    }
    newConfig.headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
    newConfig.headers[DEVICE_ID] = deviceId;
    return newConfig;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    localStorage.removeItem(ACCESS_TOKEN);
    history.push('/login');
  }
  return Promise.reject(error);

  // throw error;
});

export default instance;

import {
  Box,
  Heading,
  Flex,
  Spacer,
  Button,
  HStack,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { AddIcon } from "@chakra-ui/icons";
import FilterBySearchKey from "../../components/FilterBySearchKey";
import SpotTypeTable from "./SpotTypeTable";
import SpotTypeModalAdd from "./SpotTypeModalAdd";
import SpotTypePaging from "./SpotTypePaging";
import FilterByPageSize from "../../components/FilterByPageSize";

function SpotType() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <SpotTypeModalAdd isOpen={isOpen} onClose={onClose} />
      <Flex direction="column" p={6} h="full">
        <HStack mb={4} spacing={4}>
          <Heading size="md" textTransform="uppercase" color="gray.400">
            Spot type
          </Heading>
          <Spacer />

          <Button onClick={onOpen} leftIcon={<AddIcon />}>
            Add new
          </Button>
        </HStack>
        <SimpleGrid
          templateRows="auto 1fr"
          borderRadius="md"
          bg="gray.800"
          flex={1}
          overflow="auto"
        >
          <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
            <Flex mb={2}>
              <HStack>
                <FilterBySearchKey placeholder="Name" />
              </HStack>
              <Spacer />
              <FilterByPageSize />
              <SpotTypePaging />
            </Flex>
          </Box>
          <Box overflow="auto" flex={1} px={5}>
            <SpotTypeTable />
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
}

export function SpotTypeWrapper() {
  return (
    <React.Suspense fallback="Loading...">
      <SpotType />
    </React.Suspense>
  );
}

export default SpotTypeWrapper;

import {
  Box, Flex, HStack, Heading,
} from "@chakra-ui/react";
import { Suspense } from "react";
import WithdrawTable from "./WithdrawTable";

function WithdrawTransaction() {
  return (
    <Flex direction="column" h="full">
      <HStack m={4} spacing={4}>
        <Heading size="md" textTransform="uppercase" color="gray.400">
          Withdraw Approval
        </Heading>
      </HStack>
      <Box overflow="auto" flex={1} px={5}>
        <WithdrawTable />
      </Box>
    </Flex>
  );
}

function Wrapper() {
  return (
    <Suspense fallback="">
      <WithdrawTransaction />
    </Suspense>
  );
}

export default Wrapper;

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Buffer } from "buffer";
import {
  Box, Button, Center, IconButton, Image,
} from "@chakra-ui/react";

import { useState, useEffect, useRef } from "react";
import { GrRotateLeft, GrRotateRight } from 'react-icons/gr';
import {
  getUploadUrlSpotPhoto, IMAGE_FILE_TYPE, Photo, useUpdateEditedReviewImages,
} from 'src/api/review';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useParams } from "react-router-dom";
import axios from "axios";

interface ApproveReviewProps {
  isOpen: boolean;
  onClose: () => void;
  image?: Photo;
}

function ModalEditPhoto({
  isOpen, onClose, image,
}: ApproveReviewProps) {
  const cropperRef = useRef<ReactCropperElement>(null);
  const { id = "" } = useParams();
  const updateEditedReviewImages = useUpdateEditedReviewImages(id);
  const handleRotate = async (value) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper?.rotate(value);
  };

  const handleSave = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const cropperData = cropper?.getCroppedCanvas().toDataURL() || '';
    const base64Data = Buffer.from(cropperData.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    const presignedUrl: any = await getUploadUrlSpotPhoto(IMAGE_FILE_TYPE.PNG);

    const config = {
      method: 'put',
      url: presignedUrl.signedRequest,
      headers: {
        'Content-Type': 'image/png',
        'Content-Encoding': 'base64',
      },
      data: base64Data,
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(`Đang tải lên: ${progress}%`);
      },
    };
    const imageUrl = await axios(config);
    await updateEditedReviewImages.mutateAsync({
      id: image?.id,
      image: presignedUrl.url,
    });
    onClose();
    // getCroppedFile(img);
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Edit photo
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display="flex" justifyContent="center" alignItems="center" id="test">
            <Cropper
              // src="https://s3.ap-southeast-1.amazonaws.com/static-dev.xtrip.travel/spc715u693.jpeg"
              // src="https://static-dev.xtrip.travel/spc715u693.jpeg"
              src={image?.image}
              // style={{ height: 400, width: 400 }}
              // Cropper.js options
              // initialAspectRatio={16 / 9}
              // guides={false}
              // crop={onCrop}
              rotatable
              ref={cropperRef}
            />
          </Box>
          <Center mt={8}>
            <IconButton
              variant="outline"
              colorScheme="blue"
              aria-label="Call Sage"
              fontSize="20px"
              mr={4}
              onClick={() => handleRotate(-90)}
              icon={<GrRotateLeft />}
            />

            <IconButton
              variant="outline"
              colorScheme="blue"
              aria-label="Call Sage"
              fontSize="20px"
              onClick={() => handleRotate(90)}
              icon={<GrRotateRight />}
            />
          </Center>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button
            type="submit"
            colorScheme="blue"
            onClick={handleSave}
            isLoading={updateEditedReviewImages.isLoading}
          // isLoading={murationEdit.isLoading || mutationCreate.isLoading}
          // disabled={mutation.isError}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalEditPhoto;

import {
  Box, Flex, Heading, SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import CountriesTable from "./CountriesTable";

function Countries() {
  return (
    <Flex direction="column" p={6} h="full">
      <Heading size="md" textTransform="uppercase" color="gray.400" mb="10px">Countries</Heading>
      <SimpleGrid templateRows="auto 1fr" borderRadius="md" bg="gray.800" flex={1} overflow="auto">
        <Box overflow="auto" flex={1} px={5}>
          <CountriesTable />
        </Box>
      </SimpleGrid>
    </Flex>
  );
}

export default function Wrapper() {
  return (
    <React.Suspense>
      <Countries />
    </React.Suspense>
  );
}

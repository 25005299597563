import { Suspense, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Button,
  Heading,
  Grid,
  GridItem,
  SimpleGrid,
  Text,
  Input,
  FormErrorMessage,
  Badge,
  Select,
  Textarea,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
  useDisclosure,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { renderSpotStatusColor } from "src/utils/color";
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { SpotStatus, useSpotTranslate } from "src/api/spot";
import { useReviewsWaitingNumber } from "src/api/review";
import { get } from "lodash";
import { ICountry } from "src/interfaces/country";
import SpotLanguageModal from "src/components/SpotLanguageModal";
import { SiGoogletranslate } from 'react-icons/si';

import {
  useSpot,
  useApproveSpot,
  useSpotTypes,
  City,
  useUpdateSpot,
} from "../../api/spot";
import MapPoint from "./MapPoint";
import MapPolygon from "./MapPolygon";
import { useCountries, getCities } from "../../api/country";
import ReviewTableOverview from "../ReviewOverview/ReviewTable";
import ReviewPagingOverview from "../ReviewOverview/ReviewPaging";
import ReviewTableFeedback from "../ReviewFeedback/ReviewTable";
import ReviewPagingFeedback from "../ReviewFeedback/ReviewPaging";
import ReviewTablePhotos from "../ReviewPhoto/ReviewTable";
import ReviewPagingPhotos from "../ReviewPhoto/ReviewPaging";
import ImageUploader from "./SortableImage";
import UploadPhoto from "./UploadPhoto";

const rejectOptions = [
  "We cannot find this place",
  "This place has already registered by somebody.",
  "This place is not suitable for travel destination.",
  "You have registered spots with different location",
  'Currently, we don\'t allow user to add "Accommodation".',
  "Local language or English should be used.",
];

const languageOptions = [
  { value: "zh", label: "zh - Chinese" },
  { value: "en", label: "en - English" },
  { value: "ja", label: "jp - Japanese" },
  { value: "th", label: "th - Thai" },
  { value: "vi", label: "vi - Vietnamese" },
];

function SpotDetail() {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const { data: spotTypes = { items: [] } } = useSpotTypes({ limit: 1000 });
  const { id = "" } = useParams();
  const { data: numberReviewWaiting } = useReviewsWaitingNumber({ spot_id: id });
  const { data: dataCountries } = useCountries();
  const countryOptions = dataCountries?.map((country: ICountry) => ({
    value: country.country_code,
    label: country.country_name,
  }));
  const updateSpot = useUpdateSpot();
  const { data } = useSpot(id || "");
  const approveSpot = useApproveSpot();
  const [lat, setLat] = useState(data ? data.latitude : 0);
  const [long, setLong] = useState(data ? data.longitude : 0);
  const [changeType, setChangeType] = useState(false);
  const [changeBonusToken, setChangeBonusToken] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  let defaultAddingToken = 0;
  let defaultBonusToken: any = 0;
  if (data?.type) {
    const selectedOption = spotTypes?.items.find(
      (i) => data?.type === i.type_code,
    );
    if (selectedOption) {
      defaultAddingToken = selectedOption.check_in_token;
    }
    defaultBonusToken = get(data, "bonus_token", 0) === 0 ? selectedOption?.bonus_token : get(data, "bonus_token", 0);
  }
  useEffect(
    () => {
      console.log("use effect");
      if (data?.type) {
        const selectedOption = spotTypes?.items.find(
          (i) => data?.type === i.type_code,
        );
        if (data?.status === SpotStatus.Waiting) {
          setValue("allow_check_in", selectedOption?.check_in_earn ? 1 : 0);
        } else {
          setValue("allow_check_in", data?.allow_check_in ? 1 : 0);
        }
      }
    },
    [data],
  );

  const handleApprove = (status: boolean, values: any) => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are you sure to ${status ? "approve" : "reject"} this spot? ${status ? `And give user ${values?.token} tokens` : ""
        }`,
      ) === true
    ) {
      if (!changeType && !changeBonusToken) {
        // eslint-disable-next-line no-param-reassign
        delete values?.bonus_token;
      }
      approveSpot.mutate({
        id,
        data: {
          ...values,
          city: values.city || data?.city,
          // eslint-disable-next-line eqeqeq
          allow_check_in: values.allow_check_in == 1,
          description: values.description,
          rejectReason: values.rejectReason,
          status: status ? SpotStatus.Approved : SpotStatus.Rejected,
        },
      });
    }
  };

  const [countryCode, setCountryCode] = useState<string>(data?.country || "");
  const [dataCities, setDataCities] = useState([]);
  const [pointType, setPointType] = useState(data?.point_type);
  const cityOptions = dataCities
    ? dataCities.map((city: City) => ({
      value: city.city,
      label: city.city,
    }))
    : [];

  useEffect(() => {
    const fetchCities = async () => {
      const cities = await getCities(countryCode);
      setDataCities(cities);
    };

    if (countryCode) {
      fetchCities();
    }
  }, [countryCode]);
  const onSubmitReject = (values: any) => {
    handleApprove(false, values);
  };
  const onSubmitApprove = (values: any) => {
    handleApprove(true, values);
  };
  const onUpdate = (values: any) => {
    if (!changeType && !changeBonusToken) {
      // eslint-disable-next-line no-param-reassign
      delete values?.bonus_token;
    }
    updateSpot.mutate({
      id,
      data: {
        ...values,
        city: values.city || data?.city,
        // eslint-disable-next-line eqeqeq
        allow_check_in: values.allow_check_in == 1,
        description: values.description.replace(/'/g, "\\'"),
      },
    });
  };
  const handleUpdateLatLong = (latitude: number, longitude: number) => {
    setLat(latitude);
    setLong(longitude);
    setValue("lat", latitude);
    setValue("long", longitude);
  };

  const { data: translatedNames } = useSpotTranslate({
    spot_id: id,
    field_name: "name",
  });

  const pointTypeOptions = ["point", "polygon"];
  return (
    <Box p={4}>
      <form>
        <Flex>
          <Heading as="h2" size="lg" flex={1}>
            Spot detail - ID:
            {' '}
            {id}
            {" -  "}
            <Badge
              fontSize="0.5em"
              variant="solid"
              colorScheme={renderSpotStatusColor(data?.status || "")}
              px="2"
              py="1"
            >
              {data?.status}
            </Badge>
          </Heading>
          {data?.status === SpotStatus.Waiting ? (
            <>
              <Button
                colorScheme="red"
                variant="solid"
                type="submit"
                name="action"
                value="reject"
                mr={4}
                onClick={handleSubmit(onSubmitReject)}
              >
                Reject
              </Button>
              <Button
                colorScheme="green"
                type="submit"
                name="action"
                value="approve"
                variant="solid"
                onClick={handleSubmit(onSubmitApprove)}
              >
                Approve
              </Button>
            </>
          ) : (
            <Button
              colorScheme="blue"
              variant="solid"
              type="submit"
              name="action"
              value="reject"
              disabled={data?.status === SpotStatus.Rejected}
              mr={4}
              onClick={handleSubmit(onUpdate)}
            >
              Update
            </Button>
          )}
        </Flex>

        <SimpleGrid column={2} mt={4}>
          <SimpleGrid columns={2} spacing={10}>
            <Box bg="gray.800" p={4}>
              <Grid
                templateRows="repeat(3, 1fr)"
                templateColumns="repeat(3, 1fr)"
                gap={4}
                // height="100%"
              >
                <GridItem colSpan={1} alignItems="center">
                  <Text mb={2}>Email</Text>
                  {data?.user?.email}
                </GridItem>

                <GridItem colSpan={1}>
                  <Text mb={2}>Lat</Text>
                  <Input
                    h="40px"
                    id="lat"
                    placeholder="lat"
                    required
                    type="number"
                    value={lat}
                    {...register("lat", {
                      value: lat,
                      onChange: (e) => {
                        setLat(parseFloat(e?.target?.value));
                      },
                    })}
                    borderRadius="6px"
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <Text mb={2}>Long</Text>
                  <Input
                    id="long"
                    placeholder="long"
                    required
                    value={long}
                    h="40px"
                    type="number"
                    {...register("long", {
                      onChange: (e) => {
                        setLong(parseFloat(e?.target?.value));
                      },
                    })}
                    borderRadius="6px"
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <Text mb={2}>Point Type</Text>
                  <Select
                    placeholder="Select option"
                    {...register("point_type", {
                      onChange: (e) => {
                        setPointType(e.target.value);
                      },
                    })}
                    bgColor="rgba(255, 255, 255, 0.04)"
                    border="0"
                  >
                    {pointTypeOptions?.map((type) => (
                      <option
                        value={type}
                        selected={type === data?.point_type}
                        key={type}
                      >
                        <Text textTransform="capitalize">{type}</Text>
                      </option>
                    ))}
                  </Select>
                </GridItem>
                <GridItem colSpan={2} rowSpan={2}>
                  <Text mb={2}>Description</Text>
                  <Textarea
                    id="description"
                    rows={5}
                    variant="filled"
                    defaultValue={data?.description}
                    {...register("description")}
                    borderRadius="6px"
                  />
                </GridItem>
              </Grid>
            </Box>
            <Box bg="gray.800" p={4}>
              <Grid
                templateRows="repeat(2, 1fr)"
                templateColumns="repeat(3, 1fr)"
                gap={4}
              >
                <GridItem colSpan={1} alignItems="center">
                  <Flex justifyContent="space-between">
                    <Text mb={2}>
                      {' '}
                      Name

                    </Text>
                    <Tooltip placement="top" label="Edit language">
                      <IconButton
                        icon={<Icon boxSize={6} as={SiGoogletranslate} />}
                        variant="ghost"
                        colorScheme="cyan"
                        aria-label="delete"
                        onClick={() => onOpen()}
                      />
                    </Tooltip>
                  </Flex>

                  <Input
                    id="name"
                    placeholder="name"
                    required
                    defaultValue={data?.name}
                    {...register("name")}
                    h="40px"
                    borderRadius="6px"
                  />
                  <FormErrorMessage>
                    {errors.name?.message as any}
                  </FormErrorMessage>
                </GridItem>
                {/* <GridItem colSpan={2}>
                  <Grid templateColumns="repeat(3, 1fr)" gap={2}> */}
                {languageOptions?.map((lang) => {
                  let isTranslated = false;
                  // eslint-disable-next-line max-len
                  const translatedText = translatedNames?.find((e) => e.language_code === lang.value);
                  console.log("console: ", lang.value, translatedText);

                  if (translatedText) {
                    isTranslated = true;
                  }
                  return (
                    <GridItem colSpan={1} overflow="hidden">
                      <Badge variant="solid" colorScheme={isTranslated ? "green" : "red"}>
                        {lang.value}
                        :

                      </Badge>
                      {translatedText
                        && (
                          <Text>
                            {translatedText.field_value}
                          </Text>
                        )}
                    </GridItem>
                  );
                })}
                {/* </Grid>
                </GridItem> */}
                <GridItem colSpan={1}>
                  <Text mb={2}>Country</Text>
                  <Select
                    placeholder="Select option"
                    {...register("country", {
                      onChange: (e) => {
                        setCountryCode(e.target.value);
                      },
                    })}
                    h="40px"
                    bgColor="rgba(255, 255, 255, 0.04)"
                    border="0"
                  >
                    {countryOptions?.map((country) => (
                      <option
                        value={country.value}
                        selected={country.value === data?.country}
                        key={country.value}
                      >
                        <Text textTransform="capitalize">{country.label}</Text>
                      </option>
                    ))}
                  </Select>
                </GridItem>

                <GridItem colSpan={1}>
                  <Text mb={2}>City</Text>
                  <Select
                    placeholder="Select option"
                    {...register("city")}
                    bgColor="rgba(255, 255, 255, 0.04)"
                    border="0"
                  >
                    {cityOptions?.map((city) => (
                      <option
                        value={city.value}
                        selected={city.value === data?.city}
                        key={`${city.value}-${data?.country}`}
                      >
                        <Text textTransform="capitalize">{city.label}</Text>
                      </option>
                    ))}
                  </Select>
                </GridItem>

                <GridItem colSpan={1}>
                  <Text mb={2}>Type</Text>

                  <Select
                    placeholder="Select option"
                    {...register("type", {
                      onChange: (e) => {
                        const selectedOption = spotTypes?.items.find(
                          (i) => e.target.value === i.type_code,
                        );
                        if (selectedOption) {
                          setChangeType(true);
                          setValue("token", selectedOption.check_in_token);
                          setValue("bonus_token", selectedOption.bonus_token);
                          setValue("allow_check_in", selectedOption.check_in_earn ? 1 : 0);
                        }
                      },
                    })}
                    bgColor="rgba(255, 255, 255, 0.04)"
                    border="0"
                  >
                    {spotTypes?.items?.map((t) => (
                      <option
                        value={t.type_code}
                        selected={t.type_code === data?.type}
                        key={t.type_code}
                      >
                        <Text textTransform="capitalize">
                          {" "}
                          {t.name || t.type_code}
                        </Text>
                      </option>
                    ))}
                  </Select>
                </GridItem>

                {data?.status === SpotStatus.Waiting && (
                  <GridItem colSpan={1}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Text mr={1}>
                        Add Token
                      </Text>
                      <Tooltip label="'Add Bonus' column is default Bonus token amount for adding this type of spots!" aria-label="A tooltip">
                        <QuestionOutlineIcon />
                      </Tooltip>
                    </Box>

                    <Input
                      placeholder="medium size"
                      disabled={data?.status !== SpotStatus.Waiting}
                      size="md"
                      type="number"
                      defaultValue={defaultAddingToken}
                      {...register("token", {
                        required: "This is required",
                        min: {
                          value: 0,
                          message: "Minimum value should be 0",
                        },
                      })}
                      borderRadius="6px"
                    />
                    <FormErrorMessage>
                      {errors.token && (errors.token?.message as any)}
                    </FormErrorMessage>
                  </GridItem>
                )}
                <GridItem colSpan={1}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Text mr={1}>
                      Allow check in
                    </Text>
                    <Tooltip label="Spot is counted for number of passed spot in travel" aria-label="A tooltip">
                      <QuestionOutlineIcon />
                    </Tooltip>
                  </Box>
                  <Select
                    {...register("allow_check_in")}
                    bgColor="rgba(255, 255, 255, 0.04)"
                    border="0"
                  >
                    <option
                      value={1}
                      key={1}
                    >
                      <Text textTransform="capitalize">Allow</Text>
                    </option>
                    <option
                      value={0}
                      key={0}
                    >
                      <Text textTransform="capitalize">Not allow</Text>
                    </option>
                  </Select>
                </GridItem>
                <GridItem colSpan={1}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Text mr={1}>
                      Bonus Token
                    </Text>
                    <Tooltip label="'Bonus Bonus' column is default Bonus Token amount when user passed!" aria-label="A tooltip">
                      <QuestionOutlineIcon />
                    </Tooltip>
                  </Box>
                  <Input
                    placeholder="Bonus token"
                    size="md"
                    type="number"
                    defaultValue={defaultBonusToken}
                    {...register("bonus_token", {
                      onChange: () => {
                        setChangeBonusToken(true);
                      },
                    })}
                  />
                </GridItem>
                {data?.status !== SpotStatus.Approved && (
                  <GridItem colSpan={2} rowSpan={1}>
                    <Text my={2}>Reject reason</Text>
                    <Select
                      placeholder="Select option"
                      {...register("rejectReason")}
                      bgColor="rgba(255, 255, 255, 0.04)"
                      border="0"
                    >
                      {rejectOptions.map((t) => (
                        <option
                          value={t}
                          key={t}
                          selected={data?.rejectReason === t}
                        >
                          <Text textTransform="capitalize">{t}</Text>
                        </option>
                      ))}
                    </Select>
                  </GridItem>
                )}
              </Grid>

            </Box>
          </SimpleGrid>
        </SimpleGrid>
      </form>

      <Tabs mt="60px">
        <TabList>
          <Tab>Location</Tab>
          <Tab>
            Oveview
            {numberReviewWaiting?.overview > 0
              && (
                <Badge
                  colorScheme="blue"
                  w="20px"
                  h="20px"
                  borderRadius="full"
                  fontSize="11px"
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  ml="6px"
                >
                  {numberReviewWaiting?.overview}
                </Badge>
              )}

          </Tab>
          <Tab>
            Feedback
            {' '}
            {numberReviewWaiting?.feedback > 0
              && (
                <Badge
                  colorScheme="blue"
                  w="20px"
                  h="20px"
                  borderRadius="full"
                  fontSize="11px"
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  ml="6px"
                >
                  {numberReviewWaiting?.feedback}
                </Badge>
              )}

          </Tab>
          <Tab>
            Photo
            {' '}
            {numberReviewWaiting?.photo > 0
              && (
                <Badge
                  colorScheme="blue"
                  w="20px"
                  h="20px"
                  borderRadius="full"
                  fontSize="11px"
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  ml="6px"
                >
                  {numberReviewWaiting?.photo}
                </Badge>
              )}

          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            {pointType === "point" ? (
              <MapPoint handleUpdateLatLong={handleUpdateLatLong} />
            ) : (
              <MapPolygon />
            )}
          </TabPanel>
          <TabPanel>
            <ReviewTableOverview spotId={id} />
            <Flex justifyContent="flex-end" mt="24px">
              <ReviewPagingOverview spotId={id} />
            </Flex>
          </TabPanel>
          <TabPanel>
            <ReviewTableFeedback spotId={id} />
            <Flex justifyContent="flex-end" mt="24px">
              <ReviewPagingFeedback spotId={id} />
            </Flex>
          </TabPanel>
          <TabPanel>
            <Flex justifyContent="flex-end" mb="24px">
              <ReviewPagingPhotos spotId={id} />
            </Flex>
            <ReviewTablePhotos spotId={id} />
            <UploadPhoto />
            <ImageUploader />
          </TabPanel>
          {/* <TabPanel p={0}>
            <UploadPhoto />
            <ImageUploader />
          </TabPanel> */}
        </TabPanels>
      </Tabs>
      {
        isOpen
        && <SpotLanguageModal isOpen={isOpen} onClose={onClose} spotId={id} fieldName="name" fieldValue={data?.name || ""} />
      }
      {/* {pointType === "polygon" && <MapPolygon />} */}
    </Box>
  );
}

function ItemTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <SpotDetail />
    </Suspense>
  );
}

export default ItemTableWrapper;

import React, { forwardRef, PropsWithoutRef } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Text,
  Flex,
  Checkbox,
  Image,
  Box,
} from "@chakra-ui/react";
import { useField } from "react-final-form";
import { Photo } from 'src/api/review';

export interface LabeledSelectPhotosProps
  extends PropsWithoutRef<JSX.IntrinsicElements["textarea"]> {
  /** Field name. */
  name: string;
  /** Field label. */
  label?: string;
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  helperText?: string;
  defaultValue?: any;
  onValueChange?: any;
  photos: Photo[] | undefined
}

export const LabeledSelectPhotos = forwardRef<
  HTMLTextAreaElement,
  LabeledSelectPhotosProps
>(
  (
    {
      name,
      label,
      outerProps,
      helperText,
      photos,
      required,
    },
  ) => {
    const {
      input: { onChange, value },
      meta: { touched, error, submitError },
    } = useField(name);

    const normalizedError = Array.isArray(error)
      ? error.join(", ")
      : error || submitError;

    return (
      <FormControl
        color="gray.100"
        isInvalid={touched && normalizedError}
        {...outerProps}
      >
        <FormLabel fontSize="sm" mb="0" display="flex" alignItems="center">
          {label}
          {' '}
          { required
            && <Text>*</Text>}
        </FormLabel>
        {helperText && <FormHelperText mb="2">{helperText}</FormHelperText>}
        <Flex wrap="wrap">
          { photos?.map((photo) => (
            <Box
              key={photo.id}
              m="15px"
              flexDirection="column"
              h="auto"
              outline={0}
              border={0}
              textAlign="center"
              display="flex"
              justifyContent="space-between"
            >
              <Image
                src={photo.image}
                w="146px"
                mb="10px"
                onClick={() => {
                  if (value.indexOf(photo.id) === -1) {
                    onChange([...value, photo.id]);
                  } else {
                    onChange(value.filter((item) => item !== photo.id));
                  }
                }}
              />
              <Checkbox
                size="md"
                colorScheme="blue"
                isChecked={value.indexOf(photo.id) !== -1}
                onChange={() => {
                  if (value.indexOf(photo.id) === -1) {
                    onChange([...value, photo.id]);
                  } else {
                    onChange(value.filter((item) => item !== photo.id));
                  }
                }}
              >
                Select image
              </Checkbox>
            </Box>
          )) }

        </Flex>

        {touched && normalizedError && (
          <FormErrorMessage>{normalizedError}</FormErrorMessage>
        )}
      </FormControl>
    );
  },
);

export default LabeledSelectPhotos;

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-bitwise */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
import React, { Component } from "react";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import Dropzone from "react-dropzone-uploader";
import axios from "axios";
import Api from "src/config/axios";
import classNames from 'classnames';
import { getFileName } from "src/utils/utils";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {
  FormHelperText, FormLabel, Text, Icon,
} from "@chakra-ui/react";
import FileType from "./FileType";
import classes from "./DropzoneUploader.module.scss";

class DropzoneUploader extends Component<any, any> {
  dropzoneRef: any;

  constructor(props) {
    super(props);
    this.state = {
      loading: {},
    };
  }

  getFilesFromEvent = (e: any) => new Promise((resolve) => {
    getDroppedOrSelectedFiles(e).then((chosenFiles) => {
      resolve(chosenFiles.map((f) => f.fileObject));
    });
  });

  getUploadParams = async ({ file }) => {
    // const size = file.size
    const { size } = file;
    if (size > 5 * 1024 * 1024) {
      return;
    }
    const id = new Date().valueOf() + Math.floor(Math.random() * 10000);
    const { input } = this.props;
    const { onChange } = input;

    try {
      input.value.push({ id, url: file.name, status: "UPLOADING" });
      // onChange(...input.value);

      const extend = file.name ? file.name.split(".").pop() : "File";

      const result = await Api.get('/spot-review-images/upload-url', {
        params: {
          fileType: `image/${extend}`,
        },
      });

      await axios.put(result?.data?.signedRequest, file, {
        headers: {
          "x-amz-acl": "public-read",
          "Content-Type": extend === "svg" && "image/svg+xml",
        },
        onUploadProgress: (progressEvent) => {
          const progress = progressEvent.loaded / size;
          this.setState((prevState) => ({
            ...prevState,
            loading: {
              ...prevState.loading,
              [id]: progress,
            },
          }));
        },
      });

      // const [imageUrl] = result?.data?.signedRequest.split("?");

      // eslint-disable-next-line react/destructuring-assignment
      const newValue = this.props.input.value.map((item) => {
        if (item.id === id) {
          return {
            id,
            url: result?.data?.url,
            fileName: file.name,
            status: "DONE",
          };
        }
        return item;
      });
      onChange(newValue);
    } catch (e) {
      // eslint-disable-next-line react/destructuring-assignment
      const newValue = this.props.input.value.filter((item) => item.id !== id);
      onChange(newValue);
    }
  };

  Preview = () => {
    const { input } = this.props;
    const { value } = input;
    const { loading } = this.state;
    return (
      <div>
        <div className={classes.files}>
          {value
            && value.map((file, i) => (
              <div key={file.id} className={classes.fileWrapper}>
                <div className={classes.file}>
                  <div className={classNames(classes.left)}>
                    <div className={classes.imageWrapper}>
                      {file.url && file.status !== "UPLOADING" && (
                        <FileType item={file} />
                      )}
                    </div>
                    <p className={classes.fileName}>{getFileName(file.url)}</p>
                  </div>
                  <a
                    className={classes.btnClose}
                    onClick={this.handleRemoveDocument(i)}
                  >
                    <Icon as={AiOutlineCloseCircle} color="red.300" size="xl" />
                  </a>
                </div>
                {loading[file.id] && loading[file.id] !== 1 && (
                  <div className={classes.row}>
                    <div className={classes.progressWrapper}>
                      <div
                        className={classes.progress}
                        style={{ width: `${loading[file.id] * 100}%` }}
                      />
                    </div>
                    <p className={classes.percent}>
                      {`${Math.round(loading[file.id] * 100)}%`}
                    </p>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  };

  Input = ({
    accept, onFiles, files, getFilesFromEvent,
  }) => {
    const text = files.length > 0 ? "Add File" : "Select File";
    const { maxFiles, input } = this.props;
    return (
      <div
        className={classNames(
          classes.inputComponent,
          maxFiles <= input.value.length && classes.pb0,
        )}
      >
        {this.Preview()}
        {(!maxFiles || maxFiles > input.value.length) && (
          <>
            <p className={classes.text}>Drag and drop file here!</p>
            <p className={classes.or}>OR</p>
            <div className={classes.actions}>
              <label className="btn">
                {text}
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept={accept}
                  multiple
                  onChange={(e) => {
                    getFilesFromEvent(e).then((chosenFiles) => {
                      onFiles(chosenFiles);
                    });
                  }}
                />
              </label>
            </div>
            <Text color="white.300" fontStyle="italic" textAlign="center" mt="8px">
              File max 5MB
            </Text>
          </>
        )}
      </div>
    );
  };

  handleRemoveDocument = (pos) => () => {
    this.dropzoneRef.handleRemove(this.dropzoneRef.files[pos]);
    const { input } = this.props;
    const newValue = input.value.filter((item, i) => i !== pos);
    input.onChange(newValue);
  };

  Layout = ({ input, dropzoneProps }) => (
    <div>
      <div {...dropzoneProps}>{input}</div>
    </div>
  );

  render() {
    const {
      maxFiles, accept,
      meta: {
        touched, error, submitError,
      },
      label,
      required,
      helperText,
    } = this.props;

    const normalizedError = Array.isArray(error)
      ? error.join(", ")
      : error || submitError;

    return (
      <div
        className={classNames(classes.container)}
      >
        <FormLabel fontSize="sm" mb={helperText ? "0" : '2'} display="flex" alignItems="center">
          {label}
          {' '}
          { required
            && <Text>*</Text>}

        </FormLabel>
        {helperText && <FormHelperText mb="3">{helperText}</FormHelperText>}
        <div className={classNames(touched && normalizedError && "errorWrapper")}>
          <Dropzone
            inputContent="Drag and drop image here!"
            InputComponent={this.Input}
            LayoutComponent={this.Layout}
            getUploadParams={this.getUploadParams as any}
            multiple
            getFilesFromEvent={this.getFilesFromEvent as any}
          // PreviewComponent={null}
            maxFiles={maxFiles || 100}
            accept={accept || "image/*,.mp4"}
            ref={(ref) => (this.dropzoneRef = ref)}
          />
        </div>
        {touched && normalizedError && (
          <Text color="red.300" mt="10px" fontSize="14px">{normalizedError}</Text>
        )}
      </div>

    );
  }
}

export default DropzoneUploader;

import React, { useMemo } from 'react';
import {
  ResponsiveContainer, PieChart, Pie, Cell,
} from 'recharts';
import {
  Box,
  Flex,
  Text,
  Link,
} from '@chakra-ui/react';
import { TokenManagementDto } from 'src/api/transaction';
import { formatStringToNumber } from 'src/utils/utils';
import { Link as RouterLink } from 'react-router-dom';

interface OverallTransactionsProps {
  token: string,
  data: TokenManagementDto | undefined | null
}

function OverallTransactions({ token, data }: OverallTransactionsProps) {
  const COLORS = ['#2DBC60', '#f4b400'];

  const chartData: any = useMemo(() => {
    if (data) {
      return [{
        value: data?.total_earn_token || 1,
        id: 1,
      }, {
        value: data?.total_spend_token || 1,
        id: 2,
      }];
    }
    return [];
  }, [data]);

  return (
    <Box borderRadius="11px" border="1px" borderColor="rgba(255, 255, 255, 0.16)" p="20px" backgroundColor="var(--ninjakids-colors-gray-800)">
      <Text fontSize="24px" fontWeight="bold" textAlign="center" mb="24px" mt="20px">
        Overall
        {' '}
        {token}
        {' '}
        transactions
      </Text>

      <Flex justifyContent="space-between" mb="16px">
        <Box
          flex={1}
          mx="10px"
        >
          <Text fontSize="13px" fontWeight="600" textTransform="uppercase">
            Total supply
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            {formatStringToNumber(data?.total_supply_token)}
            {' '}
            {data?.token}
          </Text>
          <Link
            as={RouterLink}
            to={`/supply-transactions?token=${token}`}
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="6px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
        <Box
          flex={1}
          mx="10px"
        >
          <Text fontSize="13px" fontWeight="600" textTransform="uppercase" textAlign="right">
            Remain
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)" textAlign="right">
            { formatStringToNumber(data?.total_remain_token)}
            {' '}
            {data?.token}
          </Text>
        </Box>
      </Flex>

      <Box display="flex" justifyContent="center" w="200px" h="200px" mx="auto" mb="20px">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={chartData}
              innerRadius={50}
              outerRadius={100}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${entry.id}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>

      <Flex alignItems="center" justifyContent="center">
        <Flex mr="40px">
          <Box w="14px" h="14px" border="1" borderColor="#707070" backgroundColor="#2DBC60" mt="3px" mr="8px" />
          <Box flex={1}>
            <Text fontSize="14px" mb="4px">
              {token}
              {' '}
              user earned
            </Text>
            <Text fontSize="12px">
              { formatStringToNumber(data?.total_earn_token)}
              {' '}
              {token}
            </Text>
          </Box>
        </Flex>

        <Flex>
          <Box w="14px" h="14px" border="1" borderColor="#707070" backgroundColor="#f4b400" mt="3px" mr="8px" />
          <Box flex={1}>
            <Text fontSize="14px" mb="4px">
              {token}
              {' '}
              user spend
            </Text>
            <Text fontSize="12px">
              { formatStringToNumber(data?.total_spend_token)}
              {' '}
              {token}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

export default OverallTransactions;

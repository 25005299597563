/* eslint-disable no-useless-escape */
import React from "react";
import classNames from "classnames";
import classes from "./FileType.module.scss";

export const isImage = (fileName) => /[\/.](gif|jpg|jpeg|tiff|png|svg)$/i.test(fileName);

const fileType = (filename) => {
  const extend = filename ? filename.split(".").pop() : "File";
  if (!filename) {
    return "File";
  }
  if (extend.toUpperCase() === "DOCX") {
    return (
      <img
        src="/img/fileIcons/word.svg"
        className={classes.imageFile}
        alt="img"
      />
    );
  }
  if (extend.toUpperCase() === "DOC") {
    return (
      <img
        src="/img/fileIcons/doc.svg"
        className={classes.imageFile}
        alt="img"
      />
    );
  }
  if (extend.toUpperCase() === "XLS") {
    return (
      <img
        src="/img/fileIcons/xls.svg"
        className={classes.imageFile}
        alt="img"
      />
    );
  }
  if (extend.toUpperCase() === "XLSX") {
    return (
      <img
        src="/img/fileIcons/excel.svg"
        className={classes.imageFile}
        alt="img"
      />
    );
  }
  if (extend.toUpperCase() === "CSV") {
    return (
      <img
        src="/img/fileIcons/csv.svg"
        className={classes.imageFile}
        alt="img"
      />
    );
  }
  if (extend.toUpperCase() === "PPTX") {
    return (
      <img
        src="/img/fileIcons/powerpoint.svg"
        className={classes.imageFile}
        alt="img"
      />
    );
  }
  if (extend.toUpperCase() === "PPT") {
    return (
      <img
        src="/img/fileIcons/ppt.svg"
        className={classes.imageFile}
        alt="img"
      />
    );
  }
  if (extend.toUpperCase() === "TXT") {
    return (
      <img
        src="/img/fileIcons/txt.svg"
        className={classes.imageFile}
        alt="img"
      />
    );
  }
  if (extend.toUpperCase() === "PDF") {
    return (
      <img
        src="/img/fileIcons/pdf.svg"
        className={classes.imageFile}
        alt="img"
      />
    );
  }
  return extend;
};

function FileType({ item, size }: any) {
  let element: any = "LINK";
  if (isImage(item.url)) {
    element = <img src={`${item.url}`} className={classes.image} alt="img" />;
  } else {
    element = fileType(item.url);
  }
  return (
    <div
      className={classNames(
        classes.attachmentType,
        size === "sm" && classes.sm,
      )}
    >
      {element}
    </div>
  );
}

export default FileType;

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useQueryClient } from 'react-query';
import {
  deleteSpot, SpotAction, spotKeys, updateSpot,
} from '../../api/spot';

interface SpotModalDeleteProps {
  isOpen: boolean,
  onClose: () => void,
  id: string,
  action: string,
}
function SpotDelete({
  isOpen, onClose, id, action = SpotAction.Archive,
}: SpotModalDeleteProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();
  const handleAction = async () => {
    try {
      if (action === SpotAction.Archive) {
        await deleteSpot(id);
      } else {
        await updateSpot(id, { is_deleted: 0 });
      }

      onClose();
      toast({ status: "success", description: `${action} successful` });
      queryClient.invalidateQueries(spotKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Error" });
    }
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Are you sure you want to
          {' '}
          {action}
          {' '}
          spot id:
          {id}
          {' '}
          ?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody />
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="ghost" colorScheme="red" onClick={handleAction} textTransform="capitalize">{action}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SpotDelete;

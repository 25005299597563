/* eslint-disable max-len */
import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { UserDetail } from "./user";
import { DEFAULT_PAGE_SIZE } from "../constants/pagination";

export const TRIP_SEARCH_TYPE_OPTIONS = [{
  label: 'Trip ID',
  value: 'id',
}, {
  label: 'User ID',
  value: 'user_id',
}, {
  label: 'Device Id',
  value: 'device_id',
}];
export const TRIP_SEARCH_PLACEHOLDER = {
  id: 'trip id',
  user_id: 'user id',
  device_id: 'device id',
};

export const TRIP_STATE = ["travelling", "finished", "cancel"];
export enum TripState {
  Traveling = "travelling",
  Finished = "finished",
  Cancel = "cancel",
}

export interface GetTripDto {
  name?: string;
  city?: string;
  country?: string;
}

export interface Point {
  longitude: number;
  latitude: number;
  bad_point: boolean;
  timestamp: number;
}
export interface TripDocument extends TimeStamp, ObjectId {
  user_id: number;
  device_id: string;
  longitude_start: number;
  latitude_start: number;
  longitude_stop: number;
  latitude_stop: number;
  longitude_destination: number;
  latitude_destination: number;
  max_distance: number;
  max_token: number;
  finshed_distance: number;
  all_points: string;
  bonus_token: number;
  bonus_spots: number;
  spots: number;
  xtr_reward: number;
  state: string;
  finished_at: number;
  created_at: Date;
}

export interface UpdateTripDto extends Partial<TripDocument> {
}

export interface TripDetail {
  id: number;
  distance: number;
  name: string;
  address: string;
  point_type: string;
  point: number;
  type: string;
  created_at: number;
  updated_at: number;
  create_by: number;
  deleted_at: number;
  city: string;
  country: string;
  description: string;
  rejectReason: string;
  user: UserDetail;
  latitude: number;
  longitude: number;
  nearBy: TripDetail[];
  polygon: Object[];
  agoda_id: string;
  booking_id: string;
}

export const tripKeys = generateQueryKey("trip");
export const tripPointKeys = generateQueryKey("tripPoint");

export const getTrip = async (id: string) => {
  try {
    const { data } = await axios.get<TripDetail>(`admin/trip/${id}`);
    return data;
  } catch (e) {
    return null;
  }
};

export const getTripPoint = async (id: string) => {
  try {
    const { data } = await axios.get(`admin/trip/${id}/all-point`);
    return data;
  } catch (e) {
    return null;
  }
};

export const getTrips = async (params?: QueryOptionParams & GetTripDto) => {
  try {
    const { data } = await axios.get<PaginateDocument<TripDocument>>(
      "/admin/trip",
      {
        params: { page: 1, limit: DEFAULT_PAGE_SIZE, ...params },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const updateTrip = async (id: string, updateTripDto: UpdateTripDto) => {
  const { data } = await axios.patch(`/admin/trip/${id || ""}`, updateTripDto);
  return data;
};

// hook

export const useTrip = (id: string) => useQuery(tripKeys.detail(id), () => getTrip(id));
export const useTripPoints = (id: string) => useQuery(tripPointKeys.detail(id), () => getTripPoint(id));

export const useTrips = (params?: QueryOptionParams & GetTripDto) => useQuery(tripKeys.list(params), () => getTrips(params));

export const useUpdateTrip = () => {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: string; data: UpdateTripDto }) => updateTrip(id, data),
    {
      onError: (e: any) => {
        toast({
          status: "error",
          description: e?.response?.data?.message[0] || "Error!",
        });
      },
      onSuccess: () => {
        toast({ status: "success", description: "Update successful!" });
        queryClient.invalidateQueries(tripKeys.all);
      },
    },
  );
};

export const updateTripPublished = async (
  id: string,
  updateSpotDto: { isPublished: boolean },
) => {
  const { data } = await axios.patch(
    `/spot-trip/${id}/is-published`,
    updateSpotDto,
  );
  return data;
};

export const useUpdateTripPublished = () => {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: string; data: { isPublished: boolean } }) => updateTripPublished(id, data),
    {
      onError: (e: any) => {
        toast({
          status: "error",
          description: e?.response?.data?.message[0] || "Error!",
        });
      },
      onSuccess: () => {
        toast({ status: "success", description: "Update successful!" });
        queryClient.invalidateQueries(tripKeys.all);
      },
    },
  );
};

import {
  Box,
  Heading,
  Flex,
  Spacer,
  HStack,
  SimpleGrid,
  Text,
  Button,
} from "@chakra-ui/react";
import React, { useState } from 'react';
import CSVReader from "react-csv-reader";
import { useCountries } from "src/api/country";
import { useSpotTypes, useUploadSpot } from "src/api/spot";
import UploadedSpotTable from "./TableData";

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, "_"),
};

function SpotType() {
  const { data: spotTypes } = useSpotTypes({ limit: 1000 });
  const { data: dataCountries } = useCountries();
  const [uploadData, setUploadData] = useState([]);
  const handleForce = (data: any) => {
    setUploadData(data);
  };

  const mutation = useUploadSpot();
  let errorIndex: number[] = [];
  if (uploadData.length > 0) {
    const spotTypeOptions = (spotTypes?.items || []).map((item) => item.type_code);
    const countryOptions:any = dataCountries?.map((country: any) => country.country_code);
    uploadData.forEach((item: any, index: any) => {
      if (!spotTypeOptions.includes(item.type)) {
        errorIndex.push(index + 1);
      }
      if (!countryOptions.includes(item.country)) {
        errorIndex.push(index + 1);
      }
      if (item.longitude > 180 || item.longitude < -180) {
        errorIndex.push(index + 1);
      }
      if (item.latitude > 90 || item.latitude < -90) {
        errorIndex.push(index + 1);
      }
      if (item.name.includes("'")) {
        errorIndex.push(index + 1);
      }
    });
    errorIndex = [...new Set(errorIndex)];
  }
  const onSubmit = async () => {
    const data = uploadData.map((item: any) => ({
      ...item,
      lat: item.latitude,
      long: item.longitude,
    }));
    mutation.mutateAsync(data);
  };

  return (
    <Flex direction="column" p={6} h="full">
      <HStack mb={4} spacing={4}>
        <Heading size="md" textTransform="uppercase" color="gray.400">
          Spot upload
        </Heading>
        <Spacer />
        <CSVReader
          cssClass="react-csv-input"
          onFileLoaded={handleForce}
          parserOptions={papaparseOptions}
        />
      </HStack>
      <Flex mb={4}>

        <HStack bg="gray.700" px={3} borderRadius="md" mr={2}>
          <Text fontWeight="bold" fontSize="sm" ml={2}>
            Total:
          </Text>
          <Text color="green.200">{uploadData.length}</Text>
        </HStack>
        <HStack bg="gray.700" px={3} borderRadius="md" mr={2}>
          <Text fontWeight="bold" fontSize="sm" ml={2}>
            Error:
          </Text>
          <Text color="red.300">
            {errorIndex.length}
          </Text>
        </HStack>
        <Spacer />
        <Button
          colorScheme="blue"
          size="sm"
          disabled={errorIndex.length > 0 || uploadData.length === 0}
          onClick={onSubmit}
          isLoading={mutation.isLoading}
        >
          Upload
        </Button>
      </Flex>
      <SimpleGrid
        templateRows="auto 1fr"
        borderRadius="md"
        bg="gray.800"
        flex={1}
        overflow="auto"
      >

        <Box overflow="auto" flex={1} px={5}>
          <UploadedSpotTable data={uploadData} errorIndex={errorIndex} />
        </Box>
      </SimpleGrid>

    </Flex>
  );
}

export function SpotTypeWrapper() {
  return (
    <React.Suspense fallback="Loading...">
      <SpotType />
    </React.Suspense>
  );
}

export default SpotTypeWrapper;

import React, { Suspense, useState } from "react";
import { CellValue } from "react-table";
import {
  ReviewDocument, REVIEW_PHOTO_REJECT_OPTIONS, useDeleteReview, useReviews, useUpdateReviewStatus,
} from "src/api/review";
import {
  Text, Badge, Icon, HStack, Tooltip, IconButton,
  Box,
  useDisclosure,
  Image,
  Flex,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FiTrash2 } from 'react-icons/fi';
import { MdCancel } from 'react-icons/md';
import dayjs from "dayjs";
import { renderSpotStatusColor } from "src/utils/color";
import RejectDialog from "src/components/RejectDialog";
import { formatStringToNumber } from "src/utils/utils";
import ReviewDetail from "src/components/ReviewDetail";
import ConfirmDialog from "src/components/ConfirmDialog";
// import CreateReview from 'src/components/CreateReview';
import { REVIEW_TYPE } from "src/constants/review";
import { DATE_TIME_FORMAT } from "src/constants/date";
import ApproveReviewPhotoModal from "./ApproveReviewPhoto";
import Table from "../../components/Table";
import useSearchParams from "../../utils/useSearchParams";
import { ReviewTableProps } from "../ReviewOverview/ReviewTable";

function ReviewTable({ spotId, spotStatus }: ReviewTableProps) {
  const { params } = useSearchParams();
  const { data } = useReviews({
    ...params,
    spot_id: spotId,
    review_type: REVIEW_TYPE.photo,
    spot_status: spotStatus || '',
  });
  const [selectedReview, setSelectedReview] = useState<ReviewDocument | undefined>();
  const { isOpen: isOpenApprove, onOpen: onOpenApprove, onClose: onCloseApprove } = useDisclosure();
  const { isOpen: isOpenReject, onOpen: onOpenReject, onClose: onCloseReject } = useDisclosure();
  const {
    isOpen: isOpenReviewDetail, onOpen: onOpenReviewDetail,
    onClose: onCloseReviewDetail,
  } = useDisclosure();
  // const {
  //   isOpen: isOpenEditReview, onOpen: onOpenEditReview,
  //   onClose: onCloseEditReview,
  // } = useDisclosure();
  const {
    isOpen: isOpenDeleteReview, onOpen: onOpenDeleteReview,
    onClose: onCloseDeleteReview,
  } = useDisclosure();

  const mutationUpdateStatus = useUpdateReviewStatus();
  const mutationDeleteReview = useDeleteReview();

  const handleReject = async (rejectReason) => {
    await mutationUpdateStatus.mutateAsync({
      reviewId: selectedReview?.id,
      status: 'rejected',
      reason: rejectReason,
      review_type: 'photo',
    });
    onCloseReject();
  };

  const handleDeleteReview = async () => {
    if (selectedReview) {
      await mutationDeleteReview.mutateAsync(selectedReview?.id as any);
      onCloseDeleteReview();
    }
  };

  const columns = React.useMemo(
    () => (spotId ? [{
      Header: "Created at",
      accessor: "created_at",
      Cell: ({ value }: CellValue) => <Text>{dayjs(value).format(DATE_TIME_FORMAT)}</Text>,
    },
    {
      Header: "User",
      accessor: "full_name",
      Cell: ({ value, row }: CellValue) => (
        <Link
          as={RouterLink}
          to={`/user/${row.original.user_id}`}
          fontWeight="semibold"
          color="blue.300"
        >
          <HStack>
            <Text mb="4px">
              {value}
              {' '}
              (
              {row?.original.email}
              )
            </Text>
          </HStack>
        </Link>

      ),
    },
    {
      Header: "Photo",
      accessor: "photos",
      Cell: ({ value }: CellValue) => (
        <Flex wrap="wrap" w="100px" maxW="330px" alignItems="center" position="relative">
          {value?.slice(0, 1).map((item) => (
            <Image
              src={item.image}
              w="100px"
              // m="4px"
              key={item.id}
            />
          ))}

          {value?.length > 1
            && (
              <Flex
                alignItems="center"
                justifyContent="center"
                backgroundColor="rgba(0,0,0, 0.4)"
                position="absolute"
                top="0"
                right="0"
                left="0"
                bottom="0"
                zIndex={2}
              >
                <Text fontSize="20px" fontWeight="600">
                  +
                  {' '}
                  {(value?.length as number) - 1}
                </Text>
              </Flex>
            )}

        </Flex>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value, row }: CellValue) => (
        <Box>
          <Badge
            variant="outline"
            colorScheme={renderSpotStatusColor(value)}
            px="2"
            py="1"
          >
            {value}
          </Badge>
          {value === 'approved'
            && (
              <Text mt="8px">
                {row.original.approve_photo}
                {' '}
                /
                {' '}
                {row.original.total_photo}
                {' '}
                Images
              </Text>
            )}
          {value === 'rejected'
            && (
              <Tooltip label={row?.original?.reject_reason}>
                <Text mt="8px" noOfLines={2}>
                  {row?.original?.reject_reason}
                </Text>
              </Tooltip>

            )}

        </Box>

      ),
    },
    {
      Header: "Reward",
      accessor: "token_number",
      Cell: ({ value }: CellValue) => (
        <Text>
          {formatStringToNumber(value)}
        </Text>

      ),
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ row }: CellValue) => (
        <HStack spacing={2}>
          <Tooltip label="Approve">
            <IconButton
              icon={<Icon boxSize={4} as={AiFillCheckCircle} />}
              variant="ghost"
              colorScheme="green"
              aria-label="Approve"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedReview(row.original);
                onOpenApprove();
              }}
              disabled={row.original?.status !== 'waiting'}
            />
          </Tooltip>

          <Tooltip label="Reject">
            <IconButton
              icon={<Icon boxSize={4} as={MdCancel} />}
              variant="ghost"
              colorScheme="red"
              aria-label="Reject"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedReview(row.original);
                onOpenReject();
              }}
              disabled={row.original?.status !== 'waiting'}
            />
          </Tooltip>
          {row?.original.role_name === 'admin' && (
            <>
              {/* <Tooltip label="Edit">
                <IconButton
                  icon={<Icon boxSize={4} as={FiEdit} />}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelectedReview(row.original);
                    onOpenEditReview();
                  }}
                />
              </Tooltip> */}
              <Tooltip label="Delete">
                <IconButton
                  icon={<Icon boxSize={4} as={FiTrash2} />}
                  variant="ghost"
                  colorScheme="red"
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelectedReview(row.original);
                    onOpenDeleteReview();
                  }}
                />
              </Tooltip>
            </>
          )}
        </HStack>
      ),
    }] : [
      {
        Header: "Created at",
        accessor: "created_at",
        Cell: ({ value }: CellValue) => <Text>{dayjs(value).format(DATE_TIME_FORMAT)}</Text>,
      },
      {
        Header: "User",
        accessor: "full_name",
        Cell: ({ value, row }: CellValue) => (
          <Link
            as={RouterLink}
            to={`/user/${row.original.user_id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            <HStack>
              <Text mb="4px">
                {value}
                {' '}
                (
                {row?.original.email}
                )
              </Text>
            </HStack>
          </Link>

        ),
      },
      {
        Header: "Photo",
        accessor: "photos",
        Cell: ({ value }: CellValue) => (
          <Flex wrap="wrap" w="100px" maxW="330px" alignItems="center" position="relative">
            {value?.slice(0, 1).map((item) => (
              <Image
                src={item.image}
                w="100px"
                // m="4px"
                key={item.id}
              />
            ))}

            {value?.length > 1
              && (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="rgba(0,0,0, 0.4)"
                  position="absolute"
                  top="0"
                  right="0"
                  left="0"
                  bottom="0"
                  zIndex={2}
                >
                  <Text fontSize="20px" fontWeight="600">
                    +
                    {' '}
                    {(value?.length as number) - 1}
                  </Text>
                </Flex>
              )}

          </Flex>
        ),
      },
      {
        Header: "Spot name",
        accessor: "spot_name",
        Cell: ({ value, row }: CellValue) => (
          <Link
            as={RouterLink}
            to={`/spot/${row.original.spot_id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            <HStack>
              <Text>{value}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Spot type",
        accessor: "spot_type",
        Cell: ({ value }: CellValue) => (
          <Text>
            {value || '-'}
          </Text>
        ),
      },
      {
        Header: "Country",
        accessor: "country",
        Cell: ({ value }: CellValue) => (
          <Text>
            {value}
          </Text>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }: CellValue) => (
          <Box>
            <Badge
              variant="outline"
              colorScheme={renderSpotStatusColor(value)}
              px="2"
              py="1"
            >
              {value}
            </Badge>
            {value === 'approved'
              && (
                <Text mt="8px">
                  {row.original.approve_photo}
                  {' '}
                  /
                  {' '}
                  {row.original.total_photo}
                  {' '}
                  {' '}
                  Images
                </Text>
              )}

            {value === 'rejected'
              && (
                <Tooltip label={row?.original?.reject_reason}>
                  <Text mt="8px" noOfLines={2}>
                    {row?.original?.reject_reason}
                  </Text>
                </Tooltip>

              )}

          </Box>
        ),
      },
      {
        Header: "Reward",
        accessor: "token_number",
        Cell: ({ value }: CellValue) => (
          <Text>
            {formatStringToNumber(value)}
          </Text>

        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }: CellValue) => (
          <HStack spacing={2}>
            <Tooltip label="Approve">
              <IconButton
                icon={<Icon boxSize={4} as={AiFillCheckCircle} />}
                variant="ghost"
                colorScheme="green"
                aria-label="Approve"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedReview(row.original);
                  onOpenApprove();
                }}
                disabled={row.original?.status !== 'waiting'}
              />
            </Tooltip>

            <Tooltip label="Reject">
              <IconButton
                icon={<Icon boxSize={4} as={MdCancel} />}
                variant="ghost"
                colorScheme="red"
                aria-label="Reject"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedReview(row.original);
                  onOpenReject();
                }}
                disabled={row.original?.status !== 'waiting'}
              />
            </Tooltip>
            {row?.original.role_name === 'admin' && (
              <>
                {/* <Tooltip label="Edit">
                <IconButton
                  icon={<Icon boxSize={4} as={FiEdit} />}
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelectedReview(row.original);
                    onOpenEditReview();
                  }}
                />
              </Tooltip> */}
                <Tooltip label="Delete">
                  <IconButton
                    icon={<Icon boxSize={4} as={FiTrash2} />}
                    variant="ghost"
                    colorScheme="red"
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSelectedReview(row.original);
                      onOpenDeleteReview();
                    }}
                  />
                </Tooltip>
              </>
            )}
          </HStack>
        ),
      },
    ]),
    [spotId],
  );
  return (
    <>
      <Table
        columns={columns}
        data={data?.items || []}
        onRowClick={(row) => {
          setSelectedReview(row.original);
          onOpenReviewDetail();
        }}
      />
      <ApproveReviewPhotoModal
        isOpen={isOpenApprove}
        onClose={onCloseApprove}
        selectedReview={selectedReview}
      />
      <RejectDialog
        isOpen={isOpenReject}
        onClose={onCloseReject}
        title="Confirm"
        handleOk={handleReject}
        loading={mutationUpdateStatus.isLoading}
        options={REVIEW_PHOTO_REJECT_OPTIONS}
      />

      <ReviewDetail
        isOpen={isOpenReviewDetail}
        onClose={onCloseReviewDetail}
        selectedReview={selectedReview}
      />

      {/* <CreateReview
        isOpen={isOpenEditReview}
        onClose={onCloseEditReview}
        reviewType="photo"
        selectedReview={selectedReview}
      /> */}

      <ConfirmDialog
        title="Confirm"
        description="Do you want delete this review?"
        handleOk={handleDeleteReview}
        isOpen={isOpenDeleteReview}
        onClose={onCloseDeleteReview}
        loading={mutationDeleteReview.isLoading}
      />
    </>

  );
}

function ReviewTableWrapper({ spotId, spotStatus }: ReviewTableProps) {
  return (
    <Suspense fallback="loading...">
      <ReviewTable spotId={spotId} spotStatus={spotStatus} />
    </Suspense>
  );
}

export default ReviewTableWrapper;

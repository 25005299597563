import components from "./components";
import foundations from "./foundations";
import styles from "./styles";

export type ThemeDirection = "ltr" | "rtl";

export interface ThemeConfig {
  cssVarPrefix?: string;
  useSystemColorMode: boolean;
  initialColorMode: string;
}
const direction: ThemeDirection = "ltr";

const config: ThemeConfig = {
  useSystemColorMode: false,
  initialColorMode: "dark",
  cssVarPrefix: "xtrip",
};

export const theme = {
  direction,
  ...foundations,
  components,
  styles,
  config,
};

export type Theme = typeof theme;

/**
 * @deprecated
 * Duplicate theme type. Please use `Theme`
 */
export type DefaultChakraTheme = Theme;

export default theme;

import { useQuery, useQueryClient, useMutation } from "react-query";
import axios from "src/config/axios";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
// import { Partial } from "src/types/Partial";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { useToast } from "@chakra-ui/react";
import { DEFAULT_PAGE_SIZE } from "../constants/pagination";

export const SEARCH_TYPE_OPTIONS = [{
  label: 'Email',
  value: 'email',
}, {
  label: 'Customer name',
  value: 'customerName',
}];

export const SEARCH_TYPE_PLACEHOLDER = {
  email: 'email',
  customerName: 'customer name',
};

export interface TransactionDocument extends TimeStamp, ObjectId {

}

export interface AddTokenDto extends TimeStamp, ObjectId {
  token: string,
  amount: string
}

export interface GetTopUsersDto {
  trans_flow?: string
}

export interface GetTransactionDto {
  trans_flow?: string,
  user_id?: string,
  search?: string,
  search_type?: string,
  token?: string,
  trans_type?: string,
  trans_state?: string,
  start_date?: string,
  end_date?: string
}

export interface GetDailyTransactionDto {
  start_date: string,
  end_date: string,
  token: string
}

export interface GetTokenManagementDto {
  token: string
}

export interface DailyTransactionsDto extends TimeStamp, ObjectId {
  total_earn_token: string,
  total_spend_token: string,
  token: string,
  transaction_date: string
}

export interface TokenManagementDto extends TimeStamp, ObjectId {
  token: string,
  total_earn_token: number,
  total_remain_token: number,
  total_spend_token: number,
  total_supply_token: number
}

export interface GetTokenHistoryDto {
  token?: string,
  start_date?: string,
  end_date?: string
}

export interface TransactionTypeReportDto {
  trans_type?: string,
  total_transaction?: string,
  total_token?: string
}

export interface TokenHistory {

}

export interface GetReportTransactionType {
  token?: string,
  start_date?: string,
  end_date?: string
}

export const transactionsKey = generateQueryKey("transactions");

// ===== Get daily transactions ======
export const getDailyTransactions = async (
  params?: QueryOptionParams & GetDailyTransactionDto,
) => {
  try {
    const { data } = await axios.get<DailyTransactionsDto[]>(
      "/admin/transaction/daily",
      {
        params,
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useDailyTransactions = (params?: QueryOptionParams & GetDailyTransactionDto) => (
  useQuery(
    transactionsKey.list({ ...params, daily: true }),
    () => getDailyTransactions(params),

    { suspense: false },
  )
);

// ===== Get daily transactions ======
export const getTransactionTypeReport = async (
  params?: QueryOptionParams & GetReportTransactionType,
) => {
  try {
    const { data } = await axios.get(
      "/admin/transaction/count/type",
      {
        params,
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useTransactionTypeReport = (params?: QueryOptionParams & GetReportTransactionType) => (
  useQuery(
    transactionsKey.list({ ...params, transactionTypeReport: true }),
    () => getTransactionTypeReport(params),

    { suspense: false },
  )
);

// ===== Get token managements ======
export const getTokenManagements = async (
  params?: QueryOptionParams & GetTokenManagementDto,
) => {
  try {
    const { data } = await axios.get<TokenManagementDto>(
      "/admin/token-management",
      {
        params,
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useTokenManagements = (params?: QueryOptionParams & GetTokenManagementDto) => (
  useQuery(
    transactionsKey.list({ ...params, tokenManagement: true }),
    () => getTokenManagements(params),

    { suspense: false },
  )
);

// ===== Get token histories ======
export const getTokenHistories = async (
  params?: QueryOptionParams & GetTokenHistoryDto,
) => {
  try {
    const { data } = await axios.get<PaginateDocument<TokenHistory>>(
      "/admin/token-management/history",
      {
        params,
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useTokenHistories = (params?: QueryOptionParams & GetTokenHistoryDto) => (
  useQuery(
    transactionsKey.list({ ...params, tokenHistories: true }),
    () => getTokenHistories(params),

    { suspense: false },
  )
);

// ===== Get supply transactions ======
export const getSupplyTransactions = async (
  params?: QueryOptionParams & GetTransactionDto,
) => {
  try {
    const { data } = await axios.get<PaginateDocument<TransactionDocument>>(
      "/admin/transaction/search",
      {
        params: {
          page: 1, limit: DEFAULT_PAGE_SIZE, ...params,
        },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useSupplyTransactions = (params?: QueryOptionParams & GetTransactionDto) => (
  useQuery(transactionsKey.list(params), () => getSupplyTransactions(params), { suspense: false })
);

// ===== Get transactions ======
export const getTransactions = async (
  params?: QueryOptionParams & GetTransactionDto,
) => {
  try {
    const { data } = await axios.get<PaginateDocument<TransactionDocument>>(
      "/admin/transaction/search",
      {
        params: {
          page: 1, limit: DEFAULT_PAGE_SIZE, ...params,
        },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useTransactions = (params?: QueryOptionParams & GetTransactionDto) => (
  useQuery(transactionsKey.list(params), () => getTransactions(params), { suspense: false })
);

// ===== Get top user transfer ======
export const getTopUser = async (
  params?: QueryOptionParams & GetTopUsersDto,
) => {
  try {
    const { data } = await axios.get<PaginateDocument<TransactionDocument>>(
      "/admin/transaction/top-users",
      {
        params: {
          page: 1, limit: DEFAULT_PAGE_SIZE, ...params,
        },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useTopUsers = (params?: QueryOptionParams & GetTopUsersDto) => (
  useQuery(
    transactionsKey.list({ ...params, topUser: true }),
    () => getTopUser(params),

    { suspense: false },
  )
);

// ===== Get review overview and feedback ======
export const addTokens = async (values: AddTokenDto) => {
  try {
    await axios.put(
      "/admin/token-management/add-token",
      {
        ...values,
      },
    );
    return true;
  } catch (e) {
    return null;
  }
};

export const useAddTokens = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(addTokens, {
    onSuccess: () => {
      queryClient.invalidateQueries(transactionsKey.all);
      toast({ status: "success", description: "Update successful!" });
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};
export const withdrawApprovals = generateQueryKey("withdrawApprovals");
export const getWithdrawApproval = async () => {
  try {
    const { data } = await axios.get('/admin/transaction/withdraw-waiting-approve');
    return data;
  } catch (e) {
    return [];
  }
};

export const useWithdrawApproval = () => (useQuery(withdrawApprovals.all, getWithdrawApproval));

export const handleWithdraw = async (body: {action: string, id: number}) => {
  const { data } = await axios.post(`/admin/transaction/withdraw-${body.action}`, { id: body.id });
  return data;
};

export const useHandleWithdraw = () => {
  const queryClient = useQueryClient();
  return useMutation(handleWithdraw, {
    onSuccess: () => {
      queryClient.invalidateQueries(withdrawApprovals.all);
    },
  });
};

export const getDailyUserWithdrawRequest = async (userId: number, token: string) => {
  const { data } = await axios.get(`/admin/transaction/withdraw-daily-history?userId=${userId}&token=${token}`);
  return data;
};

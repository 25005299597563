import React, { Suspense } from "react";
import {
  Box,
  Flex,
  Link,
  Text,
  Heading,
} from "@chakra-ui/react";
import {
  useTokenManagements, useTopUsers, useTransactions, useTransactionTypeReport,
} from "src/api/transaction";
import { Link as RouterLink } from 'react-router-dom';
import TransactionTable from '../Transactions/TransactionTable';
import TopUserTransactions from './TopUserTransactions';
import OverallTransactions from "./OverallTransactions";
import TransactionsByDay from './TransactionsByDay';
import TransactionTypeReportWrapper from "./TransactionTypeReport";
// import CategoryChart from './CategoryChart';
// import Summary from "./Summary";

function Home() {
  const { data: xtrToken } = useTokenManagements({ token: 'XTR' });
  const { data: gxtToken } = useTokenManagements({ token: 'GXT' });
  const { data: avaxToken } = useTokenManagements({ token: 'AVAX' });
  const { data: transactionTypeReport } = useTransactionTypeReport({ limit: 100 });

  // const { data } = useTransactions();
  const { data: transactionEarns } = useTransactions({
    trans_flow: 'add',
    limit: 10,
    page: 1,
  });
  const { data: transactionSpends } = useTransactions({
    trans_flow: 'sub',
    limit: 10,
    page: 1,
  });
  const { data: topUsersEarn } = useTopUsers({
    trans_flow: 'add',
    limit: 10,
    page: 1,
  });
  const { data: topUsersSpend } = useTopUsers({
    trans_flow: 'sub',
    limit: 10,
    page: 1,
  });
  return (
    <Box p="24px">
      <Heading size="md" textTransform="uppercase" color="gray.400" mb="24px">
        Overview
      </Heading>
      <Flex mb="24px">
        <Box flex={1}>
          {' '}
          <OverallTransactions token="XTR" data={xtrToken} />
        </Box>
        <Box flex={1} mx="20px">
          {' '}
          <OverallTransactions token="GXT" data={gxtToken} />
        </Box>
        <Box flex={1}>
          {' '}
          <OverallTransactions token="AVAX" data={avaxToken} />
        </Box>
      </Flex>
      {/* <Summary data={xtrToken} />

      <Summary data={gxtToken} />

      <Summary data={avaxToken} /> */}

      {/* <Flex mb="24px" mx="-10px">
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="12px" fontWeight="600" textTransform="uppercase">
            Review overview
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            12,000 XRT
          </Text>

          <CategoryChart />

          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="12px" fontWeight="600" textTransform="uppercase">
            Review feedback
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            1,000 XRT
          </Text>
          <CategoryChart />
          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="12px" fontWeight="600" textTransform="uppercase">
            Review photo
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            1,000 XRT
          </Text>
          <CategoryChart />
          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="12px" fontWeight="600" textTransform="uppercase">
            Travel rewards
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            1,000 XRT
          </Text>
          <CategoryChart />
          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="12px" fontWeight="600" textTransform="uppercase">
            Gift/Box
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            1,000 XRT
          </Text>
          <CategoryChart />
          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="11px" fontWeight="600" textTransform="uppercase">
            Add spot
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            1,000 XRT
          </Text>
          <CategoryChart />
          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
      </Flex>

      <Flex mb="24px" mx="-10px">
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="12px" fontWeight="600" textTransform="uppercase">
            Deposit
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            12,000 XRT
          </Text>
          <CategoryChart />
          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="12px" fontWeight="600" textTransform="uppercase">
            Withdraw
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            1,000 XRT
          </Text>
          <CategoryChart />
          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="12px" fontWeight="600" textTransform="uppercase">
            Levelup item
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            1,000 XRT
          </Text>
          <CategoryChart />
          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="12px" fontWeight="600" textTransform="uppercase">
            Buy items
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            1,000 XRT
          </Text>
          <CategoryChart />
          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="12px" fontWeight="600" textTransform="uppercase">
            Mint item
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            1,000 XRT
          </Text>
          <CategoryChart />
          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="20px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          flex={1}
          mx="10px"
        >
          <Text fontSize="11px" fontWeight="600" textTransform="uppercase">
            Burn item
          </Text>
          <Text fontSize="18px" fontWeight="bold" color="var(--ninjakids-colors-blue-300)">
            1,000 XRT
          </Text>
          <CategoryChart />
          <Link
            as={RouterLink}
            to="/supply-transactions"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            mt="10px"
          >
            <Text fontSize="12px">View transactions</Text>
          </Link>
        </Box>
      </Flex> */}

      <Box
        borderRadius="11px"
        border="1px"
        borderColor="rgba(255, 255, 255, 0.16)"
        p="40px"
        backgroundColor="var(--ninjakids-colors-gray-800)"
        mt="32px"
        flex={1}
        mx="10px"
      >
        <Text fontSize="32px" fontWeight="bold" mb="40px" textAlign="center">
          Category report
        </Text>

        <TransactionTypeReportWrapper data={transactionTypeReport} />

      </Box>

      <Box mt="32px">
        <TransactionsByDay token="XTR" />
      </Box>

      <Box mt="32px">
        <TransactionsByDay token="GXT" />
      </Box>

      <Box mt="32px">
        <TransactionsByDay token="AVAX" />
      </Box>

      <Flex mx="-10px">
        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="40px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          mt="32px"
          flex={1}
          mx="10px"
        >
          <Text fontSize="32px" fontWeight="bold" mb="40px" textAlign="center">
            Top users earn
          </Text>

          <TopUserTransactions data={topUsersEarn} />

          <Link
            as={RouterLink}
            to="/transaction-earn"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            textAlign="center"
            mt="24px"
          >
            <Text>View more</Text>
          </Link>

        </Box>

        <Box
          borderRadius="11px"
          border="1px"
          borderColor="rgba(255, 255, 255, 0.16)"
          p="40px"
          backgroundColor="var(--ninjakids-colors-gray-800)"
          mt="32px"
          flex={1}
          mx="10px"
        >

          <Text fontSize="32px" fontWeight="bold" mb="40px" textAlign="center">
            Top users spend
          </Text>
          <TopUserTransactions data={topUsersSpend} />

          <Link
            as={RouterLink}
            to="/transaction-spend"
            fontWeight="semibold"
            color="blue.300"
            display="block"
            textAlign="center"
            mt="24px"
          >
            <Text>View more</Text>
          </Link>

        </Box>
      </Flex>

      <Box
        borderRadius="11px"
        border="1px"
        borderColor="rgba(255, 255, 255, 0.16)"
        p="40px"
        backgroundColor="var(--ninjakids-colors-gray-800)"
        mt="32px"
      >
        <Text fontSize="32px" fontWeight="bold" mb="40px" textAlign="center">
          Lastest Earn transactions
        </Text>
        <TransactionTable data={transactionEarns} />

        <Link
          as={RouterLink}
          to="/transaction-earn"
          fontWeight="semibold"
          color="blue.300"
          display="block"
          textAlign="center"
          mt="24px"
        >
          <Text>View more</Text>
        </Link>
      </Box>
      <Box
        borderRadius="11px"
        border="1px"
        borderColor="rgba(255, 255, 255, 0.16)"
        p="40px"
        backgroundColor="var(--ninjakids-colors-gray-800)"
        mt="32px"
      >
        <Text fontSize="32px" fontWeight="bold" mb="40px" textAlign="center">
          Lastest Spend transactions
        </Text>
        <TransactionTable data={transactionSpends} />

        <Link
          as={RouterLink}
          to="/transaction-spend"
          fontWeight="semibold"
          color="blue.300"
          display="block"
          textAlign="center"
          mt="24px"
        >
          <Text>View more</Text>
        </Link>

      </Box>

    </Box>
  );
}

export function HomeWrapper() {
  return (
    <Suspense fallback="loading...">
      <Home />
    </Suspense>
  );
}

export default HomeWrapper;

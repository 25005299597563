import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import ItemForm from "./ItemForm";
import {
  CreateItemDto,
  itemKeys,
  updateItem,
  ItemDocument,
} from "../../api/item";

const FORM_ID = "add-item";

interface ItemModalAddProps {
  isOpen: boolean;
  onClose: () => void;
  editingData: ItemDocument;
}

function ItemModalEdit({
  isOpen,
  onClose,
  editingData,
}: ItemModalAddProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();
  const onSubmit = async (values: CreateItemDto) => {
    try {
      await updateItem(editingData.id, {
        ...values,
      });
      onClose();
      toast({ status: "success", description: "Success update" });
      queryClient.invalidateQueries(itemKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Error" });
    }
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit spot</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ItemForm
            initialValues={{ ...editingData }}
            onSubmit={onSubmit}
            formId={FORM_ID}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ItemModalEdit;

import axios from "src/config/axios";
import { useQuery } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";

import generateQueryKey from "src/utils/queryKeyFactory";

export interface RoleDocument extends TimeStamp, ObjectId {
  role_name: string;
  permissions: string;
}

export const getAllRoles = async () => {
  try {
    const { data } = await axios.get<RoleDocument[]>("/role");
    return data;
  } catch (e) {
    return null;
  }
};

// hooks
export const roleKeys = generateQueryKey("role");

export const useAllRoles = () => useQuery(roleKeys.all, () => getAllRoles());

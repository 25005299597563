import React from "react";
import {
  Modal,
  Heading,
  VStack,
  Button,
  useToast,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import { Form } from "react-final-form";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import LabeledTextField from "../../components/LabeledTextField";
import { LoginDto, login, currentUserKey } from "../../api/user";
import { ACCESS_TOKEN } from "../../constants/localStorage";

interface LoginProps {
  resetErrorBoundary?: () => void;
  isOpen?: boolean;
}

function Login({ resetErrorBoundary, isOpen = true }: LoginProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSubmit = async (values: LoginDto) => {
    try {
      const result = await login(values);
      localStorage.setItem(ACCESS_TOKEN, result?.token);
      queryClient.invalidateQueries(currentUserKey);
      if (resetErrorBoundary) {
        resetErrorBoundary();
      }
      navigate('/');
    } catch (error) {
      toast({
        status: "error",
        description: "Sai tên truy cập hoặc mật khẩu",
        position: "top-right",
        isClosable: true,
      });
    }
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent p={8}>
        <VStack align="stretch">
          <Heading size="lg" mb={4}>
            Welcome to xTrip console!
          </Heading>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <VStack onSubmit={handleSubmit} as="form" spacing={4}>
                <LabeledTextField name="email" label="Email" />
                <LabeledTextField
                  name="password"
                  label="Password"
                  type="password"
                />
                <Button type="submit" w="full">
                  Đăng nhập
                </Button>
              </VStack>
            )}
          />
        </VStack>
      </ModalContent>
    </Modal>
  );
}

export default Login;

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";

import {
  Box, Button, Divider, Flex, HStack, Icon, IconButton, Select,
  Text, Textarea, Tooltip,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { CellValue, Column } from "react-table";
import { useCreateTranslate, useSpotTranslate } from 'src/api/spot';
import Table from "src/components/Table";
import { FiEdit } from 'react-icons/fi';

interface SpotLanguageProps {
  isOpen: boolean;
  onClose: () => void;
  spotId: string;
  fieldName: string;
  fieldValue: string;
}
const languageOptions = [
  { value: "zh", label: "zh - Chinese" },
  { value: "en", label: "en - English" },
  { value: "ja", label: "jp - Japanese" },
  { value: "th", label: "th - Thai" },
  { value: "vi", label: "vi - Vietnamese" },
];
function SpotTranslateModal({
  isOpen, onClose, spotId, fieldName, fieldValue,
}: SpotLanguageProps) {
  // const { data: languages } = useLanguages();
  const { mutate } = useCreateTranslate();
  // const languageOptions = useMemo(() => languages.map((language) => ({
  //   value: language.code,
  //   label: `${language.code} - ${language.name}`,
  // })), [languages]);
  const [disableInput, setDisableInput] = useState(true);
  const { data: existedContents } = useSpotTranslate({
    spot_id: spotId,
    field_name: fieldName,
  });
  const {
    handleSubmit,
    register,
    setFocus,
    setValue,
    // formState: { errors },
  } = useForm();
  const handleUpdate = (values: any) => {
    mutate({
      field_name: fieldName,
      field_value: values.value,
      spot_id: spotId,
      language_code: values.language,
    });
  };
  const columns = useMemo<Column[]>(
    () => [

      {
        Header: "Id",
        accessor: "",
        Cell: ({ row }: any) => (
          <HStack>
            <Text>{row.original.id}</Text>
          </HStack>
        ),
      },
      {
        Header: "Lang",
        accessor: "language_code",
        Cell: ({ value }: any) => (
          <HStack>
            <Box w="50px">
              <Text>{value}</Text>
            </Box>
          </HStack>
        ),
      },
      {
        Header: "Content",
        accessor: "field_value",
        Cell: ({ value }: any) => (
          <HStack>
            <Box w="300px">
              <Text>{value}</Text>

            </Box>
          </HStack>
        ),
      },
      {
        Header: "Edit",
        accessor: "id",
        Cell: ({ row }: CellValue) => (
          <HStack spacing={4}>
            <Tooltip>
              <IconButton
                icon={(
                  <Icon
                    boxSize={4}
                    as={FiEdit}
                  />
                )}
                variant="ghost"
                colorScheme="blue"
                aria-label="Edit"
                onClick={() => {
                  setValue('language', row.original.language_code);
                  setValue('value', row.original.field_value);
                  setFocus('value');
                  setDisableInput(false);
                }}
              />
            </Tooltip>
          </HStack>
        ),
      },
    ], // eslint-disable-next-line
    []
  );
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Translate spot id:
          {' '}
          {spotId}
          ,  field:
          {' '}
          {fieldName}
          ,  value:
          {' '}
          {fieldValue}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={4}>
          <form>
            <Table columns={columns} data={existedContents || []} />
            <Divider my={6} />
            <Select
              placeholder="Select option"
              {...register("language")}
              h="40px"
              bgColor="rgba(255, 255, 255, 0.04)"
              border="0"
              mb={4}
              onChange={(e) => {
                const { value } = e.target;
                setDisableInput(isEmpty(value));
                if (!isEmpty(e.target.value)) {
                  const t = existedContents?.find((content) => content.language_code === value);
                  if (!isEmpty(t)) {
                    setValue('value', t.field_value);
                  } else {
                    setValue('value', '');
                  }
                }
                setFocus('value');
              }}
            >
              {languageOptions?.map((country) => (
                <option
                  value={country.value}
                  // selected={country.value === data?.country}
                  key={country.value}
                >
                  <Text textTransform="capitalize">{country.label}</Text>
                </option>
              ))}
            </Select>
            <Textarea
              placeholder="Content"
              disabled={disableInput}
              size="md"
              // defaultValue={defaultAddingToken}
              borderRadius="6px"
              {...register("value")}
              mb={4}
            // ref={inputRef}
            />
            <Flex justifyContent="flex-end">
              <Button
                colorScheme="blue"
                type="submit"
                name="action"
                value="approve"
                variant="solid"
                onClick={handleSubmit(handleUpdate)}
              >
                Update
              </Button>
            </Flex>

          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SpotTranslateModal;

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, useDisclosure } from "@chakra-ui/react";
import ConfirmDialog from "src/components/ConfirmDialog";
import { useState } from "react";
import ApproveReviewPhotoForm from "./ApproveReviewPhotoForm";
import { ApproveReviewDto, ReviewDocument, useApproveReview } from "../../api/review";

const FORM_ID = "approve-review-photo";

interface ApproveReviewProps {
  isOpen: boolean;
  onClose: () => void;
  selectedReview: ReviewDocument | undefined
}

function ApproveReviewModal({ isOpen, onClose, selectedReview }: ApproveReviewProps) {
  const mutation = useApproveReview();

  const [review, setReview] = useState<ApproveReviewDto>();
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();

  const onSubmit = async (values: ApproveReviewDto) => {
    setReview(values);
    onOpenConfirm();
  };

  const handleApprove = async () => {
    onCloseConfirm();
    if (review) {
      await mutation.mutateAsync(review);
    }
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Approve review photos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ApproveReviewPhotoForm
            onSubmit={onSubmit}
            formId={FORM_ID}
            initialValues={selectedReview}
            selectedReview={selectedReview}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button
            form={FORM_ID}
            type="submit"
            colorScheme="blue"
            isLoading={mutation.isLoading}
          >
            Approve
          </Button>
        </ModalFooter>
      </ModalContent>

      <ConfirmDialog
        title="Confirm"
        description={`Are you sure want to give this user ${review?.token_number} of token ?`}
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        handleOk={handleApprove}
      />
    </Modal>
  );
}

export default ApproveReviewModal;

import React, { Suspense, useState } from "react";
import { CellValue, Column } from "react-table";
import { useParams } from "react-router-dom";

import Table from "src/components/Table";
import {
  Tooltip,
  Text,
  HStack,
  IconButton,
  Icon,
  useDisclosure,
  Link,
  Badge,
} from "@chakra-ui/react";
import { renderSpotStatusColor } from "src/utils/color";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdRestore } from "react-icons/md";
import { SpotAction, useSpot, useSpotNearBy } from "../../api/spot";
import SpotDelete from "../spot/SpotModalDelete";

function NearByTable() {
  const [deleteData, setDeleteData] = useState<string>();
  const [action, setAction] = useState(SpotAction.Archive);
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();
  const deleteSpot = async (id: string) => {
    onDeleteOpen();
    setDeleteData(id);
  };
  const { id = "" } = useParams();
  const { data: nearBy = [] } = useSpotNearBy(id || "");
  const { data } = useSpot(id || "");
  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Id",
        accessor: "",
        Cell: ({ row }: any) => (
          <HStack>
            <Text>{row.original.id}</Text>
          </HStack>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, row }: any) => (
          <Link
            href={`/spot/${row.original.id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            <HStack>
              <Text>{value}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Distance (m)",
        accessor: "distance",
        Cell: ({ value }: CellValue) => <Text>{value ? value.toFixed(2) : ""}</Text>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: CellValue) => (
          <Badge
            variant="outline"
            colorScheme={renderSpotStatusColor(value)}
            px="2"
            py="1"
          >
            {value}
          </Badge>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ value, row }: CellValue) => {
          const isActive = row.original.is_deleted === 0;
          return (
            <HStack spacing={4}>
              <Tooltip label={isActive ? "Archive" : "Restore"}>
                <IconButton
                  icon={(
                    <Icon
                      boxSize={4}
                      as={isActive ? RiDeleteBin6Line : MdRestore}
                    />
                  )}
                  variant="ghost"
                  colorScheme={isActive ? "red" : "gray"}
                  aria-label="delete"
                  onClick={() => {
                    if (isActive) {
                      setAction(SpotAction.Archive);
                    } else {
                      setAction(SpotAction.Restore);
                    }
                    deleteSpot(value);
                  }}
                />
              </Tooltip>
            </HStack>
          );
        },
      },
    ], // eslint-disable-next-line
    []
  );
  return (
    <>
      <SpotDelete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        id={deleteData as string}
        action={action}
      />
      <Table columns={columns} data={[data, ...nearBy || []] || []} />
    </>
  );
}

function NearByTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <NearByTable />
    </Suspense>
  );
}

export default NearByTableWrapper;

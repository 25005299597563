/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  Image,
  Input,
  IconButton,
  HStack,
  Tooltip,
  useToast,
  Spacer,
  Flex,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import {
  getUploadUrlSpotPhoto, IMAGE_FILE_TYPE, createSpotReviewImage, reviewImagesKeys,
} from 'src/api/review';
import axios from "axios";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { BsUpload } from "react-icons/bs";

interface ImageFile extends File {
  preview: string;
}

function UploadForm() {
  const queryClient = useQueryClient();

  const { id = "" } = useParams();
  const [images, setImages] = useState<ImageFile[]>([]);
  const toast = useToast({ position: "top" });
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    for (let index = 0; index < fileList.length; index += 1) {
      const file = fileList[index];
      if (file.size > 1024 * 1024 * 5) {
        toast({ status: "error", description: "Please choose files have size less than 5mb!" });
        return;
      }
    }

    const fileArray = Array.from(fileList).map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    }));

    setImages([...images, ...fileArray]);
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const presignedUrls = await Promise.all(
      images.map(() => getUploadUrlSpotPhoto(IMAGE_FILE_TYPE.PNG)),
    );

    await Promise.all(
      images.map(async (image, index) => {
        // eslint-disable-next-line no-param-reassign
        const config = {
          method: 'put',
          url: presignedUrls[index].signedRequest,
          headers: {
            'Content-Type': 'image/png',
          },
          data: image,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(`Đang tải lên: ${progress}%`);
          },
        };
        await axios(config);
      }),
    );
    const imageUrls = presignedUrls.map((u) => u.url as string);
    try {
      await createSpotReviewImage(id, imageUrls);
      setImages([]);
      queryClient.invalidateQueries(reviewImagesKeys.detail(id));
      toast({ status: "success", description: "Upload successful!" });
    } catch (error) {
      console.log("error", error);
      toast({ status: "error", description: "Update failed!" });
    }
  };

  return (
    <Box width="100%" textAlign="center" p={0} mt={4}>
      <form onSubmit={handleSubmit}>
        <HStack m={0} flexDirection="row-reverse">
          <Input
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileChange}
            display="none"
            id="upload"
          />
          <Box htmlFor="upload" as="label" marginInline={0}>
            <Button colorScheme="blue" as="span" variant="outline" leftIcon={<BsUpload />}>
              Select image
            </Button>
          </Box>

          {images.length > 0
            && (
              <>
                <Button
                  leftIcon={<MdClose />}
                  size="sm"
                  colorScheme="red"
                  onClick={() => setImages([])}
                  variant="outline"
                  disabled={images.length === 0}
                >
                  Remove all
                </Button>
                <Spacer />

                <Button type="submit" colorScheme="teal" disabled={images.length === 0} mt={4}>
                  Upload
                </Button>
              </>
            )}
        </HStack>

        {images.length > 0 && (
          <Flex flexWrap="wrap" mt={4} gap={4}>
            {images.map((image, index) => (
              <VStack
                key={index}
                align="center"
                justify="space-between"
                p="2"
                bg="gray.800"
                position="relative"
                borderRadius="md"
                width={250}
              >
                <Text flex="1">
                  {image.name.substring(0, 15)}
                  ...
                </Text>
                <Tooltip label="Remove ">
                  <IconButton
                    aria-label="Remove image"
                    position="absolute"
                    right={0}
                    top={0}
                    icon={<MdClose />}
                    size="sm"
                    variant="outline"
                    border="none"
                    colorScheme="blue"
                    onClick={() => handleRemoveImage(index)}
                  />
                </Tooltip>
                <Image src={image.preview} width="150" height="150" objectFit="contain" borderRadius="md" mr="2" />

              </VStack>
            ))}

          </Flex>

        )}

      </form>
    </Box>
  );
}

export default UploadForm;

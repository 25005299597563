import React, { Suspense, useState } from "react";
import { CellValue, Column } from "react-table";
import Table from "src/components/Table";
import dayjs from "dayjs";
import {
  Tooltip,
  Text,
  HStack,
  IconButton,
  Icon,
  useDisclosure,
  Link,
  Badge,
} from "@chakra-ui/react";
// import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineLocationOn, MdRestore } from "react-icons/md";
import { TbPolygon } from "react-icons/tb";
import { Link as RouterLink } from "react-router-dom";
import { renderSpotStatusColor } from "src/utils/color";
import useSearchParams from "../../utils/useSearchParams";
import SpotModalEdit from "./SpotModalEdit";
import {
  useSpots, SpotDocument, SpotAction,
} from "../../api/spot";
import SpotDeleteRestore from "./SpotModalDelete";

function SpotTable() {
  const { params } = useSearchParams();
  const { data } = useSpots(params);
  const [editingData] = useState<SpotDocument>();
  const [deleteData, setDeleteData] = useState<string>();
  const [action, setAction] = useState(SpotAction.Archive);
  const {
    isOpen: isEditOpen,
    onClose: onEditClose,
    // onOpen: onEditOpen,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();
  // const editSpot = (spot: SpotDocument) => {
  //   onEditOpen();
  //   setEditingData(spot);
  // };
  const deleteSpot = async (id: string) => {
    onDeleteOpen();
    setDeleteData(id);
  };

  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, row }: any) => (
          <Link
            as={RouterLink}
            to={`/spot/${row.original.id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            <HStack>
              <Text>{value}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "User",
        accessor: "user",
        Cell: ({ value, row }: any) => (
          <Link
            as={RouterLink}
            to={`/user/${row.original?.user?.id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            <HStack>
              {value && (
                <Text>
                  {value?.full_name}
                  <br />
                  {value?.email}
                </Text>
              )}
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Country",
        accessor: "country",
        Cell: ({ value }: CellValue) => <Text>{value}</Text>,
      },
      {
        Header: "City",
        accessor: "city",
        Cell: ({ value }: CellValue) => <Text>{value}</Text>,
      },
      {
        Header: "Point type",
        accessor: "point_type",
        Cell: ({ value }: CellValue) => (
          <HStack>
            <Tooltip label={value !== 'point' ? 'Polygon' : "point"} placement="right">
              <HStack>
                <Icon
                  boxSize={6}
                  color={value !== 'point' ? 'orange.300' : "gray.300"}
                  as={value !== 'point' ? TbPolygon : MdOutlineLocationOn}
                />
              </HStack>
            </Tooltip>
          </HStack>
        ),
      },
      {
        Header: "Type",
        accessor: "spot_type",
        Cell: ({ value }: CellValue) => <Text>{value?.name || value?.type_code}</Text>,
      },
      {
        Header: "GG address",
        accessor: "address",
        Cell: ({ value }: CellValue) => (
          <HStack>
            <Tooltip label={value} placement="right">
              <Text>
                {value && value.substring(0, 20)}
                {value && value.length > 20 && "..."}
              </Text>
            </Tooltip>
          </HStack>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: CellValue) => (
          <Badge
            variant="outline"
            colorScheme={renderSpotStatusColor(value)}
            px="2"
            py="1"
          >
            {value}
          </Badge>
        ),
      },
      {
        Header: "Create",
        accessor: "created_at",
        Cell: ({ value }: CellValue) => <Text>{dayjs(value).format("l")}</Text>,
      },
      // {
      //   Header: "Update",
      //   accessor: "updated_at",
      //   Cell: ({ value }) => <Text>{dayjs(value).format("l")}</Text>,
      // },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ value, row }: CellValue) => {
          const isActive = row.original.is_deleted === 0;
          return (
            <HStack spacing={4}>
              <Tooltip label={isActive ? "Archive" : "Restore"}>
                <IconButton
                  icon={(
                    <Icon
                      boxSize={4}
                      as={isActive ? RiDeleteBin6Line : MdRestore}
                    />
                  )}
                  variant="ghost"
                  colorScheme={isActive ? "red" : "gray"}
                  aria-label="delete"
                  onClick={() => {
                    if (isActive) {
                      setAction(SpotAction.Archive);
                    } else {
                      setAction(SpotAction.Restore);
                    }
                    deleteSpot(value);
                  }}
                />
              </Tooltip>
            </HStack>
          );
        },
      },
    ], // eslint-disable-next-line
    []
  );

  return (
    <>
      <SpotModalEdit
        isOpen={isEditOpen}
        onClose={onEditClose}
        editingData={editingData as SpotDocument}
      />
      <SpotDeleteRestore
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        id={deleteData as string}
        action={action}
      />
      <Table columns={columns} data={data?.items || []} />
    </>
  );
}

function SpotTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <SpotTable />
    </Suspense>
  );
}

export default SpotTableWrapper;

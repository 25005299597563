import {
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from "@chakra-ui/modal";
import {
  Box, Button, HStack, Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";

interface props {
  isOpen: boolean;
  onClose: () => void;
  data: any,
}
function WithdrawStatisModal({
  isOpen, onClose, data = {},
}: props) {
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Withdraw static (
          {dayjs().format('YYYY-MM-DD')}
          )
          <Text>{`User #${data.userId} - Token: ${data.token}`}</Text>
        </ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <Box>
            <HStack>
              <Text>{`Token by Logs: ${data.logToken}`}</Text>
            </HStack>
            <HStack>
              <Text>{`Token in wallet: ${data.balanceToken}`}</Text>
            </HStack>
            <HStack>
              <Text>{`Withdrawn Success: ${data.withdraw}`}</Text>
            </HStack>
            <HStack>
              <Text>{`Withdraw Failed: ${data.failed}`}</Text>
            </HStack>
            <HStack>
              <Text>{`Withdraw waiting approve: ${data.waitApprove}`}</Text>
            </HStack>
            <HStack>
              <Text>{`Withdraw canceled: ${data.cancelled}`}</Text>
            </HStack>
            <HStack>
              <Text>{`Withdraw pending: ${data.pending}`}</Text>
            </HStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>Ok</Button>
        </ModalFooter>
      </ModalContent>

    </Modal>
  );
}

export default WithdrawStatisModal;

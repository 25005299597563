import React, { Suspense, useCallback } from "react";
import {
  Text, HStack, Link, Badge, UnorderedList, ListItem,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useUserAll, useUserSate } from "src/api/user";
import { CellValue } from "react-table";
import dayjs from "dayjs";
import Table from "../../components/Table";
import useSearchParams from "../../utils/useSearchParams";
import { renderUserStateColor } from "../../utils/color";

function UserTable() {
  const { params } = useSearchParams();
  const { data } = useUserAll(params);
  const { data: userStates } = useUserSate();
  console.log("🚀 ~ file: UserTable.tsx:16 ~ UserTable ~ userStates", userStates);

  const renderStateText = useCallback(
    (state: number) => {
      const label = userStates?.find((d) => d[1] === state);
      return label![0];
    },
    [userStates],
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
        Cell: ({ value, row }: any) => (
          <Link
            as={RouterLink}
            to={`/user/${row?.original.id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            <HStack>
              <Text>{value}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role_name",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Invitation code",
        accessor: "invitation_code",
      },
      {
        Header: "Wallet",
        accessor: "wallet",
        Cell: ({ value }: CellValue) => (
          <UnorderedList>
            {value?.map((item) => (
              <ListItem>
                <b>{item.token}</b>
                :
                {' '}
                {item.balance}
              </ListItem>
            ))}
          </UnorderedList>
        ),
      },
      {
        Header: "State",
        accessor: "state",
        Cell: ({ value }: CellValue) => (
          <Badge
            variant="outline"
            colorScheme={renderUserStateColor(value)}
            px="2"
            py="1"
          >
            {renderStateText(value)}
          </Badge>
        ),
      },
      {
        Header: "Created at",
        accessor: "created_at",
        Cell: ({ value }: CellValue) => (
          <Text>
            {dayjs(value).format("L")}
          </Text>
        ),
      },
      {
        Header: "Updated at",
        accessor: "updated_at",
        Cell: ({ value }: CellValue) => (
          <Text>
            {dayjs(value).format("L")}
          </Text>
        ),
      },
    ],
    [],
  );
  return (
    <Table columns={columns} data={data?.items || []} />
  );
}

function UserTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <UserTable />
    </Suspense>
  );
}

export default UserTableWrapper;

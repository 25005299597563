import React, { Suspense } from "react";
import {
  Text,
} from "@chakra-ui/react";
import { Column } from "react-table";
import Table from "src/components/Table";

// eslint-disable-next-line react/prop-types
function UploadedSpotTable({ data, errorIndex }) {
  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: '#',
        id: 'index',
        accessor: (_row: any, i: number) => {
          // eslint-disable-next-line react/prop-types
          const isError = errorIndex && errorIndex.includes(i + 1);
          return (
            <Text color={isError && 'red.300'}>
              {i + 1}
            </Text>
          );
        },
      },
      { Header: "name", accessor: "name" },
      { Header: "country", accessor: "country" },
      { Header: "city", accessor: "city" },
      { Header: "type", accessor: "type" },
      { Header: "latitude", accessor: "latitude" },
      { Header: "longitude", accessor: "longitude" },
    ], // eslint-disable-next-line
    [errorIndex]
  );

  return (
    <Table columns={columns} data={data || []} />
  );
}

function UploadedSpotTableWrapper({ data, errorIndex }: any) {
  return (
    <Suspense fallback="loading...">
      <UploadedSpotTable data={data} errorIndex={errorIndex} />
    </Suspense>
  );
}

export default UploadedSpotTableWrapper;

import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { formatStringToNumber } from 'src/utils/utils';
import FilterByRangerDate from 'src/components/FilterByRangerDate';
import dayjs from 'dayjs';
import { useDailyTransactions } from 'src/api/transaction';
import { DATE_FORMAT } from 'src/constants/date';

const os = 'unknown';

interface TransactionsByDayProps {
  token: string,
}

function TransactionsByDay({ token }: TransactionsByDayProps) {
  const [date, setDate] = useState({
    fromDate: dayjs().add(-30, 'days').format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD'),
  });

  const { data } = useDailyTransactions({
    start_date: date.fromDate,
    end_date: date.toDate,
    token,
  });

  const handleApplyFilter = (fromDate, toDate) => {
    setDate({
      fromDate,
      toDate,
    });
  };

  const handleDeleteFilter = () => {
    setDate({
      fromDate: dayjs().add(-30, 'days').format('YYYY-MM-DD'),
      toDate: dayjs().format('YYYY-MM-DD'),
    });
  };

  return (
    <Box borderRadius="11px" border="1px" borderColor="rgba(255, 255, 255, 0.16)" p="20px" backgroundColor="var(--ninjakids-colors-gray-800)">
      <Box position="relative">
        <Text fontSize="32px" fontWeight="bold" textAlign="center" mb="48px" mt="20px">
          Transactions
          {' '}
          {token}
          {' '}
          by day
        </Text>
        <Box position="absolute" top="10px" right="20px">
          <FilterByRangerDate
            date={date}
            handleApplyFilter={handleApplyFilter}
            handleDeleteFilter={handleDeleteFilter}
            isCloseClickOutside={false}
          />
        </Box>

      </Box>

      <Box display="flex" justifyContent="center" w="full" h="400px" mx="auto" mb="20px" alignItems="center">
        { (data && data?.length > 0)
          ? (
            <ResponsiveContainer>
              <BarChart
                data={data || []}
                margin={{
                  top: 20,
                  right: 30,
                  left: 40,
                  bottom: 5,
                }}
              >
                <CartesianGrid stroke="#707070" vertical={false} />
                <XAxis
                  dataKey="transaction_date"
                  tickLine={false}
                  tick={{ fill: '#f8f8f8', fontSize: '12px' }}
                  tickFormatter={(label) => `${dayjs(label).format(DATE_FORMAT)}`}
                />
                {os === 'unknown' && (
                <YAxis
                  tickFormatter={(value) => `${formatStringToNumber(value)}`}
                  axisLine={false}
                  tick={{ fill: '#f8f8f8', fontSize: '12px' }}
                />
                )}

                <Tooltip />
                { token !== 'AVAX'
              && (
              <Bar
                dataKey="total_earn_token"
                stackId="a"
                fill="#2DBC60"
                barSize={24}
              />
              )}

                <Bar
                  dataKey="total_spend_token"
                  stackId="b"
                  fill="#f4b400"
                  barSize={24}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Text fontSize="20px" textStyle="italic" color="whiteAlpha.600">
              Don&apos;t have transactions
            </Text>
          )}

      </Box>

      <Flex alignItems="center" justifyContent="center">
        <Flex mr="40px">
          <Box w="14px" h="14px" border="1" borderColor="#707070" backgroundColor="#2DBC60" mt="3px" mr="8px" />
          <Box flex={1}>
            <Text fontSize="14px" mb="4px">Earn</Text>
          </Box>
        </Flex>

        <Flex>
          <Box w="14px" h="14px" border="1" borderColor="#707070" backgroundColor="#f4b400" mt="3px" mr="8px" />
          <Box flex={1}>
            <Text fontSize="14px" mb="4px">Spend</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

export default TransactionsByDay;

import Table from "src/components/Table"; import { Suspense, useMemo, useState } from "react";
import { CellValue, Column } from "react-table";
import { getDailyUserWithdrawRequest, useHandleWithdraw, useWithdrawApproval } from "src/api/transaction";
import {
  HStack, Icon, IconButton, Modal, Tooltip, useDisclosure, useToast,
} from "@chakra-ui/react";

import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from "react-icons/ai";
import ConfirmDialog from "src/components/ConfirmDialog";
import WithdrawStatisModal from "./WithdrawStaticModal";

function WithdrawTable() {
  const { data } = useWithdrawApproval();
  const {
    isOpen: isOpenWithdrawCf,
    onOpen: onOpenWithdrawCf, onClose: onCloseWithdrawCf,
  } = useDisclosure();
  const {
    isOpen: isOpenWithdrawRj,
    onOpen: onOpenWithdrawRj, onClose: onCloseWithdrawRj,
  } = useDisclosure();
  const {
    isOpen: isOpenStatis,
    onOpen: onOpenStatis,
    onClose: onCloseStatis,
  } = useDisclosure();
  const [approveRecord, setApproveRecord] = useState<any>();
  const [rejectRecord, setRejectRecord] = useState<any>();
  const [dailyStatis, setDailyStatis] = useState<any>();
  const handleWithdrawMutation = useHandleWithdraw();
  const toast = useToast();
  const handleApprove = async () => {
    if (!approveRecord) {
      return;
    }
    try {
      await handleWithdrawMutation.mutateAsync({ id: approveRecord?.id, action: 'approved' });
    } catch (e) {
      toast({ status: 'error', description: "Server internal error. Please try again later!" });
    } finally {
      onCloseWithdrawCf();
    }
    setApproveRecord(null);
  };
  const handleReject = async () => {
    if (!rejectRecord) {
      return;
    }
    try {
      await handleWithdrawMutation.mutateAsync({ id: rejectRecord?.id, action: 'rejected' });
    } catch (e) {
      toast({ status: 'error', description: "Server internal error. Please try again later!" });
    } finally {
      onCloseWithdrawRj();
    }
    await handleWithdrawMutation.mutateAsync({ id: rejectRecord?.id, action: 'rejected' });
    setRejectRecord(null);
  };

  const checkDailyRequest = async (userId: number, token: string) => {
    const statis = await getDailyUserWithdrawRequest(userId, token);
    setDailyStatis({ ...statis, userId, token });
    onOpenStatis();
  };

  const columns = useMemo<Column[]>(() => [
    { Header: "Request Id", accessor: 'id' },
    { Header: "Amount", accessor: 'amount' },
    { Header: 'token', accessor: 'token_symbol' },
    { Header: 'UserId', accessor: 'user_id' },
    { Header: 'Request At', accessor: 'created_at' },
    {
      Header: 'Transactions',
      Cell: ({ value, row }: CellValue) => (
        <HStack>
          <Tooltip label="Check Transactions">
            <IconButton
              icon={<Icon boxSize={4} as={AiFillClockCircle} />}
              variant="ghost"
              colorScheme="blue"
              aria-label="edit"
              onClick={() => {
                checkDailyRequest(row.original.user_id, row.original.token_symbol);
              }}
            />
          </Tooltip>
        </HStack>
      ),
    },
    {
      Header: 'Action',
      Cell: ({ value, row }: CellValue) => (
        <HStack>
          <Tooltip label="Approve">
            <IconButton
              icon={<Icon boxSize={4} as={AiFillCheckCircle} />}
              variant="ghost"
              colorScheme="green"
              aria-label="edit"
              onClick={() => {
                setApproveRecord(row.original);
                onOpenWithdrawCf();
              }}
            />
          </Tooltip>
          <Tooltip label="Reject">
            <IconButton
              icon={<Icon boxSize={4} as={AiFillCloseCircle} />}
              variant="ghost"
              colorScheme="red"
              aria-label="edit"
              onClick={() => {
                setRejectRecord(row.original);
                onOpenWithdrawRj();
              }}
            />
          </Tooltip>
        </HStack>
      ),
    },
  ], []);
  return (
    <>
      <ConfirmDialog
        title="Withdraw Approve"
        description={`Do you want to approve the withdraw ${approveRecord?.id} with amount: ${approveRecord?.amount} 
        ${approveRecord?.token_symbol}`}
        isOpen={isOpenWithdrawCf}
        onClose={onCloseWithdrawCf}
        handleOk={handleApprove}
      />
      <ConfirmDialog
        title="Withdraw Reject"
        description={`Do you want to reject this withdraw ${rejectRecord?.id} with amount: ${rejectRecord?.amount} 
        ${rejectRecord?.token_symbol}`}
        isOpen={isOpenWithdrawRj}
        onClose={onCloseWithdrawRj}
        handleOk={handleReject}
      />
      <WithdrawStatisModal isOpen={isOpenStatis} onClose={onCloseStatis} data={dailyStatis} />
      <Table columns={columns} data={data || []} />
    </>
  );
}

function Wrapper() {
  return (
    <Suspense fallback="loading...">
      <WithdrawTable />
    </Suspense>
  );
}

export default Wrapper;

import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  GridItem,
  Icon,
  IconButton,
  HStack,
  Heading,
  Spacer,
  Image,
} from "@chakra-ui/react";
import { Outlet, Navigate } from "react-router-dom";
import { HiOutlineMenu } from "react-icons/hi";
import NavUserInfo from "src/components/NavUserInfo";
import SideBar from "src/components/SideBar";
import { isLoggedIn } from "src/utils/utils";
import logo from 'src/assets/images/icon_logo.png';

function DefaultLayout() {
  const [isOpen, setIsOpen] = useState(true);

  const onToggle = () => {
    setIsOpen(!isOpen);
    localStorage.setItem("isOpenNav", JSON.stringify(!isOpen));
  };

  useEffect(() => {
    const initIsOpen = JSON.parse(localStorage.getItem("isOpenNav") || "false");
    setIsOpen(initIsOpen);
  }, []);

  const loggedIn = React.useMemo(() => isLoggedIn(), []);

  return (
    loggedIn
      ? (
        <Grid
          bg="gray.800"
          overflowY="auto"
          minH="100vh"
          gridTemplateColumns="min-content 1fr"
        >
          <GridItem
            width={isOpen ? "200px" : "66px"}
            borderRight="1px"
            borderColor="gray.700"
            overflow="auto"
            minH="100vh"
            h="100vh"
            position="sticky"
            top="0px"
          >
            <HStack position="sticky" top={0} px={4} h={16} spacing={1}>
              <Image src={logo} w="30px" />
              <Heading size="md">{isOpen ? "Travel token" : ""}</Heading>
            </HStack>
            <SideBar isOpen={isOpen} />
          </GridItem>
          <Grid
            overflowY="auto"
            h="100vh"
            minH="100vh"
            gridTemplateRows="48px 1fr"
          >
            <HStack px={4} borderBottom="1px" borderColor="gray.700">
              <IconButton
                variant="ghost"
                aria-label="Menu"
                icon={<Icon fontSize="xl" as={HiOutlineMenu} />}
                onClick={onToggle}
                colorScheme="gray"
              />
              <Spacer />
              <NavUserInfo />
            </HStack>
            <GridItem overflowY="auto" bg="gray.900">
              <Outlet />
            </GridItem>
          </Grid>
        </Grid>
      ) : <Navigate to="/login" />
  );
}

export default DefaultLayout;

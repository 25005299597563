import React, { Suspense } from "react";
import { CellValue } from "react-table";
import {
  Text, Flex,
  Box,
  Link,
  Badge,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from "dayjs";
// import { FiEdit } from 'react-icons/fi';
import { PaginateDocument } from "src/types/PaginateDocument";
import { TransactionDocument } from "src/api/transaction";
import { formatStringToNumber } from "src/utils/utils";
import { renderTransferStateColor } from "src/utils/color";
import { TRAN_TYPE_OBJ, TRAN_FLOW } from "src/constants/transaction";
import { DATE_TIME_FORMAT } from "src/constants/date";
import Table from "../../components/Table";

interface TransactionTableData {
  data: PaginateDocument<TransactionDocument> | null | undefined,
  userId?: string
}

function TransactionTable({ data, userId }: TransactionTableData) {
  const columns = React.useMemo(
    () => (userId ? ([
      {
        Header: "Created at",
        accessor: "created_at",
        Cell: ({ value }: CellValue) => <Text>{dayjs(value).format(DATE_TIME_FORMAT)}</Text>,
      },
      {
        Header: "Category",
        accessor: "trans_type",
        Cell: ({ value }: CellValue) => <Text>{TRAN_TYPE_OBJ[value] || value}</Text>,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value, row }: CellValue) => (
          <Flex alignItems="center">
            <Text color={row.original.trans_flow === TRAN_FLOW.add ? "green" : "red.300"}>
              {row.original.trans_flow === TRAN_FLOW.add ? '+' : '-'}
              { formatStringToNumber(value) }
            </Text>

          </Flex>

        ),
      },
      {
        Header: "Token",
        accessor: "token",
        Cell: ({ value }: CellValue) => (
          <Text>
            {value}
          </Text>

        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value }: CellValue) => (
          <Text>
            {value}
          </Text>

        ),
      },

      {
        Header: "Status",
        accessor: "trans_state",
        Cell: ({ value }: CellValue) => (
          <Box maxW="150px">
            <Badge
              variant="outline"
              colorScheme={renderTransferStateColor(value)}
              px="2"
              py="1"
            >
              {value}
            </Badge>

          </Box>
        ),
      },

    ]) : (
      [
        {
          Header: "Created at",
          accessor: "created_at",
          Cell: ({ value }: CellValue) => <Text>{dayjs(value).format(DATE_TIME_FORMAT)}</Text>,
        },
        {
          Header: "Category",
          accessor: "trans_type",
          Cell: ({ value }: CellValue) => <Text>{TRAN_TYPE_OBJ[value] || value}</Text>,
        },
        {
          Header: "Full name",
          accessor: "full_name",
          Cell: ({ value, row }: CellValue) => (
            <Box>
              <Link
                as={RouterLink}
                to={`/user/${row.original.user_id}`}
                fontWeight="semibold"
                color="blue.300"
              >
                <Text mb="4px">
                  {value}
                </Text>
              </Link>

            </Box>

          ),
        },
        {
          Header: "Email",
          accessor: "email",
          Cell: ({ value, row }: CellValue) => (
            <Box>
              <Link
                as={RouterLink}
                to={`/user/${row.original.user_id}`}
                fontWeight="semibold"
                color="blue.300"
              >
                {value}
              </Link>

            </Box>

          ),
        },
        {
          Header: "Amount",
          accessor: "amount",
          Cell: ({ value, row }: CellValue) => (
            <Flex alignItems="center">
              <Text color={row.original.trans_flow === TRAN_FLOW.add ? "green" : "red.300"}>
                {row.original.trans_flow === TRAN_FLOW.add ? '+' : '-'}
                { formatStringToNumber(value) }
              </Text>

            </Flex>

          ),
        },
        {
          Header: "Token",
          accessor: "token",
          Cell: ({ value }: CellValue) => (
            <Text>
              {value}
            </Text>

          ),
        },
        {
          Header: "Description",
          accessor: "description",
          Cell: ({ value }: CellValue) => (
            <Text>
              {value}
            </Text>

          ),
        },

        {
          Header: "Status",
          accessor: "trans_state",
          Cell: ({ value }: CellValue) => (
            <Box maxW="150px">
              <Badge
                variant="outline"
                colorScheme={renderTransferStateColor(value)}
                px="2"
                py="1"
              >
                {value}
              </Badge>

            </Box>
          ),
        },

      ]
    )),
    [userId],
  );
  return (
    <Table columns={columns} data={data?.items || []} />

  );
}

function ReviewTableWrapper({ data, userId }: TransactionTableData) {
  return (
    <Suspense fallback="loading...">
      <TransactionTable data={data} userId={userId} />
    </Suspense>
  );
}

export default ReviewTableWrapper;

import { useSearchParams } from "react-router-dom";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Suspense } from "react";
import { Option } from "src/types/Options";

interface FilterProps {
  title: string,
  options: Option[],
  name: string,
  optionsObj: any
}

function Filter({
  title, options, name, optionsObj,
}: FilterProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentType = searchParams.get(name);

  return (
    <Popover onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={currentType ? "blue.500" : undefined}
          variant="ghost"
          // textTransform="capitalize"
          onClick={onOpen}
        >
          {optionsObj[currentType as string] || currentType || title}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>
          <Box maxH="56" overflowY="auto">
            {options.map((item) => (
              <HStack
                onClick={() => {
                  searchParams.set(name, item.value);
                  setSearchParams(searchParams);
                  onClose();
                }}
                color={item.value === currentType ? "blue.500" : undefined}
                cursor="pointer"
                _hover={{ bg: "gray.600" }}
                borderRadius="md"
                key={item.value}
                p={2}
              >
                <Box
                  flexShrink={0}
                  w={2.5}
                  h={2.5}
                  bg="blue.500"
                  borderRadius="full"
                />
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  textTransform="capitalize"
                >
                  {item.label}
                </Text>
              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                searchParams.delete(name);
                setSearchParams(searchParams);
                onClose();
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterByReviewType({
  title, options, name, optionsObj,
}: FilterProps) {
  return (
    <Suspense
      fallback={(
        <Button variant="ghost" isLoading>
          {title}
        </Button>
      )}
    >
      <Filter options={options} title={title} name={name} optionsObj={optionsObj} />
    </Suspense>
  );
}

export default FilterByReviewType;

import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import { useToast } from "@chakra-ui/react";
import { DEFAULT_PAGE_SIZE } from "../constants/pagination";
import { UserDetail } from "./user";

export interface SpotTypeDto {
  type_code: string;
  bonus_token: number;
  check_in_token: number;
  name: string;
  check_in_earn: boolean;
}
export interface CreateSpotDto {
  name: string;
  lat: string;
  long: string;
  city: string;
  country: string;
  description: string;
  bonus_token: number;
}
export interface GetSpotDto extends Partial<CreateSpotDto> {
  isBookingHotel?: boolean;
}

export interface UpdateSpotDto extends Partial<CreateSpotDto> {
  status?: string;
  balance?: number;
  is_deleted?: number;

}

export interface UpdateSpotPolygonDto {
  polygon: {
    lat: number;
    long: number;
  }[];
}

export interface SpotDocument extends TimeStamp, ObjectId {
  name: string;
  point: string;
  city?: string,
  country?: string
}

export enum SpotStatus {
  Waiting = "waiting",
  Approved = "approved",
  Rejected = "rejected",
}

export enum SpotAction {
  Archive = "archive",
  Restore = "restore",
}

export interface SpotDetail {
  id: number;
  distance: number;
  name: string;
  point_type: string;
  point: number;
  status: SpotStatus;
  type: string;
  created_at: number;
  updated_at: number;
  create_by: number;
  deleted_at: number;
  city: string;
  country: string;
  description: string;
  rejectReason: string;
  user: UserDetail;
  latitude: number;
  longitude: number;
  nearBy: SpotDetail[];
  polygon: any;
  bonus_token: number;
  allow_check_in: boolean;
}

export interface City {
  id: string;
  country_code: string;
  city: string;
}

export interface ApproveSpotDto {
  status: string;
  token: number;
}

export const SPOT_STATUS = ["waiting", "approved", "rejected"];

export const SEARCH_TYPE_OPTIONS = [{
  label: 'Spot name',
  value: 'name',
}, {
  label: 'Email',
  value: 'email',
}, {
  label: 'Customer name',
  value: 'customerName',
}];

export const SEARCH_TYPE_PLACEHOLDER = {
  email: 'email',
  customerName: 'customer name',
  name: 'spot name',
};

export const SPOT_TYPES = [
  "airport",
  "amusement_park",
  "aquarium",
  "art_gallery",
  "casino",
  "lodging",
  "movie_theater",
  "museum",
  "point_of_interest",
  "park",
  "restaurant",
  "sightseeing",
  "shopping_mall",
  "store",
  "supermarket",
  "subway_station",
  "tourist_attraction",
  "train_station",
  "zoo",
];

export const SPOT_TYPES_OPTIONS = SPOT_TYPES.map((t) => ({
  value: t,
  label: t,
}));

// api
export const createSpot = async (createSpotDto: CreateSpotDto) => {
  const { data } = await axios.post("admin/spot", createSpotDto);
  return data;
};

export const getSpot = async (id: string) => {
  try {
    const { data } = await axios.get<SpotDetail>(`admin/spot/${id}`);
    return data;
  } catch (e) {
    return null;
  }
};

export const getSpotNearBy = async (id: string) => {
  try {
    const { data } = await axios.get<SpotDetail[]>(`/spot/${id}/nearby`);
    return data;
  } catch (e) {
    return null;
  }
};

export const getSpots = async (params?: QueryOptionParams & GetSpotDto) => {
  try {
    const { data } = await axios.get<PaginateDocument<SpotDocument>>("/spot", {
      params: {
        page: 1, limit: DEFAULT_PAGE_SIZE, isAdmin: true, ...params,
      },
    });
    return data;
  } catch (e) {
    return null;
  }
};

export const updateSpot = async (id: string, updateSpotDto: UpdateSpotDto) => {
  const { data } = await axios.patch(`/spot/${id || ""}`, updateSpotDto);
  return data;
};

export const approveSpot = async (
  id: string,
  approveSpotDto: ApproveSpotDto,
) => {
  const { data } = await axios.patch(`/spot/${id}/status`, approveSpotDto);
  return data;
};

export const deleteSpot = async (id: string) => {
  const { data } = await axios.delete(`/spot/${id}`);
  return data;
};

export const detailSpot = async (id: string) => {
  try {
    const { data } = await axios.get(`/spot/${id}`);
    return data;
  } catch (e) {
    return null;
  }
};

export const deleteSpotImage = async (id: string) => {
  const { data } = await axios.delete(`/spot-images/${id}`);
  return data;
};

export const uploadSpot = async (createSpotDto: CreateSpotDto[]) => {
  const { data } = await axios.post("admin/spot/upload", createSpotDto);
  return data;
};

// hooks
export const spotKeys = generateQueryKey("spot");

export const useSpots = (params?: QueryOptionParams & GetSpotDto) => (
  useQuery(spotKeys.list(params), () => getSpots(params))
);

export const useSpot = (id: string) => useQuery(spotKeys.detail(id), () => getSpot(id));
export const useSpotNearBy = (id: string) => {
  const toast = useToast({ position: "top" });
  return useQuery(spotKeys.detail(`${id}-nearby`), () => getSpotNearBy(id), {
    onError: (e: any) => {
      toast({
        status: "error",
        description: e?.response?.data?.message[0] || "Error!",
      });
    },
  });
};

export const useUpdateSpot = () => {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: string; data: ApproveSpotDto }) => updateSpot(id, data),
    {
      onError: (e: any) => {
        toast({
          status: "error",
          description: e?.response?.data?.message[0] || "Error!",
        });
      },
      onSuccess: () => {
        toast({ status: "success", description: "Update successful!" });
        queryClient.invalidateQueries(spotKeys.all);
      },
    },
  );
};

export const useCreateSpot = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(createSpot, {
    onSuccess: () => {
      queryClient.invalidateQueries(spotKeys.all);
      toast({ status: "success", description: "create successful!" });
    },
    onError: (e: any) => {
      toast({ status: "error", description: e?.response?.data?.message || "Error!" });
    },
  });
};

export const useUploadSpot = () => {
  const toast = useToast({ position: "top" });
  return useMutation(uploadSpot, {
    onSuccess: (e) => {
      if (e.error.length > 0) {
        toast({
          status: "error", description: `Error: ${e.error.length}, Detail: ${JSON.stringify(e.error)}`, isClosable: true, duration: null,
        });
      } else {
        toast({ status: "success", description: "Upload successful!" });
      }
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};

export const useApproveSpot = () => {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: string; data: ApproveSpotDto }) => approveSpot(id, data),
    {
      onError: () => {
        toast({ status: "error", description: "Error!" });
      },
      onSuccess: () => {
        toast({ status: "success", description: "Update successful!" });
        queryClient.invalidateQueries(spotKeys.all);
      },
    },
  );
};

export const getCities = async (params: string) => {
  try {
    const { data } = await axios.get(`/cities/${params}`);
    return data;
  } catch (e) {
    return null;
  }
};
export const useDataCities = (params: string) => useQuery("spot_cities", () => getCities(params));

export const getCountries = async () => {
  const { data } = await axios.get("/countries");
  return data;
};
export const useCountries = () => useQuery("spot_countries", getCountries);

export const getCountryCode = async (countryCode: string) => {
  try {
    const { data } = await axios.get(`/countries/${countryCode}`);
    return data;
  } catch (e) {
    return null;
  }
};
export const useCountryCode = (countryCode: string) => useQuery("countries", () => getCountryCode(countryCode));

export const spotImagesKeys = generateQueryKey("spot-images");

export const getImages = async (id: string) => {
  try {
    const { data } = await axios.get(`/spot-images/${id}`);
    return data;
  } catch (e) {
    return null;
  }
};
export const useImages = (id: string) => useQuery(spotImagesKeys.detail(id), () => getImages(id), {
  staleTime: Infinity,
});

// SPOT TYPE

export const spotTypesKeys = generateQueryKey("spot-types");

export const getSpotTypes = async (params) => {
  try {
    const { data } = await axios.get<PaginateDocument<SpotTypeDto>>(
      "/admin/spot-types",
      {
        params: { page: 1, limit: DEFAULT_PAGE_SIZE, ...params },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useSpotTypes = (params?: QueryOptionParams) => (
  useQuery(spotTypesKeys.list(params), () => getSpotTypes(params))
);

export const updateSpotType = async (updateData: SpotTypeDto) => {
  const { data } = await axios.patch(
    `/admin/spot-types/${updateData.type_code}`,
    updateData,
  );
  return data;
};

export const useUpdateSpotType = () => {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  return useMutation((data: SpotTypeDto) => updateSpotType(data), {
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
    onSuccess: () => {
      toast({ status: "success", description: "Update successful!" });
      queryClient.invalidateQueries(spotTypesKeys.all);
    },
  });
};

export const createSpotType = async (createSpotTypeDto: SpotTypeDto) => {
  const { data } = await axios.post("/admin/spot-types", createSpotTypeDto);
  return data;
};

export const useCreateSpotType = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(createSpotType, {
    onSuccess: () => {
      queryClient.invalidateQueries(spotTypesKeys.all);
      toast({ status: "success", description: "create successful!" });
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};

export const updateSpotPolygon = async (
  id: string,
  updateSpotPolygonDto: UpdateSpotPolygonDto,
) => {
  const { data } = await axios.post(
    `/spot-polygon/${id || ""}`,
    updateSpotPolygonDto,
  );
  return data;
};

export const useUpdateSpotPolygon = () => {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: string; data: UpdateSpotPolygonDto }) => updateSpotPolygon(id, data),
    {
      onError: (e: any) => {
        toast({
          status: "error",
          description: e?.response.data.message[0] || "Error!",
        });
      },
      onSuccess: () => {
        toast({ status: "success", description: "Update polygon successful!" });
        queryClient.invalidateQueries(spotKeys.all);
      },
    },
  );
};

// SPOT TRANSLATE

export interface SpotTranslateDto {
  field_name: string;
  field_value: string;
  spot_id: string;
  language_code: string;
}

interface getSpotTranslateParams {
  spot_id: string,
  field_name: string
}
export const spotTranslateKeys = generateQueryKey("spot-translate");

export const getSpotTranslate = async (params) => {
  console.log("🚀 ~ file: spot.ts:471 ~ getSpotTranslate ~ params:", params);
  try {
    const { data } = await axios.get<SpotTranslateDto[]>(
      "/admin/spot-translate",
      {
        params,
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useSpotTranslate = (params?: getSpotTranslateParams) => (
  useQuery(spotTranslateKeys.list(params), () => getSpotTranslate(params))
);

export const createSpotTranslate = async (createSpotTypeDto: SpotTranslateDto) => {
  const { data } = await axios.post("/admin/spot-translate", createSpotTypeDto);
  return data;
};

export const useCreateTranslate = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(createSpotTranslate, {
    onSuccess: () => {
      queryClient.invalidateQueries(spotTranslateKeys.all);
      toast({ status: "success", description: "create successful!" });
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};

import { Form } from "react-final-form";
import { Suspense } from "react";
import {
  Stack,
} from "@chakra-ui/react";
import { TOKENS } from 'src/constants/transaction';
import LabeledNumberField from "../../components/LabeledNumberField";
import LabeledSelectField from "../../components/LabeledSelectField";

interface AddTokenProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (e: any) => void;
  formId: string;
}

interface IApproveReviewFormError {
  token?: string,
  amount?: string
}

const validate = (values) => {
  const errors: IApproveReviewFormError = {};
  if (!values.token || !values.token.trim()) {
    errors.token = 'Please enter token';
  }

  if (!values.amount) {
    errors.amount = 'Please enter amount';
  } else if (+values.amount < 0) {
    errors.amount = 'Please enter amount > 0';
  }

  return errors;
};

function AddTokensForm({
  onSubmit, formId,
}: AddTokenProps) {
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <LabeledNumberField
              name="amount"
              label="Amount"
              type="number"
            />

            <LabeledSelectField
              name="token"
              label="Token"
              options={TOKENS}
            />

          </Stack>
        </form>
      )}
    />
  );
}

function AddTokensFormWrapper({
  onSubmit, formId,
}: AddTokenProps) {
  return (
    <Suspense fallback="loading...">
      <AddTokensForm
        onSubmit={onSubmit}
        formId={formId}
      />
    </Suspense>
  );
}

export default AddTokensFormWrapper;

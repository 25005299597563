import {
  Box,
  Heading,
  Flex,
  Spacer,
  Button,
  HStack,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { AddIcon } from "@chakra-ui/icons";
import FilterBySearchKey from "../../components/FilterBySearchKey";
import ItemTable from "./ItemTable";
import ItemModalAdd from "./ItemModalAdd";
import ItemPaging from './ItemPaging';
import FilterByPageSize from "../../components/FilterByPageSize";

function Item() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <ItemModalAdd isOpen={isOpen} onClose={onClose} />
      <Flex direction="column" p={6} h="full">
        <HStack mb={4} spacing={4}>
          <Heading size="md" textTransform="uppercase" color="gray.400">
            Item
          </Heading>
          <Spacer />

          <Button onClick={onOpen} leftIcon={<AddIcon />}>
            Add item
          </Button>
        </HStack>
        <SimpleGrid
          templateRows="auto 1fr"
          borderRadius="md"
          bg="gray.800"
          flex={1}
          overflow="auto"
        >
          <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
            <Flex mb={2}>
              <HStack>
                <FilterBySearchKey placeholder="Name" />
              </HStack>
              <Spacer />
              <FilterByPageSize />
              <ItemPaging />
            </Flex>
          </Box>
          <Box overflow="auto" flex={1} px={5}>
            <ItemTable />
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
}

export function SpotWrapper() {
  return (
    <React.Suspense fallback="Loading...">
      <Item />
    </React.Suspense>
  );
}

export default SpotWrapper;

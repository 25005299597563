import React, { useState } from "react";
import { useCountries } from "src/api/spot";
import { CellValue } from "react-table";
import noImage from 'src/assets/images/no-image.png';
import {
  Icon, IconButton, Image, useDisclosure,
} from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import { getCountryCode } from "src/api/country";
import Table from "../../components/Table";
import EditCountryModal from "../../components/Country/EditCountryModal";

function CountriesTable(): React.ReactElement {
  const { data } = useCountries();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [editingData, setEditingData] = useState();
  const editCountry = async (code: string) => {
    const responseData = await getCountryCode(code);
    setEditingData(responseData);
    onOpen();
  };
  const columns = React.useMemo(() => [
    {
      Header: "Country Code",
      accessor: 'country_code',
    },
    {
      Header: "Country Name",
      accessor: 'country_name',
    },
    {
      Header: "Ja name",
      accessor: 'name_ja',
    },
    {
      Header: 'Vi name',
      accessor: "name_vi",
    },
    {
      Header: "Cover",
      accessor: 'img_cover',
      Cell: ({ value }: CellValue) => <Image src={value ?? noImage} width={40} />,
    },
    {
      Header: "Edit",
      Cell: ({ value, row }: CellValue) => (
        <IconButton
          icon={<Icon boxSize={4} as={FiEdit} />}
          variant="ghost"
          colorScheme="blue"
          aria-label="edit"
          onClick={() => { editCountry(row.original.country_code); }}
        />
      ),
    },
  ], []);
  return (
    <>
      <EditCountryModal isOpen={isOpen} onClose={onClose} editingData={editingData} />
      <Table columns={columns} data={data || []} />
    </>
  );
}

function Wrapper() {
  return (
    <React.Suspense>
      <CountriesTable />
    </React.Suspense>
  );
}
export default Wrapper;

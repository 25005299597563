export enum REVIEW_STATUS {
  approved = 'approved',
  rejected = 'rejected',
  waiting = 'waiting',
}

export enum REVIEW_TYPE {
  overview = 'overview',
  feedback = 'feedback',
  photo = 'photo',
}

import React, { Suspense } from "react";
import { CellValue, Column } from "react-table";
import Table from "src/components/Table";
import dayjs from "dayjs";
import {
  Text,
  HStack,
  Link,
} from "@chakra-ui/react";
// import { FiEdit } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";
import useSearchParams from "../../utils/useSearchParams";
import { useHotels } from "../../api/hotel";

function HotelTable() {
  const { params } = useSearchParams();
  const { data } = useHotels(params);
  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, row }: any) => (
          <Link
            as={RouterLink}
            to={`/hotel/${row.original.id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            <HStack>
              <Text>{value}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "Agoda Id",
        accessor: "agoda_id",
        Cell: ({ value }: CellValue) => <Text>{value || ""}</Text>,
      },
      {
        Header: "Country",
        accessor: "country",
        Cell: ({ value }: CellValue) => <Text>{value}</Text>,
      },
      {
        Header: "City",
        accessor: "city",
        Cell: ({ value }: CellValue) => <Text>{value}</Text>,
      },
      // {
      //   Header: "publish",
      //   accessor: "is_published",
      //   Cell: ({ value, row }: CellValue) => (
      //     <Switch
      //       id="isChecked"
      //       isChecked={value}
      //       onChange={() => handlePublish(row.original.id, value)}
      //     />
      //   ),
      // },
      {
        Header: "Create",
        accessor: "created_at",
        Cell: ({ value }: CellValue) => <Text>{dayjs(value).format("l")}</Text>,
      },
    ], // eslint-disable-next-line
    []
  );

  return <Table columns={columns} data={data?.items || []} />;
}

function HotelTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <HotelTable />
    </Suspense>
  );
}

export default HotelTableWrapper;

import React from 'react';
import { Route, Routes } from "react-router-dom";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import {
  Button, VStack, Text, HStack, Container,
} from "@chakra-ui/react";
import { QueryErrorResetBoundary } from "react-query";
import Login from "./containers/login";
import DefaultLayout from "./layouts/DefaultLayout";

import 'react-image-lightbox/style.css';
import './styles.css';

import routes from "./routes";

function ErrorFallback({ resetErrorBoundary, error }: FallbackProps) {
  // const isAuthenticationError = error.message.includes("401") || error.message.includes("403");
  return (
    <>
      {/* <Login
        resetErrorBoundary={resetErrorBoundary}
        isOpen={isAuthenticationError}
      /> */}
      <Container>
        <VStack mt="52">
          <HStack>
            <Text>There was an error!</Text>
            <Button ml="4" onClick={() => resetErrorBoundary()}>
              Try again
            </Button>
          </HStack>
          <Text>{error.message}</Text>
        </VStack>
      </Container>
    </>
  );
}

export function App() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary onReset={reset} fallbackRender={ErrorFallback}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<DefaultLayout />}>
              {routes.map((route) => (
                <Route
                  path={route.path}
                  element={<route.component />}
                  key={route.path}
                />
              ))}
            </Route>
          </Routes>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}

import React, { Suspense, useMemo } from "react";
import {
  Icon, VStack, Button, Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  AccordionIcon,
  Badge,
} from "@chakra-ui/react";
import { HiOutlineHome } from "react-icons/hi";
import {
  MdOutlinePlace, MdFeedback, MdSendToMobile, MdOutlineCategory,
} from "react-icons/md";
import {
  TbSitemap, TbPhoto, TbWorldUpload, TbHeart,

} from "react-icons/tb";
import {
  BiCog, BiUser, BiHomeAlt, BiTrip, BiHotel,
} from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineTransaction } from 'react-icons/ai';
import { RiFlag2Fill, RiFlag2Line, RiHotelFill } from "react-icons/ri";
import { GiReceiveMoney } from 'react-icons/gi';
import { IconType } from "react-icons";
import { useReviewsWaitingNumber } from "src/api/review";
import { BsCurrencyExchange } from "react-icons/bs";

interface ISubMenu {
  pathname: string;
  label: string;
  search?: string,
  icon: IconType,
  badgeNumber?: number
}
interface NavLink {
  pathname: string;
  label: string;
  icon: any;
  search?: string;
  subMenus?: ISubMenu[]
}

interface SideBarProps {
  isOpen: boolean;
}

const isActive = (locationPath: string, sideBarItem: string) => {
  if (sideBarItem === "/") return locationPath === sideBarItem;
  const rootPath = locationPath.split("/")[1] || "";
  return `/${rootPath}` === sideBarItem;
};

const isActiveParent = (locationPath: string, subMenus: ISubMenu[]) => (
  !!subMenus.find((item) => item.pathname === locationPath)
);

function SideBar({ isOpen }: SideBarProps) {
  const location = useLocation();
  const { data: reviewCount } = useReviewsWaitingNumber();

  const links: NavLink[] = useMemo(() => [
    {
      pathname: "/",
      label: "Home Page",
      icon: HiOutlineHome,
    },
    {
      label: 'Transactions',
      icon: AiOutlineTransaction,
      pathname: '/token',
      subMenus: [{
        pathname: "/transaction-earn",
        label: "Earn",
        icon: GiReceiveMoney,
      }, {
        pathname: "/transaction-spend",
        label: "Spend",
        icon: MdSendToMobile,
      }],
    },
    {
      pathname: "/trip",
      label: "Trip",
      icon: BiTrip,
    },
    {
      label: 'Spot',
      icon: MdOutlinePlace,
      pathname: '/spot-group',
      subMenus: [{
        pathname: "/spot",
        label: "Spot",
        icon: MdOutlinePlace,
      }, {
        pathname: "/spot-upload",
        label: "Spot upload",
        icon: TbWorldUpload,
      }, {
        pathname: "/spot-types",
        label: "Spot type",
        icon: MdOutlineCategory,
      }, {
        pathname: "/review-overview",
        label: "Review overview",
        icon: BiHomeAlt,
        badgeNumber: reviewCount?.overview,
      }, {
        pathname: "/review-feedback",
        label: "Review feedback",
        icon: MdFeedback,
        badgeNumber: reviewCount?.feedback,
      }, {
        pathname: "/review-photo",
        label: "Review photo",
        icon: TbPhoto,
        badgeNumber: reviewCount?.photo,
      },
      {
        pathname: "/hotel",
        label: "Hotel",
        icon: TbPhoto,
      }],
    },
    {
      pathname: "/user",
      label: "User",
      icon: BiUser,
    },
    {
      pathname: "/item",
      label: "Item",
      icon: TbSitemap,
    },
    {
      pathname: "/nft/mint-config",
      label: "Mint Config",
      icon: TbHeart,
    },
    {
      label: "Hotel",
      icon: RiHotelFill,
      pathname: '/hotel-group',
      subMenus: [{
        pathname: '/hotel-config',
        label: 'Hotel Config',
        icon: BiCog,
      },
      {
        pathname: '/hotel-countries-config',
        label: 'Hotel Countries',
        icon: RiFlag2Line,
      },
      {
        pathname: "/hotel",
        label: "Hotel",
        icon: BiHotel,
      },
      ],
    },
    {
      label: "Countries",
      icon: RiFlag2Fill,
      pathname: '/countries',
    },
    {
      label: "Withdraw Approval",
      icon: BsCurrencyExchange,
      pathname: '/withdraw-approval',
    },
  ], [reviewCount]);

  return (
    <VStack spacing={0} align="stretch">
      {links.map((item) => (
        <React.Fragment key={item.pathname}>
          {item.subMenus && item.subMenus?.length > 0
            ? (
              <Accordion
                allowToggle
                defaultIndex={isActiveParent(location.pathname, item.subMenus) ? 0 : undefined}
              >
                <AccordionItem border="0">
                  <h2>
                    <AccordionButton padding="0" paddingRight="10px">
                      <Box flex="1" textAlign="left">
                        <Tooltip placement="right" label={item.label}>
                          <Button
                            size="md"
                            leftIcon={<Icon boxSize={6} as={item.icon} />}
                            variant="sidebar"
                            w="full"
                            colorScheme=""
                            isActive={isActive(location.pathname, item.pathname)}
                          >
                            {isOpen ? item.label : ""}
                          </Button>
                        </Tooltip>
                      </Box>
                      <AccordionIcon ml={isOpen ? 0 : '-24px'} />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pl={0} pr={0} py="0">
                    {item.subMenus?.map((subMenu) => (
                      <Link
                        style={{ width: "100%" }}
                        to={{ pathname: subMenu.pathname, search: subMenu.search }}
                        key={subMenu.pathname}

                      >
                        <Tooltip placement="right" label={subMenu.label}>
                          <Button
                            size="md"
                            variant="sidebar"
                            w="full"
                            colorScheme=""
                            isActive={isActive(location.pathname, subMenu.pathname)}
                            leftIcon={!isOpen ? <Icon boxSize={6} as={subMenu.icon} /> : undefined}
                            pl={isOpen ? "50px" : '15px'}
                            pr={isOpen ? "10px" : 0}
                          >
                            {subMenu.badgeNumber && subMenu.badgeNumber > 0
                              && (
                                <Badge
                                  colorScheme="blue"
                                  w="20px"
                                  h="20px"
                                  borderRadius="full"
                                  fontSize="11px"
                                  alignItems="center"
                                  justifyContent="center"
                                  display="flex"
                                  ml={isOpen ? "-28px" : '-10px'}
                                  mr={isOpen ? "8px" : 0}
                                  mt={isOpen ? 0 : '-20px'}
                                >
                                  {subMenu.badgeNumber}
                                </Badge>
                              )}
                            {isOpen ? subMenu.label : ""}

                          </Button>
                        </Tooltip>
                      </Link>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )
            : (
              <Link
                style={{ width: "100%" }}
                to={{ pathname: item.pathname, search: item.search }}
              >
                <Tooltip placement="right" label={item.label}>
                  <Button
                    size="md"
                    leftIcon={<Icon boxSize={6} as={item.icon} />}
                    variant="sidebar"
                    w="full"
                    colorScheme=""
                    isActive={isActive(location.pathname, item.pathname)}
                  >
                    {isOpen ? item.label : ""}
                  </Button>
                </Tooltip>
              </Link>
            )}

        </React.Fragment>

      ))}
    </VStack>
  );
}

function SideBarWrapper(props: SideBarProps) {
  return (
    <Suspense fallback="">
      <SideBar {...props} />
    </Suspense>
  );
}

export default SideBarWrapper;

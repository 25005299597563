/* eslint-disable max-len */
import axios from "src/config/axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { IAppConfig } from "src/interfaces/app-config";
import { UserDetail } from "./user";
import { DEFAULT_PAGE_SIZE } from "../constants/pagination";

export interface GetHotelDto {
  name?: string;
  city?: string;
  country?: string;
}

export interface HotelDocument extends TimeStamp, ObjectId {
  name: string;
  point: string;
  latitude: number;
  longitude: number;
  city: string;
  country: string;
  agoda_id: string;
  booking_id: string;
  is_published: boolean;
}

export interface UpdateHotelDto extends Partial<HotelDocument> {
}

export interface HotelDetail {
  id: number;
  distance: number;
  name: string;
  address: string;
  point_type: string;
  point: number;
  type: string;
  created_at: number;
  updated_at: number;
  create_by: number;
  deleted_at: number;
  city: string;
  country: string;
  description: string;
  rejectReason: string;
  user: UserDetail;
  latitude: number;
  longitude: number;
  nearBy: HotelDetail[];
  polygon: Object[];
  agoda_id: string;
  booking_id: string;
}

export interface IHotelCountryConfig {
  country_code: string;
  img_cover?: string;
}

export const hotelKeys = generateQueryKey("hotel");

export const getHotel = async (id: string) => {
  try {
    const { data } = await axios.get<HotelDetail>(`admin/hotel/${id}`);
    return data;
  } catch (e) {
    return null;
  }
};

export const getHotels = async (params?: QueryOptionParams & GetHotelDto) => {
  try {
    const { data } = await axios.get<PaginateDocument<HotelDocument>>(
      "/admin/hotel",
      {
        params: { page: 1, limit: DEFAULT_PAGE_SIZE, ...params },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const updateHotel = async (id: string, updateHotelDto: UpdateHotelDto) => {
  const { data } = await axios.patch(`/admin/hotel/${id || ""}`, updateHotelDto);
  return data;
};

// hook

export const useHotel = (id: string) => useQuery(hotelKeys.detail(id), () => getHotel(id));

export const useHotels = (params?: QueryOptionParams & GetHotelDto) => useQuery(hotelKeys.list(params), () => getHotels(params));

export const useUpdateHotel = () => {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: string; data: UpdateHotelDto }) => updateHotel(id, data),
    {
      onError: (e: any) => {
        toast({
          status: "error",
          description: e?.response?.data?.message[0] || "Error!",
        });
      },
      onSuccess: () => {
        toast({ status: "success", description: "Update successful!" });
        queryClient.invalidateQueries(hotelKeys.all);
      },
    },
  );
};

export const updateHotelPublished = async (
  id: string,
  updateSpotDto: { isPublished: boolean },
) => {
  const { data } = await axios.patch(
    `/spot-hotel/${id}/is-published`,
    updateSpotDto,
  );
  return data;
};

export const useUpdateHotelPublished = () => {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: string; data: { isPublished: boolean } }) => updateHotelPublished(id, data),
    {
      onError: (e: any) => {
        toast({
          status: "error",
          description: e?.response?.data?.message[0] || "Error!",
        });
      },
      onSuccess: () => {
        toast({ status: "success", description: "Update successful!" });
        queryClient.invalidateQueries(hotelKeys.all);
      },
    },
  );
};

export const hotelConfigKeys = generateQueryKey('hotel-app-config');
export const getHotelConfigs = async () => {
  const { data } = await axios.get<Array<IAppConfig>>('/hotel/app-config');
  return data ?? [];
};

export const useHotelConfigs = () => useQuery(hotelConfigKeys.all, getHotelConfigs);

export const updateHotelConfig = async (body: Array<IAppConfig>) => {
  const { data } = await axios.patch<any>('/admin/hotel/app-config', body);
  return data;
};

export const useUpdateHotelConfig = () => useMutation((body: Array<IAppConfig>) => updateHotelConfig(body));

export const hotelCountryConfigsKey = generateQueryKey('hotel-countries-config');

export const updateHotelCountry = async (body: IHotelCountryConfig) => {
  const { data } = await axios.patch<any>('/admin/hotel/hotel-countries-config', body);
  return data;
};

export const useUpdateHotelCountry = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: IHotelCountryConfig) => updateHotelCountry(body),
    { onSuccess: () => { queryClient.invalidateQueries(hotelCountryConfigsKey.all); } },
  );
};

export const getHotelCountryConfigs = async () => {
  const { data } = await axios.get('/admin/hotel/hotel-countries-config');
  return data;
};

export const useHotelCountryConfigs = () => useQuery(hotelCountryConfigsKey.all, getHotelCountryConfigs);

export const deleteHotelCountry = async (code: string) => {
  const { data } = await axios.delete(`/admin/hotel/hotel-countries-config/${code}`);
  return data;
};

export const useDeleteHotelCountry = () => {
  const queryClient = useQueryClient();
  return useMutation((code: string) => deleteHotelCountry(code), {
    onSuccess: () => {
      queryClient.invalidateQueries(hotelCountryConfigsKey.all);
    },
  });
};

import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import SpotForm from "./SpotForm";
import {
  CreateSpotDto, spotKeys, updateSpot, SpotDocument,
} from "../../api/spot";

const FORM_ID = "add-spot";

interface SpotModalAddProps {
  isOpen: boolean;
  onClose: () => void;
  editingData: SpotDocument;
}

function SpotModalEdit({ isOpen, onClose, editingData }: SpotModalAddProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();
  const onSubmit = async (values: CreateSpotDto) => {
    try {
      await updateSpot(editingData.id, {
        ...values,
      });
      onClose();
      toast({ status: "success", description: "Success update" });
      queryClient.invalidateQueries(spotKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Error" });
    }
  };

  const point = React.useMemo(() => {
    if (editingData) {
      const { point: pointVal } = editingData;
      const location = pointVal.substring(6, pointVal.length - 1).split(" ");
      return {
        lat: location[0],
        long: location[1],
      };
    }
    return {};
  }, [editingData]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit spot</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SpotForm
            initialValues={{ ...editingData, ...point }}
            onSubmit={onSubmit}
            formId={FORM_ID}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SpotModalEdit;

import Home from "./containers/home";
import User from "./containers/user";
import Token from "./containers/token";
import Spot from "./containers/spot";
import Item from "./containers/item";
import Trip from "./containers/Trip";
import SpotDetail from "./containers/spotDetail";
import ItemDetail from "./containers/item/ItemDetail";
import CustomerDetail from "./containers/user/UserDetail";
import SpotTypes from "./containers/spotTypes";
import SpotUpload from "./containers/spotUpload";
import ReviewFeedback from "./containers/ReviewFeedback";
import ReviewOverview from "./containers/ReviewOverview";
import ReviewPhoto from "./containers/ReviewPhoto";
import Hotel from "./containers/hotel";
import HotelDetail from "./containers/hotelDetail";
import TransactionsEarn from "./containers/TransactionsEarn";
import TransactionsSpend from "./containers/TransactionsSpend";
import SupplyTransactions from "./containers/SupplyTransactions";

import MintingConfigList from "./containers/nft/MintConfig";
import HotelConfig from "./containers/hotelConfig";
import Countries from "./containers/countries";
import HotelCountriesConfig from "./containers/hotelConfig/hotelCountriesConfig";
import WithdrawApproval from "./containers/WithdrawTransaction";

export interface RouteConfigItem {
  path: string;
  component: any;
  exact?: boolean;
  routes?: Array<RouteConfigItem>;
}

const routes: RouteConfigItem[] = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/user",
    component: User,
    exact: true,
  },
  {
    path: "/token",
    component: Token,
    exact: true,
  },
  {
    path: "/trip",
    component: Trip,
    exact: true,
  },
  {
    path: "/spot",
    component: Spot,
    exact: true,
  },
  {
    path: "/item",
    component: Item,
    exact: true,
  },
  {
    path: "/spot/:id",
    component: SpotDetail,
    exact: true,
  },
  {
    path: "/item/:id",
    component: ItemDetail,
    exact: true,
  },
  {
    path: "/user/:id",
    component: CustomerDetail,
    exact: true,
  },
  {
    path: "/spot-upload",
    component: SpotUpload,
    exact: true,
  },
  {
    path: "/spot-types",
    component: SpotTypes,
    exact: true,
  },
  {
    path: "/review-feedback",
    component: ReviewFeedback,
    exact: true,
  },
  {
    path: "/review-overview",
    component: ReviewOverview,
    exact: true,
  },
  {
    path: "/review-photo",
    component: ReviewPhoto,
    exact: true,
  },
  {
    path: "/hotel",
    component: Hotel,
    exact: true,
  },
  {
    path: "/hotel/:id",
    component: HotelDetail,
    exact: true,
  },
  {
    path: "/transaction-earn",
    component: TransactionsEarn,
    exact: true,
  },
  {
    path: "/transaction-spend",
    component: TransactionsSpend,
    exact: true,
  },
  {
    path: '/supply-transactions',
    component: SupplyTransactions,
    exact: true,
  },
  {
    path: "/nft/mint-config",
    component: MintingConfigList,
    exact: true,
  },
  {
    path: '/hotel-config',
    component: HotelConfig,
    exact: true,
  },
  {
    path: '/hotel-countries-config',
    component: HotelCountriesConfig,
    exact: true,
  },
  {
    path: '/countries',
    component: Countries,
    exact: true,
  },
  {
    path: '/withdraw-approval',
    component: WithdrawApproval,
    exact: true,
  },

];

export default routes;

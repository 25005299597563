import React from "react";
import Transactions from "../Transactions";

function TransactionsEarn() {
  return (
    <Transactions type="earn" />
  );
}

export default TransactionsEarn;

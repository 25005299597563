import { Field, Form } from "react-final-form";
import { Suspense } from "react";
import {
  Stack,
} from "@chakra-ui/react";
import { CreateReviewDto, ReviewDocument } from "src/api/review";
import { getSpots } from "src/api/spot";
import { REVIEW_TYPE } from "src/constants/review";
import LabeledNumberField from "./LabeledNumberField";
import LabelTextareaField from "./LabelTextareaField";
import LabeledSelectAsyncField from "./LabeledSelectAsyncField";
import DropzoneUploader from "./DropzoneUploaderField/DropzoneUploader";

interface ApproveReviewProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: CreateReviewDto | ReviewDocument;
  reviewType?: string
}

interface CreateReviewError {
  spot?: string,
  rating_number?: string,
  images?: string
}

const validate = (values) => {
  const errors: CreateReviewError = {};

  if (!values.spot) {
    errors.spot = 'Please select spot';
  }

  if (values.review_type === REVIEW_TYPE.feedback) {
    if (!values.rating_number) {
      errors.rating_number = 'Please enter rating number';
    } else if (+values.rating_number > 5 || +values.rating_number < 1
      || +values.rating_number % 1 !== 0) {
      errors.rating_number = 'Please enter rating integer number from 1 to 5';
    }
  } else if (values.review_type === REVIEW_TYPE.photo) {
    if (!values.images || values.images.length === 0) {
      errors.images = 'Please select at least an image';
    }
  }

  return errors;
};

function CreateReviewForm({
  onSubmit, formId, initialValues, reviewType,
}: ApproveReviewProps) {
  const loadOptions = async (search, prevOptions) => {
    const data = await getSpots({
      page: (prevOptions?.length as number) / 50 + 1,
      limit: 50,
      search,
    });
    return {
      options: data?.items?.map((item) => ({
        label: `${item.name} - ${item.city} - ${item.country}`,
        value: item.id,
      })),
      hasMore: data?.meta ? data?.meta?.currentPage < data?.meta?.totalPages : false,
    };
  };

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>

          <Stack spacing={4}>
            <LabeledSelectAsyncField
              name="spot"
              label="Spot"
              required
              loadOptions={loadOptions}
            />
            {reviewType === REVIEW_TYPE.feedback
              && (
                <LabeledNumberField
                  required
                  type="number"
                  min={1}
                  max={5}
                  step={0.5}
                  name="rating_number"
                  label="Rating number"
                />
              )}
            {reviewType !== REVIEW_TYPE.photo
              && (
                <>
                  <LabelTextareaField
                    name="review_content"
                    label="Review content"
                    required
                    h="100px"
                  />
                  <LabelTextareaField
                    name="content_jp"
                    label="JP"
                    h="100px"
                  />
                  <LabelTextareaField
                    name="content_en"
                    label="EN"
                    h="100px"
                  />
                  <LabelTextareaField
                    name="content_vi"
                    label="VI"
                    h="100px"
                  />
                </>
              )}

            {reviewType === REVIEW_TYPE.photo
              && (
                <Field
                  name="images"
                  label="Photos *"
                  component={DropzoneUploader}
                />
              )}

          </Stack>
        </form>
      )}
    />
  );
}

function CreateReviewFormWrapper({
  onSubmit, formId, initialValues,
  reviewType,
}: ApproveReviewProps) {
  return (
    <Suspense fallback="loading...">
      <CreateReviewForm
        onSubmit={onSubmit}
        formId={formId}
        initialValues={initialValues}
        reviewType={reviewType}
      />
    </Suspense>
  );
}

export default CreateReviewFormWrapper;

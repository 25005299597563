import React, { forwardRef, PropsWithoutRef } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  FormHelperText,
  Text,
  InputRightElement,
} from "@chakra-ui/react";
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.pl';
import { useField } from "react-final-form";

export interface LabeledTextFieldProps
  extends PropsWithoutRef<JSX.IntrinsicElements["input"]> {
  /** Field name. */
  name: string;
  /** Field label. */
  label: string;
  /** Field label. */
  required?: boolean;
  autoComplete?: string;
  /** Field type. Doesn't include radio buttons and checkboxes */
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  helperText?: string;
  unit?: any
}

export const LabeledTextField = forwardRef<
  HTMLInputElement,
  LabeledTextFieldProps
>(
  (
    {
      name,
      label,
      outerProps,
      placeholder,
      helperText,
      disabled,
      required,
      unit,
    },
    ref,
  ) => {
    const {
      input,
      meta: {
        touched, error, submitError, submitting,
      },
    } = useField(name, {
      parse: (value) => value,
    });
    const normalizedError = Array.isArray(error)
      ? error.join(", ")
      : error || submitError;

    return (
      <FormControl isInvalid={touched && normalizedError} {...outerProps}>
        <FormLabel fontSize="sm" mb={helperText ? "0" : '2'} display="flex" alignItems="center">
          {label}
          {' '}
          {required
            && <Text>*</Text>}

        </FormLabel>
        {helperText && <FormHelperText mb="3">{helperText}</FormHelperText>}
        <InputGroup>
          <Cleave
            fontWeight="medium"
            {...input}
            disabled={submitting || disabled}
            // required={required}
            ref={ref}
            placeholder={placeholder}
            className={`input ${touched && normalizedError && 'inputError'}`}
            onChange={(e) => {
              input.onChange(e.target.rawValue);
            }}
            options={{
              numeral: true,
              numeralThousandsGroupStyle: 'thousand',
            }}
          />
          {unit && (
            <InputRightElement top="50%" transform="translateY(-50%)" right="10px">
              {unit}
            </InputRightElement>
          )}
        </InputGroup>

        {touched && normalizedError && (
          <FormErrorMessage>{normalizedError}</FormErrorMessage>
        )}
      </FormControl>
    );
  },
);

export default LabeledTextField;

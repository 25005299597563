import { useQuery, useMutation } from "react-query";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { Partial } from "src/types/Partial";
import generateQueryKey from "src/utils/queryKeyFactory";
import { Status } from "src/types/Status";
import { IMintConfig, IMintConfigDto } from "src/interfaces/mint-config";
import { DEFAULT_PAGE_SIZE } from "../constants/pagination";
import axios from "../config/axios";

export interface CreateItemDto {
  name: string,
  icon: string,
  price: number,
  efficiency: number,
  distance: number,
  luck: number,
  item_category_id: number,
}
export interface GetItemDto extends Partial<CreateItemDto> {
  balance?: number;
}

export interface UpdateItemDto extends Partial<CreateItemDto> {
  status?: Status;
  balance?: number;
}
export interface ItemDocument extends TimeStamp, ObjectId {
  name: string;
}

export interface ItemCategory {
  created_at: string,
  icon: string,
  id: number,
  name: string,
  updated_at: string,
}
export interface ItemDetail {
  code: string,
  created_at: string,
  distance: number,
  durability: number,
  efficiency: number,
  icon: string,
  id: number,
  item_category: ItemCategory,
  item_category_id: number,
  luck: number,
  name: string,
  price: number,
  updated_at: string,
}
// api
export const createItem = async (createItemDto: CreateItemDto) => {
  const data = await axios.post('/item', createItemDto);
  return data;
};

export const getItem = async (id: string) => {
  try {
    const { data } = await axios.get<ItemDetail>(`/item/${id}`);
    return data;
  } catch (e) {
    return null;
  }
};

export const getItems = async (
  params?: QueryOptionParams & GetItemDto,
) => {
  try {
    const { data } = await axios.get<PaginateDocument<ItemDocument>>(
      "/item",
      {
        params: { page: 1, limit: DEFAULT_PAGE_SIZE, ...params },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const updateItem = async (
  id: string,
  updateItemDto: UpdateItemDto,
) => {
  const { data } = await axios.patch(`/item/${id || ""}`, updateItemDto);
  return data;
};

export const deleteItem = async (id: string) => {
  const { data } = await axios.delete(`/item/${id}`);
  return data;
};
// hooks
export const itemKeys = generateQueryKey("item");

export const useItems = (params?: QueryOptionParams & GetItemDto) => (
  useQuery(itemKeys.list(params), () => getItems(params))
);

export const useItem = (id: string) => (
  useQuery(itemKeys.detail(id), () => getItem(id), {
    staleTime: Infinity,
  })
);

export const useUpdateItem = () => (
  useMutation(({ id, data }: { id: string; data: UpdateItemDto }) => updateItem(id, data))
);

export const getItemCategory = async () => {
  try {
    const { data } = await axios.get('/item-category');
    return data;
  } catch (e) {
    return null;
  }
};

//
export const useItemCategory = () => useQuery('item-category', getItemCategory);

export const mintConfigKeys = generateQueryKey("mint-config");

export const getMintConfigs = async () => {
  const { data } = await axios.get<Array<IMintConfig>>(`/v2/admin/mint-config`);
  return data;
};

export const useMintConfigs = () => useQuery(mintConfigKeys.all, getMintConfigs);

export const getMintConfig = async (configId: number) => {
  const { data } = await axios.get<IMintConfig>(`/v2/admin/mint-config/${configId}`);
  return data;
};

// eslint-disable-next-line max-len
export const useMintConfig = (id: number) => useQuery(mintConfigKeys.detail(id), () => getMintConfig(id));
export const updateMintConfig = async (id: number, body: IMintConfigDto) => {
  const { data } = await axios.patch<any>(`/v2/admin/mint-config/${id}`, { ...body, posibility: JSON.stringify(body.posibility) });
  return data;
};

// eslint-disable-next-line max-len
export const useUpdateMintConfig = () => useMutation(({ id, body }: {id: number; body: IMintConfigDto}) => updateMintConfig(id, body));

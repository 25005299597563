import { Form } from "react-final-form";
import { Suspense } from "react";
import {
  Flex, Stack, Text,
} from "@chakra-ui/react";
import { ReviewDocument } from "src/api/review";
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from "src/constants/date";
import LabeledNumberField from "./LabeledNumberField";
import LabelTextareaField from "./LabelTextareaField";

interface ApproveReviewProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: ReviewDocument;
  selectedReview?: ReviewDocument
}

interface IApproveReviewFormError {
  review_content?: string,
  token_number?: string
}

const validate = (values) => {
  const errors: IApproveReviewFormError = {};
  // if (!values.review_content || !values.review_content.trim()) {
  //   errors.review_content = 'Please enter review content';
  // }

  if (!values.token_number) {
    errors.token_number = 'Please enter token number';
  } else if (+values.token_number < 0) {
    errors.token_number = 'Please enter token number >= 0';
  }

  return errors;
};

function ApproveReviewForm({
  onSubmit, formId, initialValues, selectedReview,
}: ApproveReviewProps) {
  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Flex mb="10px">
            <Text size="sm" color="gray.500" mr="8px">
              Created at:
            </Text>
            <Text size="md">
              {dayjs(selectedReview?.created_at).format(DATE_TIME_FORMAT)}
            </Text>
          </Flex>
          <Flex justifyContent="space-between" mb="10px">
            <Flex>
              <Text size="sm" color="gray.500" mr="8px">
                User:
              </Text>
              <Text size="md">
                {selectedReview?.full_name}
                {' '}
                (
                {selectedReview?.email}
                )
              </Text>
            </Flex>
            <Flex>
              <Text size="sm" color="gray.500" mr="8px">
                Spot:
              </Text>
              <Text size="sm">
                {selectedReview?.spot_name}
              </Text>
            </Flex>
            <Flex>
              <Text size="sm" color="gray.500" mr="8px">
                Spot type:
              </Text>
              <Text size="sm">
                {selectedReview?.spot_type}
              </Text>
            </Flex>
            <Flex>
              <Text size="sm" color="gray.500" mr="8px">
                Country:
              </Text>
              <Text size="sm">
                {selectedReview?.country}
              </Text>
            </Flex>
          </Flex>
          <Stack spacing={4}>
            <LabelTextareaField
              name="review_content"
              label="Original review"
              required
              h="100px"
              disabled
            />
            <LabelTextareaField
              name="content_jp"
              label="JP"
              h="100px"
            />
            <LabelTextareaField
              name="content_en"
              label="EN"
              h="100px"
            />
            <LabelTextareaField
              name="content_vi"
              label="VI"
              h="100px"
            />
            <LabeledNumberField
              name="token_number"
              helperText="Giving token to the user"
              label="Token"
              unit="XTR"
            />
          </Stack>
        </form>
      )}
    />
  );
}

function ApproveReviewFormWrapper({
  onSubmit, formId, initialValues,
  selectedReview,
}: ApproveReviewProps) {
  return (
    <Suspense fallback="loading...">
      <ApproveReviewForm
        onSubmit={onSubmit}
        formId={formId}
        initialValues={initialValues}
        selectedReview={selectedReview}
      />
    </Suspense>
  );
}

export default ApproveReviewFormWrapper;

import { FormLabel, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import LabeledTextField from '../../../components/LabeledTextField';
import { IMintConfig } from '../../../interfaces/mint-config';

interface IMintConfigFormProps {
  onSubmit: (e: any) => void;
  formId?: string;
  initialValues?: IMintConfig;
}

function MintConfigForm({ onSubmit, initialValues, formId }: IMintConfigFormProps) {
  const { posibility = [] } = initialValues ?? {};
  return (
    <Form
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form: { mutators: { push, pop } } }) => (
        <form onSubmit={handleSubmit} id={formId}>
          <Stack spacing={4}>
            <LabeledTextField name="first_item_category_name" label="Parent 1" disabled />
            <LabeledTextField name="second_item_category_name" label="Parent 2" disabled />
            <Text>Possibility</Text>
            <FieldArray name="posibility">
              {({ fields }) => fields.map((name, index) => (
                <>
                  <FormLabel fontSize="sm" mb="1.5">{`${posibility[index].failed ? 'Failed' : posibility[index].minted_category_name.toUpperCase()} (%)`}</FormLabel>
                  <Field name={`${name}.percentage`} component="input" type="number" className="chakra-input" min={0} max={100} />
                </>
              ))}
            </FieldArray>
          </Stack>
        </form>
      )}
    />
  );
}
export default MintConfigForm;

import * as React from 'react';
import {
  Box, Button, Flex, HStack, Heading, Spacer, useToast,
} from "@chakra-ui/react";
import BoxImageConfig from 'src/components/Image/BoxImageConfig';
import { useHotelConfigs, useUpdateHotelConfig } from 'src/api/hotel';
import { HOTEL_CONFIGS } from 'src/config/hotel';
import { IAppConfig } from 'src/interfaces/app-config';
import { RiSave2Fill } from 'react-icons/ri';

function HotelConfig() {
  const { data } = useHotelConfigs();
  const [configs, setConfigs] = React.useState([...(data ?? [])]);
  const [formDirty, setFormDirty] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const updateHotelConfigMutation = useUpdateHotelConfig();
  const toast = useToast({ position: 'top' });
  const getConfigObj = (name) => {
    const cf = configs.find((v) => v.name === name);

    return cf ?? ({ name, value: '' } as IAppConfig);
  };

  const onImageConfigChanged = (name: string, value: string) => {
    const tmp = [...configs];
    const configTmp = tmp.find((v) => v.name === name) || { name, value } as IAppConfig;
    configTmp.value = value;
    if (!configTmp.id) {
      tmp.push(configTmp);
    }
    setConfigs([...tmp]);
    setFormDirty(true);
  };
  const overviewCover = getConfigObj(HOTEL_CONFIGS.hotel_overview_cover);
  const searchCover = getConfigObj(HOTEL_CONFIGS.hotel_search_cover);
  const defaultPhoto = getConfigObj(HOTEL_CONFIGS.hotel_default_photo);
  const onSaveConfig = async () => {
    setIsSaving(true);
    try {
      await updateHotelConfigMutation.mutateAsync(configs);
      toast({ status: "success", description: "Config has been saved" });
      setFormDirty(false);
    } catch (e) {
      toast({ status: "success", description: "Have error when saving config. Please try again later!" });
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <Box p="24px">
      <HStack mb="20px">
        <Heading size="md" textTransform="uppercase" color="gray.400" mb="24px">
          Hotel Config
        </Heading>
        <Spacer />
        <Button
          onClick={onSaveConfig}
          leftIcon={<RiSave2Fill />}
          disabled={!formDirty && !isSaving}
        >
          Save
        </Button>
      </HStack>

      <Flex mb="24px">
        <BoxImageConfig
          key={HOTEL_CONFIGS.hotel_overview_cover}
          id={HOTEL_CONFIGS.hotel_overview_cover}
          title="Hotel Overview Cover"
          imageSrc={overviewCover.value}
          onImageChanged={(url: string) => {
            onImageConfigChanged(HOTEL_CONFIGS.hotel_overview_cover, url);
          }}
        />
        <BoxImageConfig
          id={HOTEL_CONFIGS.hotel_search_cover}
          title="Hotel Search Cover"
          imageSrc={searchCover.value}
          onImageChanged={(url: string) => {
            onImageConfigChanged(HOTEL_CONFIGS.hotel_search_cover, url);
          }}
        />
        <BoxImageConfig
          id={HOTEL_CONFIGS.hotel_default_photo}
          title="Hotel Default Photo"
          subTitle="Image size: 400x400"
          imageSrc={defaultPhoto.value}
          onImageChanged={(url: string) => {
            onImageConfigChanged(HOTEL_CONFIGS.hotel_default_photo, url);
          }}
        />
      </Flex>
    </Box>
  );
}

function HotelConfigWrapper() {
  return (
    <React.Suspense fallback="loading">
      <HotelConfig />
    </React.Suspense>
  );
}

export default HotelConfigWrapper;

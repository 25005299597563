import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button } from "@chakra-ui/react";
import SpotTypeForm from "./SpotTypeForm";
import { useCreateSpotType, SpotTypeDto } from "../../api/spot";

const FORM_ID = "add-spot";

interface SpotTypeModalAddProps {
  isOpen: boolean;
  onClose: () => void;
}

function SpotTypeModalAdd({ isOpen, onClose }: SpotTypeModalAddProps) {
  const mutation = useCreateSpotType();

  const onSubmit = async (values: SpotTypeDto) => {
    mutation.mutateAsync(values);
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="sm"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add spot type</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SpotTypeForm onSubmit={onSubmit} formId={FORM_ID} isEdit={false} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SpotTypeModalAdd;

import React, { useState } from "react";
import {
  HStack, Icon, IconButton, Image,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDeleteHotelCountry, useHotelCountryConfigs } from "src/api/hotel";
import { CellValue } from "react-table";
import { FiEdit, FiTrash } from "react-icons/fi";
import ConfirmDialog from "src/components/ConfirmDialog";
import Table from "../../../components/Table";

interface HotelCountriesTableProps {
  onEditData: (values: any) => void;
}

function HotelCountriesTable({ onEditData }:HotelCountriesTableProps) {
  const { data } = useHotelCountryConfigs();
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();
  const [deletingCode, setDeletingCode] = useState();
  const deleteHotelCountryMutation = useDeleteHotelCountry();
  const toast = useToast({ position: 'top' });
  const handleDeleteConfig = async () => {
    try {
      if (!deletingCode) {
        return;
      }
      await deleteHotelCountryMutation.mutateAsync(deletingCode);
      onCloseConfirm();
    } catch (e) {
      toast({ status: 'error', description: "Can't delete this config. Please try again later!" });
    }
  };
  const columns = React.useMemo(() => [
    { Header: "Country Name", accessor: 'country_name' },
    { Header: "Country Code", accessor: 'country_code' },
    { Header: "Cover", accessor: 'img_cover', Cell: ({ value }: CellValue) => <Image src={value} width="60px" /> },
    {
      Header: 'Action',
      Cell: ({ value, row }: CellValue) => (
        <HStack spacing={4}>
          <Tooltip label="Edit">
            <IconButton
              icon={<Icon boxSize={4} as={FiEdit} />}
              variant="ghost"
              colorScheme="blue"
              aria-label="edit"
              onClick={() => {
                onEditData(row.original);
              }}
            />
          </Tooltip>
          <Tooltip label="Delete">
            <IconButton
              icon={<Icon boxSize={4} as={FiTrash} />}
              variant="ghost"
              colorScheme="blue"
              aria-label="delete"
              onClick={() => {
                setDeletingCode(row.original?.country_code);
                onOpenConfirm();
              }}
            />
          </Tooltip>
        </HStack>
      ),
    },
  ], []);
  return (
    <>
      <ConfirmDialog
        title="Delete Hotel Country Config"
        description="Are you sure want to delete this config?"
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        handleOk={handleDeleteConfig}
      />
      <Table columns={columns} data={data ?? []} />
    </>
  );
}

export default HotelCountriesTable;

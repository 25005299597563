import { Suspense } from "react";
import {
  Box,
  Flex,
  HStack,
  Heading,
  Spacer,
  SimpleGrid,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useTokenHistories,
} from "src/api/transaction";
import { AddIcon } from "@chakra-ui/icons";
import FilterByPage from "src/components/FilterByPage";
import FilterByRangerDate from "src/components/FilterByRangerDate";
import { TOKENS } from "src/constants/transaction";
import FilterSingle from 'src/components/FilterSingle';
import useSearchParams from "../../utils/useSearchParams";
import SortByCreatedTime from "../../components/SortByCreatedTime";
import TransactionTable from "./SupplyTransactionsTable";
import FilterByPageSize from "../../components/FilterByPageSize";
import AddTokens from './AddTokens';
// import FilterByReviewType from "./components/FilterByReviewType";

function Transactions() {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { params } = useSearchParams();
  const { data } = useTokenHistories(params);
  return (
    <Flex direction="column" p={6} h="full">
      <HStack mb={4} spacing={4}>
        <Heading size="md" textTransform="uppercase" color="gray.400">
          Transactions
        </Heading>
        <Spacer />
        <Button onClick={onOpen} leftIcon={<AddIcon />}>
          Add tokens
        </Button>
      </HStack>
      <SimpleGrid
        templateRows="auto 1fr"
        borderRadius="md"
        bg="gray.800"
        flex={1}
        overflow="auto"
      >
        <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
          <Flex mb={2}>
            <HStack>
              <FilterByRangerDate />
              <FilterSingle
                title="Token"
                name="token"
                options={TOKENS}
                optionsObj={{}}
              />
              <SortByCreatedTime />
              {/* <FilterByReviewType /> */}
            </HStack>
            <Spacer />
            <FilterByPageSize />
            <FilterByPage
              totalPages={data?.meta?.totalPages}
              totalDocs={data?.meta?.totalItems}
            />
          </Flex>
        </Box>
        <Box overflow="auto" flex={1} px={5}>
          <TransactionTable data={data} />
        </Box>
      </SimpleGrid>

      <AddTokens
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
}

function TransactionsWrapper() {
  return (
    <Suspense fallback="loading">
      <Transactions />
    </Suspense>
  );
}

export default TransactionsWrapper;

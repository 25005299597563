import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalFooter,
  RadioGroup,
  Stack,
  Radio,
  Textarea,
  useToast,
} from '@chakra-ui/react';

interface RejectDialogProps{
  title: string,
  isOpen: boolean,
  onClose: any,
  handleOk: any,
  loading?: boolean,
  options: string[]
}

function RejectDialog({
  isOpen,
  onClose,
  title,
  handleOk,
  loading,
  options,
}: RejectDialogProps) {
  const [value, setValue] = useState<string>(options[0]);
  const [otherReason, setOtherReason] = useState('');

  useEffect(() => {
    if (isOpen) {
      setValue(options[0]);
      setOtherReason('');
    }
  }, [isOpen]);

  const toast = useToast();

  const handleReject = () => {
    const rejectReason = value === 'OTHER' ? otherReason : value;
    if (rejectReason) {
      handleOk(rejectReason);
    } else {
      toast({
        title: 'Error',
        description: "Please enter reject reason",
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <RadioGroup onChange={setValue} value={value}>
            <Stack>
              { options.map((option) => (
                <Radio
                  value={option}
                  key={option}
                >
                  {option}
                </Radio>
              )) }

            </Stack>
          </RadioGroup>

          { value === 'OTHER'
            && (
            <Textarea
              autoFocus
              fontWeight="medium"
              fontSize="md"
              value={otherReason}
              onChange={(e) => {
                setOtherReason(e.target.value);
              }}
              mt="24px"
              h="160px"
              placeholder="Please enter reject reason"
            />
            )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} colorScheme="transprent">Cancel</Button>
          <Button colorScheme="blue" ml={3} onClick={handleReject} isLoading={loading}>
            Save
          </Button>

        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default RejectDialog;

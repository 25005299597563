import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import {
  GridItem,
  useColorModeValue,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Heading,
  UnorderedList,
  ListItem,
  Image,
  Grid,
} from '@chakra-ui/react';
import dayjs from "dayjs";
import { useUser } from "../../api/user";
import Transactions from "../Transactions";

function CustomerDetail() {
  const { id } = useParams();
  const { data } = useUser(id || '');

  let finalSrc = '/avatar/avatar_1.png';
  if (data?.profile_avatar) {
    if (data?.profile_avatar.startsWith("http")) {
      finalSrc = data?.profile_avatar;
    } else {
      finalSrc = `/avatar/${data?.profile_avatar}.png`;
    }
  }
  return (
    <Box p="24px">
      <Heading size="md" textTransform="uppercase" color="gray.400" mb="24px">
        Customer detail
      </Heading>
      <Tabs mt="20px">
        <TabList>
          <Tab>Detail</Tab>
          <Tab>Transactions</Tab>
        </TabList>
        <TabPanels mt="20px">
          <TabPanel>
            <Box>
              <Box overflow="auto">
                <Grid gridTemplateColumns="150px 1fr" bg={useColorModeValue("white", "gray.800")} marginTop="10px">
                  <GridItem p={4}>Name</GridItem>
                  <GridItem p={4}>{data?.full_name}</GridItem>

                  <GridItem p={4}>Email</GridItem>
                  <GridItem p={4}>{data?.email}</GridItem>
                  <GridItem p={4}>Gender</GridItem>
                  <GridItem p={4}>{data?.gender}</GridItem>
                  <GridItem p={4}>Profile avatar</GridItem>
                  <GridItem p={4}>
                    <Box>
                      <Image w="60px" src={finalSrc} />
                    </Box>

                  </GridItem>
                  <GridItem p={4}>Wallet </GridItem>
                  <GridItem p={4}>
                    <UnorderedList>
                      {data?.wallets?.map((item) => (
                        <ListItem>
                          <b>{item.token}</b>
                          :
                          {' '}
                          {item.balance}
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </GridItem>
                  <GridItem p={4}>Ref code</GridItem>
                  <GridItem p={4}>{data?.ref_code}</GridItem>
                  <GridItem p={4}>Role id</GridItem>
                  <GridItem p={4}>{data?.role_id}</GridItem>
                  <GridItem p={4}>State</GridItem>
                  <GridItem p={4}>{data?.state}</GridItem>
                  <GridItem p={4}>Created at</GridItem>
                  <GridItem p={4}>
                    {dayjs(data?.created_at).format('l')}
                  </GridItem>
                </Grid>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel>
            <Transactions userId={id} />

          </TabPanel>

        </TabPanels>
      </Tabs>

    </Box>
  );
}

function ItemTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <CustomerDetail />
    </Suspense>
  );
}

export default ItemTableWrapper;

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, useDisclosure } from "@chakra-ui/react";
import ConfirmDialog from "src/components/ConfirmDialog";
import { useState } from "react";
import ApproveReviewPhotoForm from "./AddTokensForm";
import { AddTokenDto, useAddTokens } from "../../api/transaction";

const FORM_ID = "add-tokens";

interface AddTokensProps {
  isOpen: boolean;
  onClose: () => void;
}

function ApproveReviewModal({ isOpen, onClose }: AddTokensProps) {
  const mutation = useAddTokens();

  const [token, setToken] = useState<AddTokenDto>();
  const { isOpen: isOpenConfirm, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();

  const onSubmit = async (values: AddTokenDto) => {
    setToken(values);
    onOpenConfirm();
  };

  const handleAddTokens = async () => {
    onCloseConfirm();
    if (token) {
      await mutation.mutateAsync(token);
    }
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add tokens supply</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ApproveReviewPhotoForm
            onSubmit={onSubmit}
            formId={FORM_ID}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button
            form={FORM_ID}
            type="submit"
            colorScheme="blue"
            isLoading={mutation.isLoading}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>

      <ConfirmDialog
        title="Confirm"
        description="Are you sure want add tokens ?"
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        handleOk={handleAddTokens}
      />
    </Modal>
  );
}

export default ApproveReviewModal;

import { Suspense } from "react";
import useSearchParams from "../../utils/useSearchParams";
import FilterByPage from "../../components/FilterByPage";
import { useTrips } from "../../api/trip";

function SpotPaging() {
  const { params } = useSearchParams();
  const { data } = useTrips(params);

  return (
    <FilterByPage totalDocs={data?.meta?.totalItems} totalPages={data?.meta?.totalPages} />
  );
}

function SpotPagingWrapper() {
  return (
    <Suspense fallback="">
      <SpotPaging />
    </Suspense>
  );
}

export default SpotPagingWrapper;

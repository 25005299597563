import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "src/config/axios";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
// import { Partial } from "src/types/Partial";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { useToast } from "@chakra-ui/react";
import { REVIEW_STATUS } from "src/constants/review";
import { DEFAULT_PAGE_SIZE } from "../constants/pagination";

interface IImage {
  url: string
}
export interface CreateReviewDto {
  review_type: string,
  review_content?: string,
  content_vi?: string,
  content_en?: string,
  content_jp?: string,
  photos?: string[],
  spot_id?: number,
  rating_number?: number,
  id?: number,
  spot?: any,
  spot_name?: string,
  images?: IImage[] | string[]
}

export interface ApproveReviewDto {
  review_type: string,
  id: number,
  content_vi?: string,
  content_en?: string,
  content_jp?: string,
  token_number: number,
  image_ids?: number[]
}

interface RejectReviewDto {
  reviewId: string | undefined,
  status: string,
  reason: string,
  review_type: string
}

interface GetReviewsDto {
  country?: string,
  status?: string,
  type?: string,
  sort?: string,
  orderBy?: string,
  review_type?: string,
  spot_status?: string,
  spot_id?: string | number | undefined
}

export interface Photo {
  id: number,
  order: number,
  image: string,
  status?: string
}

export interface ReviewCount {
  review_type: string,
  total: string
}

export interface ReviewDocument extends TimeStamp, ObjectId {
  rating_number: number;
  review_content: string;
  spot_review_images: string[],
  status: string,
  full_name: string,
  email: string,
  spot_name: string,
  spot_type: string,
  review_type: string,
  photos: Photo[],
  country?: string,
  content_vi?: string,
  content_en?: string,
  content_jp: string,
  spot_id?: string
}

export const IMAGE_FILE_TYPE = {
  PNG: 'image/png',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
};

export const REVIEW_TYPES = [{
  label: 'Overview',
  value: 'Overview',
}, {
  label: 'Photo',
  value: 'Photo',
}, {
  label: 'Feedback',
  value: 'Feedback',
}];

export const REVIEW_OVERVIEW_REJECT_OPTIONS = [
  "Your language is inappropriate. Local language or English should be used.",
  "Your review isn’t relevant to this spot.",
  "Your review is not inappropriate.",
  "Duplicate reviews.",
];

export const REVIEW_FEEDBACK_REJECT_OPTIONS = [
  "Your language is inappropriate. Local language or English should be used.",
  "Your review isn’t relevant to this spot.",
  "Your review is not inappropriate.",
  "Duplicate reviews.",
  "The image content violates our Community policy.",
  "Your photo size is too small or too large.",
  "Your photo is irrelevant to the spot.",
  "Duplicate photos.",
];

export const REVIEW_PHOTO_REJECT_OPTIONS = [
  "The image content violates our Community policy.",
  "Your photo size is too small or too large.",
  "Your photo is irrelevant to the spot.",
  "Duplicate photos.",
];

export const SEARCH_TYPE_OPTIONS = [{
  label: 'Email',
  value: 'email',
}, {
  label: 'Customer name',
  value: 'customerName',
}, {
  label: 'Spot name',
  value: 'spotName',
}];

export const SEARCH_TYPE_PLACEHOLDER = {
  email: 'email',
  customerName: 'customer name',
  spotName: 'spot name',
};

export const reviewKeys = generateQueryKey("review");
export const reviewImagesKeys = generateQueryKey("reviewImagesKeys");

// ======== Admin create review =======
export const createReviews = async (review: CreateReviewDto) => {
  const { data } = await axios.post("/admin/spot-reviews/create", review);
  return data;
};

export const useCreateReview = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(createReviews, {
    onSuccess: () => {
      queryClient.invalidateQueries(reviewKeys.all);
      toast({ status: "success", description: "Create review successful!" });
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};

// ======== Admin create review =======
export const updateReview = async (review: CreateReviewDto) => {
  const { data } = await axios.put(`/admin/spot-reviews/${review.id}/update`, review);
  return data;
};

export const useUpdateReview = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(updateReview, {
    onSuccess: () => {
      queryClient.invalidateQueries(reviewKeys.all);
      toast({ status: "success", description: "Update review successful!" });
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};

// ======== Admin create review =======
export const deleteReview = async (id: number) => {
  const { data } = await axios.delete(`/admin/spot-reviews/${id}`);
  return data;
};

export const useDeleteReview = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(deleteReview, {
    onSuccess: () => {
      queryClient.invalidateQueries(reviewKeys.all);
      toast({ status: "success", description: "Delete review successful!" });
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};

// ======== Reject review status ======
export const updateReviewStatus = async (review: RejectReviewDto) => {
  await axios.put(
    '/admin/spot-reviews/confirm',
    {
      id: review.reviewId,
      status: REVIEW_STATUS.rejected,
      reason: review.reason,
    },
  );

  return true;
};

export const useUpdateReviewStatus = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(updateReviewStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(reviewKeys.all);
      toast({ status: "success", description: "Reject review successful!" });
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};

// ===== Get review overview and feedback ======
export const getReviewsWatingNumber = async (params = {}) => {
  try {
    const { data } = await axios.get<ReviewCount[]>(
      "/admin/spot-reviews/status/count",
      {
        params: {
          ...params,
          status: REVIEW_STATUS.waiting,
        },
      },
    );
    const reviewCount: any = {};
    data.forEach((item) => {
      reviewCount[item.review_type] = item.total;
    });
    return reviewCount;
  } catch (e) {
    return null;
  }
};

export const useReviewsWaitingNumber = (params = {}) => (
  useQuery(
    reviewKeys.list({ reviewWaiting: true, ...params }),
    () => getReviewsWatingNumber(params),
  )
);

// ===== Get review overview and feedback ======
export const getReviews = async (
  params?: QueryOptionParams & GetReviewsDto,
) => {
  try {
    const { data } = await axios.get<PaginateDocument<ReviewDocument>>(
      "/admin/spot-reviews",
      {
        params: {
          page: 1, limit: DEFAULT_PAGE_SIZE, ...params,
        },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useReviews = (params?: QueryOptionParams & GetReviewsDto) => (
  useQuery(reviewKeys.list(params), () => getReviews(params))
);

// ===== Approve review ======
export const approveReview = async (review: ApproveReviewDto) => {
  try {
    await axios.put(
      '/admin/spot-reviews/confirm',
      {
        id: review.id,
        status: REVIEW_STATUS.approved,
        content_vi: review.content_vi,
        content_en: review.content_en,
        content_jp: review.content_jp,
        token_number: review.token_number || 0,
        image_ids: review.image_ids,
      },
    );
  } catch (e) {
    return false;
  }

  return true;
};

export const useApproveReview = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(approveReview, {
    onSuccess: () => {
      queryClient.invalidateQueries(reviewKeys.all);
      queryClient.invalidateQueries(reviewImagesKeys.all);

      toast({ status: "success", description: "Approve review successful!" });
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};

// ======== Admin create review =======
export const getUploadUrlSpotPhoto = async (fileType: string) => {
  const { data } = await axios.get(
    "/admin/spot-review-images/upload-url",
    {
      params: {
        fileType,
      },
    },
  );
  return data;
};

export const uploadPhotoWithPresignedUrl = async (file: File, url: string) => {
  const data = await axios.put(url, file, {
    headers: {
      "content-type": IMAGE_FILE_TYPE.PNG,
    },
  });
  return data;
};

export const createSpotReviewImage = async (spotId: string, images: string[]) => {
  const { data } = await axios.post(
    `/admin/spot-review-images/${spotId}`,
    { images },
  );
  return data;
};

export const getReviewImages = async (spotId: string) => {
  const { data } = await axios.get<Photo[]>(
    `/admin/spot-review-images/${spotId}`,
  );
  return data;
};

// update edited review image
export const updateEditedReviewImages = async (data) => {
  const res = await axios.put(
    `/admin/spot-review-images`,
    data,
  );
  return res;
};

export const useUpdateEditedReviewImages = (spotId: string) => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(updateEditedReviewImages, {
    onSuccess: () => {
      queryClient.invalidateQueries(reviewImagesKeys.detail(spotId));
      toast({ status: "success", description: "Edit image successful!" });
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};

export const useReviewImages = (spotId: string) => (
  useQuery(reviewImagesKeys.detail(spotId), () => getReviewImages(spotId))
);

// review image order
export const updateImagesOrder = async (orders) => {
  const { data } = await axios.put(
    `/admin/spot-review-images/order`,
    { orders },
  );
  return data;
};

export const useUpdateImagesOrder = (spotId: string) => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(updateImagesOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries(reviewImagesKeys.detail(spotId));
      toast({ status: "success", description: "Update order successful!" });
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};

import * as React from "react";
import { ColorModeScript, ChakraProvider } from "@chakra-ui/react";
import * as ReactDOM from "react-dom/client";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  // BrowserRouter as Router,
  unstable_HistoryRouter as Router,
} from "react-router-dom";
import history from "./routes/history";
import { App } from "./App";
import "./config/axios";
import "./config/dayjs";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme/index";

const container = document.getElementById("root");
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      suspense: true,
    },
  },
});

// const RouteAdapter = React.FC < { children?: React.ReactNode } > = ({ children }) => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const adaptedHistory = React.useMemo(
//     () => ({
//       replace(location: any) {
//         navigate(location, { replace: true, state: location.state });
//       },
//       push(location: any) {
//         navigate(location, { replace: false, state: location.state });
//       },
//     }),
//     [navigate]
//   );
//   return children({ history: adaptedHistory, location });
// };

root.render(
  // <React.StrictMode>
  <>
    <ColorModeScript />
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Router history={history}>
        {/* <QueryParamProvider ReactRouterRoute={RouteAdapter}> */}
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </Router>

    </QueryClientProvider>
  </>,
  // </React.StrictMode >,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

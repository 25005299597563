import { useSearchParams } from "react-router-dom";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Suspense, useMemo, useState } from "react";
import { Option } from "src/types/Options";

interface FilterProps {
  title: string,
  options: Option[],
  name: string,
  optionsObj: any
}

function Filter({
  title, options, name, optionsObj,
}: FilterProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentType = searchParams.get(name);

  const [value, setValue] = useState(currentType?.split(','));

  const displayVal = useMemo(
    () => (currentType
      ? currentType?.split(',')?.map((ite) => optionsObj[ite] || ite).join(', ') : ''),
    [currentType, optionsObj],
  );

  return (
    <Popover onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={currentType ? "blue.500" : undefined}
          variant="ghost"
          // textTransform="capitalize"
          onClick={onOpen}
        >
          <Text maxW="160px" noOfLines={1}>
            {displayVal || title}
          </Text>

        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>
          <Box maxH="56" overflowY="auto">
            {options.map((item) => (
              <HStack
                color={item.value === currentType ? "blue.500" : undefined}
                cursor="pointer"
                _hover={{ bg: "gray.600" }}
                borderRadius="md"
                key={item.value}
                p={2}
              >
                <Checkbox
                  isChecked={value && value?.indexOf(item.value) !== -1}
                  onChange={() => {
                    setValue((val) => {
                      if (!val) {
                        return [item.value];
                      }
                      if (val?.indexOf(item.value) !== -1) {
                        return val?.filter((v) => v !== item.value);
                      }
                      return [...val, item.value];
                    });
                  }}
                >
                  <Text
                    fontSize="sm"
                    fontWeight="semibold"
                    textTransform="capitalize"
                  >
                    {item.label}
                  </Text>
                </Checkbox>

              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <Flex justifyContent="space-between">
            <ButtonGroup size="sm">
              <Button
                variant="outline"
                colorScheme="blue"
                onClick={() => {
                  searchParams.delete(name);
                  setValue([]);
                  setSearchParams(searchParams);
                  onClose();
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
            <ButtonGroup size="sm">
              <Button
                colorScheme="blue"
                onClick={() => {
                  if (value && value?.length > 0) {
                    searchParams.set(name, value?.join(','));
                    setSearchParams(searchParams);
                    onClose();
                  } else {
                    setValue([]);
                    searchParams.delete(name);
                    setSearchParams(searchParams);
                    onClose();
                  }
                }}
              >
                Apply
              </Button>
            </ButtonGroup>
          </Flex>

        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterByReviewType({
  title, options, name, optionsObj,
}: FilterProps) {
  return (
    <Suspense
      fallback={(
        <Button variant="ghost" isLoading>
          {title}
        </Button>
      )}
    >
      <Filter options={options} title={title} name={name} optionsObj={optionsObj} />
    </Suspense>
  );
}

export default FilterByReviewType;

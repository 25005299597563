import React, { useState } from 'react';
import {
  Button,
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader,
  ModalOverlay, VStack, useToast,
} from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useUpdateCountry } from 'src/api/country';
import LabeledTextField from '../LabeledTextField';
import BoxImageConfig from '../Image/BoxImageConfig';

interface EditContryModalProps {
  isOpen: boolean;
  onClose: () => void;
  editingData?: any;
}
const FORM_ID = 'edit-country';
function EditCountryModal({ isOpen, onClose, editingData }: EditContryModalProps) {
  const toast = useToast({ position: 'top' });
  const [imgCover, setImageCover] = useState<string>(editingData?.img_cover || '');
  const updateCountryMutation = useUpdateCountry();
  const onSubmit = async (values) => {
    try {
      const data = {
        ...values,
        img_cover: imgCover ?? values.img_cover,
      };
      await updateCountryMutation.mutateAsync({ code: values.country_code, body: data });
      toast({ status: 'success', description: 'Country information updated!' });
      onClose?.();
    } catch (e) {
      toast({ status: 'error', description: 'Can\'t update country information. Please try again later!' });
    }
  };
  return (
    <Modal closeOnOverlayClick={false} size="xl" isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Contry</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <Form
            initialValues={editingData}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} id={FORM_ID}>
                <VStack spacing={4}>
                  <LabeledTextField name="country_code" label="Country Code" disabled />
                  <LabeledTextField name="country_name" label="Country Name" disabled />
                  <LabeledTextField name="name_ja" label="Japanese Name" />
                  <LabeledTextField name="name_vi" label="Vietnamese Name" />
                  <BoxImageConfig
                    imageSrc={editingData.img_cover}
                    title="Cover"
                    id="country-cover"
                    subTitle="Image Size: 450 x 330"
                    onImageChanged={(url:string) => { setImageCover(url); }}
                  />
                </VStack>
              </form>
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>Cancel</Button>
          <Button type="submit" colorScheme="blue" form={FORM_ID}>Update</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EditCountryModal;

import React from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import styled from "@emotion/styled";
import { IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Partial } from "src/types/Partial";

const StyledPagination = styled(ReactPaginate)`
  color: blue;
  display: flex;
  align-items: center;
  list-style: none;
  gap: 4px;

  .selected {
    a {
      background: #3182ce;
      color: white;
    }
  }

  a {
    width: 28px;
    height: 28px;
    color: #4a5568;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 100px;
  }
`;

function Pagination(props: Partial<ReactPaginateProps>) {
  return (
    <StyledPagination
      pageCount={4}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      pageClassName="page"
      nextLabel={(
        <IconButton
          variant="ghost"
          ml={2}
          icon={<ChevronRightIcon boxSize={5} />}
          colorScheme="gray"
          aria-label="next"
        />
      )}
      previousLabel={(
        <IconButton
          variant="ghost"
          mr={2}
          icon={<ChevronLeftIcon boxSize={5} />}
          colorScheme="gray"
          aria-label="next"
        />
      )}
      {...props}
    />
  );
}

export default Pagination;

import React, { useState, Suspense } from "react";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  Input,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { useCountries } from "src/api/country";
import { useSearchParams } from "react-router-dom";
import { ICountry } from "src/interfaces/country";

interface FilterByCountryProps {
  label?: string;
}

function Filter({ label = "Country" }: FilterByCountryProps) {
  const [search, setSearch] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentCountry = searchParams.get("country");
  const { data: dataCountries } = useCountries();
  const countryOptions = dataCountries?.map((country: ICountry) => ({
    value: country.country_code,
    label: country.country_name,
  }));

  const filteredCountries = (countryOptions || []).filter((country: any) => {
    const name = country.label?.toLowerCase() || "";
    const value = country.value?.toLowerCase() || "";
    return name.includes(search.toLowerCase()) || value.includes(search.toLowerCase());
  });

  const selectedCountry = countryOptions?.find(
    (item: any) => item.value === currentCountry,
  );

  return (
    <Popover onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={currentCountry ? "blue.500" : undefined}
          variant="ghost"
          onClick={onOpen}
        >
          {selectedCountry?.label || label}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>{label}</PopoverHeader>
        <PopoverBody>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            mb={3}
            type="text"
            placeholder=""
          />
          <Box maxH="56" overflowY="auto">
            {filteredCountries.map((country: any) => (
              <HStack
                onClick={() => {
                  searchParams.set("country", country.value);
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                  onClose();
                }}
                color={country.value === currentCountry ? "blue.500" : undefined}
                cursor="pointer"
                _hover={{ bg: "gray.600" }}
                borderRadius="md"
                key={country.value}
                p={2}
              >
                <Text fontSize="sm" fontWeight="semibold">
                  {country.value}
                  {' '}
                  -
                  {' '}
                  {country.label}
                </Text>
              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                searchParams.delete("country");
                searchParams.set("page", "1");
                setSearch("");
                setSearchParams(searchParams);
                onClose();
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterByCountry(props: FilterByCountryProps) {
  return (
    <Suspense
      fallback={(
        <Button variant="ghost" isLoading>
          {props.label}
        </Button>
      )}
    >
      <Filter {...props} />
    </Suspense>
  );
}

export default FilterByCountry;

import axios from "src/config/axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import isEmpty from "lodash/isEmpty";
import generateQueryKey from "src/utils/queryKeyFactory";
import { ICountry } from "src/interfaces/country";

export const getCities = async (params: string) => {
  try {
    const { data } = await axios.get(`/cities/${params}`);
    return data;
  } catch (e) {
    return null;
  }
};
export const useDataCities = (params: string) => useQuery(["spot_cities", params], () => getCities(params), {
  enabled: !isEmpty(params),
});

export const getCountries = async (): Promise<Array<ICountry>> => {
  try {
    const { data } = await axios.get<Array<ICountry>>("/countries");
    return data;
  } catch (e) {
    return [] as Array<ICountry>;
  }
};

export const countryKeys = generateQueryKey('countries');
export const useCountries = () => useQuery(countryKeys.all, getCountries);

export const getCountryCode = async (countryCode: string) => {
  try {
    const { data } = await axios.get(`/countries/${countryCode}`);
    return data;
  } catch (e) {
    return null;
  }
};
export const useCountryCode = (countryCode: string) => useQuery("countries", () => getCountryCode(countryCode));

export const updateCountry = async (code: string, body: ICountry) => {
  const { data } = await axios.patch(`/countries/${code}`, body);
  return data;
};

// eslint-disable-next-line max-len
export const useUpdateCountry = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ code, body }: {code: string; body: ICountry;}) => updateCountry(code, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(countryKeys.all);
      },
    },
  );
};

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button } from "@chakra-ui/react";
import CreateReviewForm from "./CreateReviewForm";
import {
  useCreateReview, CreateReviewDto, ReviewDocument, useUpdateReview,
} from "../api/review";

const FORM_ID = "create-review";

interface ApproveReviewProps {
  isOpen: boolean;
  onClose: () => void;
  reviewType: string;
  selectedReview?: ReviewDocument | undefined
}

function ApproveReviewModal({
  isOpen, onClose, reviewType, selectedReview,
}: ApproveReviewProps) {
  const mutationCreate = useCreateReview();
  const murationEdit = useUpdateReview();

  const onSubmit = async (values: CreateReviewDto) => {
    const data = {
      ...values,
      spot_id: values.spot?.value,
      images: values.images?.map((item) => item.url),
    };
    if (values.id) {
      await murationEdit.mutateAsync(data);
    } else {
      await mutationCreate.mutateAsync(data);
    }

    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {selectedReview ? 'Edit review' : 'Create review'}
          {' '}
          {reviewType}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateReviewForm
            onSubmit={onSubmit}
            formId={FORM_ID}
            reviewType={reviewType}
            initialValues={selectedReview ? {
              ...selectedReview,
              spot: {
                value: selectedReview.spot_id,
                label: selectedReview.spot_name,
              },
              images: selectedReview?.photos?.map((item) => ({
                ...item,
                url: item.image,
              })),
            } : {
              review_type: reviewType,
              images: [],
            }}

          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button
            form={FORM_ID}
            type="submit"
            colorScheme="blue"
            isLoading={murationEdit.isLoading || mutationCreate.isLoading}
            // disabled={mutation.isError}
          >
            {selectedReview ? 'Edit review' : 'Create review'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ApproveReviewModal;

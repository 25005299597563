import { Form } from "react-final-form";
import { Suspense } from "react";
import { Stack } from "@chakra-ui/react";
import LabeledSelectField from "src/components/LabeledSelectField";
import { useAllRoles, RoleDocument } from "src/api/role";
import { GetCustomerDto } from "../../api/user";
import LabeledTextField from "../../components/LabeledTextField";

interface UserFormProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: GetCustomerDto;
}

interface IUserError {
  fullName?: string,
  email?: string,
  password?: string,
  roleId?: string
}

const validate = (values) => {
  const errors: IUserError = {};
  if (!values.fullName || !values.fullName.trim()) {
    errors.fullName = 'Please enter full name';
  }
  if (!values.email || !values.email.trim()) {
    errors.email = 'Please enter email';
  }
  if (!values.password) {
    errors.password = 'Please enter password';
  }
  if (!values.roleId) {
    errors.roleId = 'Please select role';
  }
  return errors;
};

function UserForm({ onSubmit, formId, initialValues }: UserFormProps) {
  const { data: dateRoles } = useAllRoles();
  const roleOptions = dateRoles
    ? dateRoles.map((r: RoleDocument) => ({
      value: r.id,
      label: r.role_name,
    }))
    : [];

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <LabeledTextField name="fullName" label="Name" required />
            <LabeledSelectField
              options={roleOptions}
              name="roleId"
              label="Select role"
              required
            />
            <LabeledTextField
              name="email"
              label="Email"
              type="email"
              required
            />
            <LabeledTextField
              name="password"
              label="Password"
              type="password"
              required
            />
          </Stack>
        </form>
      )}
    />
  );
}

function UserFormWrapper({ onSubmit, formId, initialValues }: UserFormProps) {
  return (
    <Suspense fallback="loading...">
      <UserForm
        onSubmit={onSubmit}
        formId={formId}
        initialValues={initialValues}
      />
    </Suspense>
  );
}

export default UserFormWrapper;

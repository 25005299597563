import { Spinner } from "@chakra-ui/spinner";
import React, { Suspense } from "react";
import LabeledSelectField from "./LabeledSelectField";
import { useSpotTypes } from "../api/spot";

interface LabeledSelectSpotTypeProps {
  name: string;
  label: string;
  onValueChange: () => {};
}

function Select({ name, label, onValueChange }: LabeledSelectSpotTypeProps) {
  const { data: spotTypes } = useSpotTypes({ limit: 1000 });
  const spotTypeOptions = (spotTypes?.items || []).map((item) => ({
    value: item.type_code,
    label: item.name || item.type_code,
  }));
  return (
    <LabeledSelectField
      options={spotTypeOptions}
      name={name}
      label={label}
      onValueChange={onValueChange}
    />
  );
}

function LabeledSelectSpotType(props: LabeledSelectSpotTypeProps) {
  return (
    <Suspense fallback={<Spinner />}>
      <Select {...props} />
    </Suspense>
  );
}

export default LabeledSelectSpotType;

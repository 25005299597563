import * as React from 'react';
import {
  Box, Text, Image, Button, Input, useToast,
} from "@chakra-ui/react";
import { BsUpload } from "react-icons/bs";
import { getUploadUrlAppConfigPhoto } from 'src/api/app-config';
import { IMAGE_FILE_TYPE } from 'src/api/review';
import noImage from 'src/assets/images/no-image.png';
import axios from 'axios';

interface ImageConfigProp {
  title: string;
  imageSrc?: string,
  onImageChanged?: (url: string) => void,
  subTitle?: string;
  id:string;
  willRefreshImage?: boolean;
}

function BoxImageConfig({
  title, imageSrc, onImageChanged, subTitle, id,
  willRefreshImage = false,
}: ImageConfigProp) {
  const ref = React.useRef<any>();
  const toast = useToast({ position: 'top' });
  const [imgSrc, setImgSrc] = React.useState(imageSrc || noImage);
  React.useEffect(() => {
    if (!willRefreshImage) {
      return;
    }
    setImgSrc(imageSrc || noImage);
  }, [imageSrc]);
  const [isUploading, setIsUploading] = React.useState(false);
  const handleSelectImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    if (file.size > 1024 * 1024 * 5) {
      toast({ status: 'error', description: 'Please choose file have size less than 5mb!' });
    }
    const presignedUrl = await getUploadUrlAppConfigPhoto(IMAGE_FILE_TYPE.JPG);
    const config = {
      method: 'put',
      url: presignedUrl.signedRequest,
      headers: {
        'Content-Type': "image/png",
      },
      data: file,
      onUploadProgress: (progressEvent) => {
      },
    };
    await axios(config);
    setImgSrc(presignedUrl.url);
    if (onImageChanged) {
      onImageChanged(presignedUrl.url);
    }
    setIsUploading(false);
  };
  return (
    <Box borderRadius="11px" border="1px" borderColor="rgba(255, 255, 255, 0.16)" p="10px" backgroundColor="var(--ninjakids-colors-gray-800)" mr="20px">
      <Text fontSize="18px" fontWeight="bold" textAlign="center" mb="5px" mt="10px">
        {title}
      </Text>
      <Text fontSize="12px" fontWeight="normal" textAlign="center" mb="15px" mt="5px">
        {subTitle ?? 'Image Size: 1080x675'}
      </Text>
      <Image src={imgSrc} width={500} />
      <Box mt="20px">
        <Input
          type="file"
          accept="image/*"
          display="none"
          id={`upload-${id}`}
          onChange={(event) => {
            handleSelectImage(event);
            ref.current.value = '';
          }}
          ref={ref}
        />
        <Box marginInline={0} mt="20px" htmlFor={`upload-${id}`} as="label">
          <Button colorScheme="blue" as="span" variant="outline" leftIcon={<BsUpload />} disabled={isUploading}>
            Select Image
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(BoxImageConfig);

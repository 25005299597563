import {
  Box, Button, HStack, Heading, SimpleGrid, Spacer, useDisclosure,
} from "@chakra-ui/react";
import { Suspense, useState } from "react";
import { RiAddFill } from "react-icons/ri";
import HotelCountriesTable from './hotelCountriesTable';
import HotelCountryModal from "./HotelCountryModal";

function HotelCountriesConfig() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [editingData, setEditingData] = useState<any>();
  const [isNewForm, setIsNewForm] = useState(true);

  const onAddCountry = () => {
    setIsNewForm(true);
    setEditingData(null);
    onOpen();
  };
  const onEditingConfig = (values: any) => {
    setEditingData({
      countryCode: values.country_code,
      countryName: values.country_name,
      imgCover: values.img_cover,
      id: values.id,
    });
    setIsNewForm(false);
    onOpen();
  };
  return (
    <>
      <HotelCountryModal
        isOpen={isOpen}
        onClose={() => {
          setEditingData(null);
          onClose();
        }}
        addNew={isNewForm}
        editingData={editingData}
      />
      <Box p="24px">
        <HStack mb="20px">
          <Heading size="md" textTransform="uppercase" color="gray.400" mb="24px">
            Hotel Countries
          </Heading>
          <Spacer />
          <Button onClick={onAddCountry} leftIcon={<RiAddFill />}>Add Country</Button>
        </HStack>
        <SimpleGrid templateRows="auto 1fr" border="md" bg="gray.800" flex={1} overflow="auto">
          <Box overflow="auto" flex={1} px={5}>
            <HotelCountriesTable onEditData={onEditingConfig} />
          </Box>
        </SimpleGrid>
      </Box>
    </>
  );
}

function Wrapper() {
  return (
    <Suspense fallback="loading...">
      <HotelCountriesConfig />
    </Suspense>
  );
}

export default Wrapper;

import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Input,
  InputGroup,
  InputRightElement,
  CloseButton,
  Select,
} from "@chakra-ui/react";
import { Option } from "src/types/Options";

interface FilterBySearchKeyProps {
  searchTypes: Option[],
  searchTypePlaceholder: any
}

function FilterBySearchKeyAndSearchType({
  searchTypes,
  searchTypePlaceholder,
}: FilterBySearchKeyProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get("search") || "");
  const firstUpdate = useRef(true);
  const [searchType, setSearchType] = useState(searchParams.get("search_type") || searchTypes[0].value);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return () => { };
    }
    const delayDebounceFn = setTimeout(() => {
      searchParams.set("search", value);
      searchParams.set("page", "1");
      searchParams.set("search_type", searchType);
      setSearchParams(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  return (
    <InputGroup w="sm" bgColor="rgba(255, 255, 255, 0.04);">
      <Select
        value={searchType}
        onChange={(e) => {
          searchParams.set("search_type", e.target.value);
          setSearchType(e.target.value);
          if (value) {
            setSearchParams(searchParams);
          }
        }}
        size="sm"
        border="0"
        outline={0}
        w="200px"
      >
        {searchTypes.map((item) => (
          <option
            key={item.value}
            value={item.value}
            style={{
              position: 'absolute',
              top: '10px;',
            }}
          >
            {item.label}
          </option>
        ))}

      </Select>
      <Input
        // autoFocus
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
        type="text"
        placeholder={`Search by ${searchTypePlaceholder[searchType]}`}
        borderRadius="6px"
        bgColor="transparent"
        w="300px"
        border="0"
      />
      {value && (
        <InputRightElement>
          <CloseButton
            onClick={() => {
              setValue("");
            }}
            size="sm"
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}

export default FilterBySearchKeyAndSearchType;

import {
  Box,
  Heading,
  Flex,
  Spacer,
  Button,
  HStack,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { AddIcon } from "@chakra-ui/icons";
import FilterBySearchKeyAndSearchType from "src/components/FilterBySearchKeyAndSearchType";
import { SEARCH_TYPE_OPTIONS, SEARCH_TYPE_PLACEHOLDER } from 'src/api/spot';
import FilterByDeleteStatus from "src/components/FilterByDeleteStatus";
import SpotTable from "./SpotTable";
import SpotModalAdd from "./SpotModalAdd";
import SpotPaging from "./SpotPaging";
import FilterByPageSize from "../../components/FilterByPageSize";
import SortByCreatedTime from "../../components/SortByCreatedTime";
import FilterBySpotType from "../../components/FilterBySpotType";
import FilterBySpotStatus from "./components/FilterBySpotStatus";
import FilterByCountry from "../../components/FilterByCountry";

function Spot() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <SpotModalAdd isOpen={isOpen} onClose={onClose} />
      <Flex direction="column" p={6} h="full">
        <HStack mb={4} spacing={4}>
          <Heading size="md" textTransform="uppercase" color="gray.400">
            Spot
          </Heading>
          <Spacer />

          <Button onClick={onOpen} leftIcon={<AddIcon />}>
            Add Spot
          </Button>
        </HStack>
        <SimpleGrid
          templateRows="auto 1fr"
          borderRadius="md"
          bg="gray.800"
          flex={1}
          overflow="auto"
        >
          <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
            <Flex mb={2}>
              <HStack>
                <FilterByDeleteStatus />
                <FilterBySearchKeyAndSearchType
                  searchTypes={SEARCH_TYPE_OPTIONS}
                  searchTypePlaceholder={SEARCH_TYPE_PLACEHOLDER}
                />
                <FilterByCountry />
                <FilterBySpotType />
                <FilterBySpotStatus />
                <SortByCreatedTime />
              </HStack>
              <Spacer />
              <FilterByPageSize />
              <SpotPaging />
            </Flex>
          </Box>
          <Box overflow="auto" flex={1} px={5}>
            <SpotTable />
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
}

export function SpotWrapper() {
  return (
    <React.Suspense fallback="Loading...">
      <Spot />
    </React.Suspense>
  );
}

export default SpotWrapper;

import React, { forwardRef, PropsWithoutRef } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  useToken,
  Text,
} from "@chakra-ui/react";
import { useField } from "react-final-form";
import { StylesConfig } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

export interface LabeledTextFieldProps
  extends PropsWithoutRef<JSX.IntrinsicElements["textarea"]> {
  /** Field name. */
  name: string;
  /** Field label. */
  label?: string;
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  helperText?: string;
  defaultValue?: any;
  onValueChange?: any;
  loadOptions: any
}

export function useSelectStyles(error) {
  const [gray100, gray200, gray500, gray600, whiteAlpha300] = useToken(
    "colors",
    ["gray.100", "gray.200", "gray.500", "gray.600", "whiteAlpha.100"],
  );
  const [shadowMd] = useToken("shadows", ["md"]);
  const styles = React.useMemo<StylesConfig>(
    () => ({
      menu: (provided) => ({
        ...provided,
        width: "100%",
        color: gray100,
        padding: 6,
        borderRadius: 10,
        border: `1px solid ${gray500}`,
        outline: "none",
        boxShadow: shadowMd,
        background: gray600,
      }),

      menuPortal: (provided) => ({ ...provided, border: "none" }),
      option: (optionStyles: any, state: any) => ({
        ...optionStyles,
        backgroundColor: state.isFocused && gray500,
        color: gray100,
        borderRadius: 6,
      }),
      control: (provided) => ({
        ...provided,
        width: "100%",
        display: "flex",
        border: error ? '2px solid #FC8181 !important' : `none`,
        backgroundColor: whiteAlpha300,
        height: "32px",
        borderRadius: 8,
        color: gray100,
        minHeight: 40,
        outline: 0,
      }),
      container: (provided) => ({
        ...provided,
        width: "100%",
        fontSize: "14px",
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";
        return {
          ...provided, opacity, transition, color: gray200,
        };
      },
      input: (provided) => ({
        ...provided,
        color: gray100,
      }),
    }),
    [shadowMd, gray100, gray600, gray200, gray500, whiteAlpha300, error],
  );
  return styles;
}

export const LabeledSelectAsyncField = forwardRef<
  HTMLTextAreaElement,
  LabeledTextFieldProps
>(
  (
    {
      name,
      label,
      outerProps,
      helperText,
      disabled,
      loadOptions,
      defaultValue,
      onValueChange = () => { },
      required,
    },
  ) => {
    const {
      input: { onChange, value },
      meta: { touched, error, submitError },
    } = useField(name);
    const normalizedError = Array.isArray(error)
      ? error.join(", ")
      : error || submitError;
    const customStyles = useSelectStyles(touched && normalizedError);
    return (
      <FormControl
        color="gray.100"
        isInvalid={touched && normalizedError}
        {...outerProps}
      >
        <FormLabel fontSize="sm" mb="1.5" display="flex" alignItems="center">
          {label}
          {required
            && (
            <>
              {' '}
              <Text>*</Text>
            </>
            )}
        </FormLabel>
        <AsyncPaginate
          value={value}
          onChange={(val) => {
            onChange(val);
            onValueChange(val?.value);
          }}
          styles={customStyles}
          loadOptions={loadOptions}
          defaultValue={defaultValue}
          isDisabled={disabled}
          required
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {
          touched && normalizedError && (
            <FormErrorMessage>{normalizedError}</FormErrorMessage>
          )
        }
      </FormControl>
    );
  },
);

export default LabeledSelectAsyncField;

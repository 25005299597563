import React from "react";
import Transactions from "../Transactions";

function TransactionsSpend() {
  return (
    <Transactions type="spend" />
  );
}

export default TransactionsSpend;

import { useSearchParams } from "react-router-dom";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Suspense } from "react";
import { useSpotTypes } from "src/api/spot";

function Filter() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentType = searchParams.get("type");
  const { data } = useSpotTypes({ limit: 1000 });

  return (
    <Popover onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={currentType ? "blue.500" : undefined}
          variant="ghost"
          // textTransform="capitalize"
          onClick={onOpen}
        >
          {currentType || "Spot type"}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>Spot type</PopoverHeader>
        <PopoverBody>
          <Box maxH="56" overflowY="auto">
            {data?.items.map((type) => (
              <HStack
                onClick={() => {
                  searchParams.set("type", type.type_code);
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                  onClose();
                }}
                color={type.type_code === currentType ? "blue.500" : undefined}
                cursor="pointer"
                _hover={{ bg: "gray.600" }}
                borderRadius="md"
                key={type.type_code}
                p={2}
              >
                <Box
                  flexShrink={0}
                  w={2.5}
                  h={2.5}
                  bg="blue.500"
                  borderRadius="full"
                />
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  textTransform="capitalize"
                >
                  {type.name || type.type_code}
                </Text>
              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                searchParams.delete("type");
                searchParams.set("page", "1");
                setSearchParams(searchParams);
                onClose();
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterBySpotType() {
  return (
    <Suspense
      fallback={(
        <Button variant="ghost" isLoading>
          Danh mục
        </Button>
      )}
    >
      <Filter />
    </Suspense>
  );
}

export default FilterBySpotType;

import { useSearchParams } from "react-router-dom";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Suspense } from "react";
import { renderTripStateColor } from "src/utils/color";
import { TRIP_STATE } from "src/api/trip";

function Filter() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStatus = searchParams.get("state");

  return (
    <Popover onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={currentStatus ? "blue.500" : undefined}
          variant="ghost"
          textTransform="capitalize"
          onClick={onOpen}
        >
          {currentStatus || "State"}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>Trip state</PopoverHeader>
        <PopoverBody>
          <Box maxH="56" overflowY="auto">
            {TRIP_STATE.map((state) => (
              <HStack
                onClick={() => {
                  searchParams.set("state", state);
                  setSearchParams(searchParams);
                  onClose();
                }}
                color={state === currentStatus ? "blue.500" : undefined}
                cursor="pointer"
                _hover={{ bg: "gray.600" }}
                borderRadius="md"
                key={state}
                p={2}
              >
                <Box
                  flexShrink={0}
                  w={2.5}
                  h={2.5}
                  bg={renderTripStateColor(state)}
                  borderRadius="full"
                />
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  textTransform="capitalize"
                >
                  {state}
                </Text>
              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                searchParams.delete("state");
                setSearchParams(searchParams);
                onClose();
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterByTripState() {
  return (
    <Suspense
      fallback={(
        <Button variant="ghost" isLoading>
          Danh mục
        </Button>
      )}
    >
      <Filter />
    </Suspense>
  );
}

export default FilterByTripState;

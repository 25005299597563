import React, { Suspense } from "react";
import { CellValue } from "react-table";
import {
  Text,
  Box,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from "dayjs";
// import { FiEdit } from 'react-icons/fi';
import { PaginateDocument } from "src/types/PaginateDocument";
import { TokenHistory } from "src/api/transaction";
import { formatStringToNumber } from "src/utils/utils";
import { DATE_TIME_FORMAT } from "src/constants/date";
import Table from "../../components/Table";

interface TransactionTableData {
  data: PaginateDocument<TokenHistory> | null | undefined,
}

function ReviewTable({ data }: TransactionTableData) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Created at",
        accessor: "created_at",
        Cell: ({ value }: CellValue) => <Text>{dayjs(value).format(DATE_TIME_FORMAT)}</Text>,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }: CellValue) => (
          <Text color="green">
            +
            { formatStringToNumber(value) }
          </Text>

        ),
      },
      {
        Header: "Token",
        accessor: "token",
        Cell: ({ value }: CellValue) => (
          <Text>
            {value}
          </Text>

        ),
      },
      {
        Header: "Created by",
        accessor: "created_by",
        Cell: ({ row }: CellValue) => (

          <Box>
            <Link
              as={RouterLink}
              to={`/user/${row.original.user_id}`}
              fontWeight="semibold"
              color="blue.300"
            >
              <Text mb="4px">
                {row.original.full_name}
                {' '}
                (
                {row.original.email}
                )
              </Text>
            </Link>

          </Box>

        ),
      },

    ],
    [],
  );
  return (
    <Table columns={columns} data={data?.items || []} />

  );
}

function ReviewTableWrapper({ data }: TransactionTableData) {
  return (
    <Suspense fallback="loading...">
      <ReviewTable data={data} />
    </Suspense>
  );
}

export default ReviewTableWrapper;

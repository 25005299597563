import {
  HStack,
  Text,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Suspense } from "react";
import { ACCESS_TOKEN } from "src/constants/localStorage";
import { useCurrentUser } from "src/api/user";
import { useNavigate } from "react-router-dom";

function NavUserInfo() {
  const { data: user } = useCurrentUser();
  const navigate = useNavigate();

  const signOut = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    navigate('/login');
  };

  return (
    <HStack>
      <Text fontSize="sm" fontWeight="semibold">
        {user?.email}
      </Text>
      <Menu>
        <MenuButton>
          <Avatar size="sm" name={user?.fullName} />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={signOut}>Đăng xuất</MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
}

function NavUserInfoWrapper() {
  return (
    <Suspense fallback="loading...">
      <NavUserInfo />
    </Suspense>
  );
}

export default NavUserInfoWrapper;

import React, { Suspense } from "react";
import { CellValue } from "react-table";
import {
  Text,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
// import { FiEdit } from 'react-icons/fi';
import { TransactionTypeReportDto } from "src/api/transaction";
import { formatStringToNumber } from "src/utils/utils";
import { TRAN_TYPE_OBJ, TRAN_FLOW } from "src/constants/transaction";
import Table from "../../components/Table";

interface TransactionTypeReportProps {
  data: TransactionTypeReportDto[] | null | undefined,
}

function TransactionTypeReport({ data }: TransactionTypeReportProps) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Category",
        accessor: "trans_type",
        Cell: ({ value }: CellValue) => (
          <Text mb="4px">
            {TRAN_TYPE_OBJ[value]}
          </Text>

        ),
      },
      {
        Header: "Total transactions",
        accessor: "total_transaction",
        Cell: ({ value }: CellValue) => (
          <Text>
            {formatStringToNumber(value)}
          </Text>

        ),
      },
      {
        Header: "Total token",
        accessor: "total_token",
        Cell: ({ value }: CellValue) => (
          <Text>
            {formatStringToNumber(value)}
          </Text>

        ),
      },
      {
        Header: "Token",
        accessor: "token",
        Cell: ({ value }: CellValue) => (
          <Text>
            {value}
          </Text>
        ),
      },
      {
        Header: "Transactions",
        accessor: "id",
        Cell: ({ row }: CellValue) => (
          <Link
            as={RouterLink}
            to={`/${row.original.trans_flow === TRAN_FLOW.add ? 'transaction-earn' : 'transaction-spend'}?trans_type=${row.original.trans_type}`}
            fontWeight="semibold"
            color="blue.300"
          >
            View transactions
          </Link>

        ),
      },
    ],
    [],
  );
  return (
    <Table columns={columns} data={data || []} />

  );
}

function TransactionTypeReportWrapper({ data }: TransactionTypeReportProps) {
  return (
    <Suspense fallback="loading...">
      <TransactionTypeReport data={data} />
    </Suspense>
  );
}

export default TransactionTypeReportWrapper;

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useDisclosure,
  PopoverFooter,
  ButtonGroup,
  RadioGroup,
  SimpleGrid,
  Radio,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { DateKey } from "src/types/DateKey";
import { Sort } from "src/types/Sort";

const OPTIONS = [
  {
    orderBy: DateKey.CreatedAt,
    sort: Sort.Desc,
    text: "Newest ",
  },
  {
    orderBy: DateKey.CreatedAt,
    sort: Sort.Asc,
    text: "Oldest ",
  },
];

function SortByCreatedTime() {
  // const initialFocusRef = React.useRef();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();

  const orderBy = searchParams.get("orderBy");
  const sort = searchParams.get("sort");

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const setOption = (value: string) => {
    setSelectedIndex(+(value));
    searchParams.set("orderBy", OPTIONS[+(value)].orderBy);
    searchParams.set("sort", OPTIONS[+(value)].sort);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (orderBy && sort) {
      const currentSelectedIndex = OPTIONS.findIndex(
        (option) => option.orderBy === orderBy && option.sort === sort,
      );
      setSelectedIndex(currentSelectedIndex);
    }
  }, []);

  return (
    <Popover
      onClose={onClose}
      isOpen={isOpen}
      // initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={selectedIndex !== -1 ? "blue.500" : undefined}
          variant="ghost"
          onClick={onOpen}
        >
          {selectedIndex !== -1 ? OPTIONS[selectedIndex].text : "Sort"}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>Sort by </PopoverHeader>
        <PopoverBody>
          <RadioGroup onChange={setOption} value={selectedIndex}>
            <SimpleGrid borderWidth="1px" columns={1} border="none">
              {OPTIONS.map((option, index) => (
                <Radio
                  size="sm"
                  cursor="pointer"
                  my={2}
                  value={index}
                  key={option.sort}
                >
                  {option.text}
                </Radio>
              ))}
            </SimpleGrid>
          </RadioGroup>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={() => {
                setSelectedIndex(-1);
                searchParams.delete("orderBy");
                searchParams.delete("sort");
                setSearchParams(searchParams);
                onClose();
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

export default SortByCreatedTime;

import React from "react";
import { Form } from "react-final-form";
import { Stack } from "@chakra-ui/react";
import LabeledTextField from "../../components/LabeledTextField";
import { UpdateItemDto, useItemCategory, ItemCategory } from "../../api/item";
import LabeledSelectField from "../../components/LabeledSelectField";

interface ItemFormProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: UpdateItemDto;
}

function ItemForm({ onSubmit, formId, initialValues }: ItemFormProps) {
  const { data: dataItemCategory } = useItemCategory();
  const itemCategoryOptions = dataItemCategory.map((itemCategory:ItemCategory) => ({
    value: itemCategory.id,
    label: itemCategory.name,
  }));
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <LabeledTextField name="name" label="Name" required />
            <LabeledTextField name="icon" label="Icon" />
            <LabeledTextField name="price" label="Price" type="number" required />
            <LabeledTextField name="efficiency" label="Efficiency" type="number" required />
            <LabeledTextField name="distance" label="Distance" type="number" required />
            <LabeledTextField name="durability" label="Durability" type="number" required />
            <LabeledTextField name="luck" label="Luck" type="number" required />
            <LabeledSelectField options={itemCategoryOptions} name="item_category_id" label="Item category" />
          </Stack>
        </form>
      )}
    />
  );
}

export default ItemForm;

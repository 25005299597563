import {
  Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
} from "@chakra-ui/modal";
import {
  Box, Button, Text, useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useCountries } from "src/api/country";
import { useUpdateHotelCountry } from "src/api/hotel";
import BoxImageConfig from "src/components/Image/BoxImageConfig";
import LabeledSelectField from "src/components/LabeledSelectField";
import LabeledTextField from "src/components/LabeledTextField";
import { ICountry } from "src/interfaces/country";

interface HotelCountryModalProps {
  isOpen: boolean;
  onClose: () => void;
  addNew: boolean;
  editingData?: {
    id: number;
    countryCode?: string;
    countryName?:string;
    imgCover?: string;
  }
}

const FORM_ID = 'hotel_country';
function HotelCountryModal({
  isOpen, onClose, editingData, addNew = true,
}: HotelCountryModalProps) {
  const { data: countries } = useCountries();
  const countryOptions: any = countries?.map((country: ICountry) => ({
    value: country.country_code,
    label: country.country_name,
  }));
  let initialValues: any = {
    country_code: editingData?.countryCode || '',
  };
  const [countryName, setCountryName] = useState<any>(editingData?.countryName || '');
  const [imgCover, setImgCover] = useState<any>(editingData?.imgCover || '');
  const onCountrySelected = (code:string) => {
    const country = countries?.find((c) => c.country_code === code);
    setCountryName(country?.country_name || '');
    setImgCover(country?.img_cover || '');
  };
  const resetForm = () => {
    initialValues = {};
    setCountryName(null);
    setImgCover(null);
  };
  const updateHotelCountryMutation = useUpdateHotelCountry();
  const toast = useToast({ position: 'top' });
  const onSubmit = async (values: any) => {
    const data = { country_code: values.country_code, img_cover: imgCover };
    try {
      await updateHotelCountryMutation.mutateAsync(data);
      resetForm();
      onClose();
    } catch (e) {
      toast({ status: "error", description: "Can't saved config. Please try again later" });
    }
  };
  useEffect(() => {
    setCountryName(editingData?.countryName);
    setImgCover(editingData?.imgCover);
  }, [editingData]);

  const onCloseModal = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Config Hotel Country</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form id={FORM_ID} onSubmit={handleSubmit}>
                {addNew && (
                  <LabeledSelectField
                    options={countryOptions}
                    name="country_code"
                    onValueChange={onCountrySelected}
                    label="Select Country"
                  />
                )}
                {!addNew
                && <LabeledTextField name="country_code" disabled label="Country Code" />}
                <Text mt="10px">Country Name</Text>
                <Box borderRadius="8px" p="8px" backgroundColor="rgba(255, 255, 255, 0.06)" mt="10px">
                  <Text minHeight="22px">{countryName}</Text>
                </Box>
              </form>
            )}
          />
          <Box mt="15px">
            <BoxImageConfig
              imageSrc={imgCover}
              title="Cover"
              id="hotel_country_cover"
              willRefreshImage
              onImageChanged={(url: string) => { setImgCover(url); }}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>Cancel</Button>
          <Button type="submit" colorScheme="blue" form={FORM_ID}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default HotelCountryModal;

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Button, Grid, GridItem, HStack, Text,
} from "@chakra-ui/react";
import {
  GoogleMap,
  Marker,
  Polyline,
  useLoadScript,
} from "@react-google-maps/api";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { CellValue, Column } from "react-table";
import { Point, TripDocument, useTripPoints } from "src/api/trip";
import Table from "src/components/Table";
import { calcCrow } from "src/utils/cal";

interface TripMapModalProps {
  isOpen: boolean;
  onClose: () => void;
  trip: TripDocument;
}
const containerStyle = {
  width: "100%",
  height: "100%",
};

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};
function TripMapModal({ isOpen, onClose, trip }: TripMapModalProps) {
  const { data: allPoints } = useTripPoints(trip.id);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_MAP_URL || "",
    libraries: ["places"],
  });

  const arrPoint = useMemo(
    () => {
      try {
        const data = allPoints;
        data.forEach((e, index, arr) => {
          if (index > 0) {
            const prev = arr[index - 1];
            // eslint-disable-next-line no-param-reassign
            arr[index].distance = Math.round(calcCrow(
              prev.latitude,
              prev.longitude,
              e.latitude,
              e.longitude,
            ) * 1000);
            // eslint-disable-next-line no-param-reassign
            arr[index].time = dayjs(e.timestamp).diff(dayjs(prev.timestamp), 'second');
          }
        });
        return data;
        // eslint-disable-next-line no-empty
      } catch (error) { }
      return [];
    },
    [trip],
  );
  const path = useMemo(
    () => arrPoint.map((point: Point) => ({
      lat: point.latitude,
      lng: point.longitude,
      bad: point.bad_point,
    })),
    [trip],
  );
  const badPoint = useMemo(
    () => arrPoint.filter((point: Point) => (point.bad_point)).length,
    [trip],
  );
  const [index, setIndex] = React.useState(0);
  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: '#',
        id: 'index',
        accessor: (_row: any, i: number) => (
          <Button onClick={() => setIndex(i)} disabled={i === index} variant={i === index ? "solid" : "outline"} colorScheme={i === index ? "green" : "gray"}>
            {i + 1}
          </Button>
        ),
      },
      {
        Header: "Lat/Long",
        accessor: "",
        Cell: ({ row }: any) => (
          <Text>
            {row.original.latitude}
            <br />
            {row.original.longitude}
          </Text>
        ),
      },

      {
        Header: "Distance",
        accessor: "distance",
        Cell: ({ value }: CellValue) => (
          <Text>
            {value || 0}
            m
          </Text>
        ),
      },

      {
        Header: "Time(s)",
        accessor: "time",
        Cell: ({ value }: CellValue) => (
          <Text>
            {value || 0}
            s
          </Text>
        ),
      },
      {
        Header: "Recorded at",
        accessor: "timestamp",
        Cell: ({ value }: CellValue) => <Text>{dayjs(value).format("hh:mm:ss")}</Text>,
      },
    ], // eslint-disable-next-line
    [index]
  );
  const setRowStyle = (row) => {
    let rowStyle = {};
    if (row.original.bad_point) {
      rowStyle = {
        color: '#E53E3E',
      };
    }
    return rowStyle;
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => {
        setIndex(0);
        onClose();
      }}
      size="full"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Trip ID:
          {' '}
          {trip.id}
          <HStack bg="gray.700" px={3} borderRadius="md" mr={2}>
            <Text fontWeight="bold" fontSize="sm">
              Available point:
            </Text>
            <Text color="green.200">{arrPoint.length - badPoint}</Text>
            <Text fontWeight="bold" fontSize="sm" ml={2}>
              Bad point:
            </Text>
            <Text color="red.200">{badPoint}</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="2fr 1fr" height="90vh" gap={4}>
            <GridItem colSpan={1} bg="">
              {!loadError && isLoaded && (
                <GoogleMap
                  options={{}}
                  mapContainerStyle={containerStyle}
                  center={path[0]}
                  zoom={17}
                >
                  <Polyline
                    // onLoad={onLoad}
                    path={path}
                    options={options}
                  />
                  <Marker
                    position={path[index]}
                  />
                  {path.map((point) => ( // eslint-disable-next-line
                    <Marker
                      position={point}
                      icon={{
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 4,
                        fillColor: point.bad ? "red" : "gold",
                        fillOpacity: 0.9,
                        strokeColor: "black",
                        strokeWeight: 1,
                      }}
                    />
                  ))}

                </GoogleMap>
              )}
            </GridItem>
            <GridItem colSpan={1} overflow="scroll">
              <Table columns={columns} data={arrPoint || []} setRowStyle={setRowStyle} />
            </GridItem>
          </Grid>
        </ModalBody>

      </ModalContent>
    </Modal>
  );
}

export default TripMapModal;

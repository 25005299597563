import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, useToast } from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import ItemForm from "./ItemForm";
import {
  CreateItemDto,
  createItem,
  itemKeys,

} from "../../api/item";

const FORM_ID = "add-item";

  interface ItemModalAddProps {
    isOpen: boolean;
    onClose: () => void;
  }

function ItemModalAdd({ isOpen, onClose }: ItemModalAddProps) {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  const onSubmit = async (values: CreateItemDto) => {
    try {
      await createItem(values);
      onClose();
      toast({ status: "success", description: "Add " });
      queryClient.invalidateQueries(itemKeys.all);
    } catch (error) {
      toast({ status: "error", description: "Error" });
    }
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ItemForm onSubmit={onSubmit} formId={FORM_ID} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ItemModalAdd;

import React, { Suspense } from "react";
import { CellValue } from "react-table";
import {
  Text, Flex,
  Box,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
// import { FiEdit } from 'react-icons/fi';
import { PaginateDocument } from "src/types/PaginateDocument";
import { TransactionDocument } from "src/api/transaction";
import { formatStringToNumber } from "src/utils/utils";
import Table from "../../components/Table";

interface TransactionTableData {
  data: PaginateDocument<TransactionDocument> | null | undefined,
}

function TopUserTransactions({ data }: TransactionTableData) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Full name",
        accessor: "full_name",
        Cell: ({ value, row }: CellValue) => (
          <Box>
            <Link
              as={RouterLink}
              to={`/user/${row.original.user_id}`}
              fontWeight="semibold"
              color="blue.300"
            >
              <Text mb="4px">
                {value}
              </Text>
            </Link>

          </Box>

        ),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value, row }: CellValue) => (
          <Box>
            <Link
              as={RouterLink}
              to={`/user/${row.original.user_id}`}
              fontWeight="semibold"
              color="blue.300"
            >
              {value}
            </Link>

          </Box>

        ),
      },
      {
        Header: "Amount",
        accessor: "total",
        Cell: ({ value }: CellValue) => (
          <Flex alignItems="center">
            <Text color={value > 0 ? 'green' : 'red.300'}>
              {value > 0 && '+'}
              {' '}
              {formatStringToNumber(value)}
            </Text>
          </Flex>

        ),
      },

    ],
    [],
  );
  return (
    <Table columns={columns} data={data?.items || []} />

  );
}

function TopUserTransactionsWrapper({ data }: TransactionTableData) {
  return (
    <Suspense fallback="loading...">
      <TopUserTransactions data={data} />
    </Suspense>
  );
}

export default TopUserTransactionsWrapper;

import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Image,
  Box,
  Tbody,
} from '@chakra-ui/react';
import { useItem } from "../../api/item";

function ItemDetail() {
  const { id } = useParams();
  const { data } = useItem(id || '');
  return (
    <>
      <h1>ItemDetail</h1>
      <Box overflow="auto" flex={1} px={5}>
        <Table variant="simple" colorScheme="gray" size="sm" bg={useColorModeValue("white", "gray.800")} marginTop="10px">
          <Tbody>
            <Tr>
              <Th>Name</Th>
              <Td>{data?.name}</Td>
            </Tr>
            <Tr>
              <Th>Price</Th>
              <Td>{data?.price}</Td>
            </Tr>
            <Tr>
              <Th>Luck</Th>
              <Td>{data?.luck}</Td>
            </Tr>
            <Tr>
              <Th>Distance</Th>
              <Td>{data?.distance}</Td>
            </Tr>
            <Tr>
              <Th>Durability</Th>
              <Td>{data?.durability}</Td>
            </Tr>
            <Tr>
              <Th>Efficiency</Th>
              <Td>{data?.efficiency}</Td>
            </Tr>
            <Tr>
              <Th>Created at</Th>
              <Td>{data?.created_at}</Td>
            </Tr>
            <Tr>
              <Th>Updated at</Th>
              <Td>{data?.updated_at}</Td>
            </Tr>
            <Tr>
              <Th rowSpan={2} borderRight="1px solid rgba(255, 255, 255, 0.16);">Item category</Th>
              <Td>{data?.item_category?.name}</Td>
            </Tr>
            <Tr>
              <Td>
                <Image src={data?.item_category?.icon} alt="icon" />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </>
  );
}

function ItemTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <ItemDetail />
    </Suspense>
  );
}

export default ItemTableWrapper;

import React, { Suspense } from "react";
import useSearchParams from "../../utils/useSearchParams";
import { useItems } from "../../api/item";
import FilterByPage from "../../components/FilterByPage";

function ItemPaging() {
  const { params } = useSearchParams();
  const { data } = useItems(params);
  return (
    <FilterByPage totalDocs={data?.meta?.totalItems} totalPages={data?.meta?.totalPages} />
  );
}

function ItemPagingWrapper() {
  return (
    <Suspense fallback="">
      <ItemPaging />
    </Suspense>
  );
}

export default ItemPagingWrapper;

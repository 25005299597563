import { Suspense, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { VStack } from "@chakra-ui/react";
import isEmpty from "lodash/isEmpty";
import LabeledTextField from "../../components/LabeledTextField";
import LabeledTextareaField from "../../components/LabelTextareaField";
import {
  UpdateSpotDto, City, useSpotTypes,
} from "../../api/spot";
import { useCountries, getCities } from "../../api/country";
import LabeledSelectField from "../../components/LabeledSelectField";
import LabeledSelectSpotType from "../../components/LabeledSelectSpotType";
import { ICountry } from "../../interfaces/country";

interface SpotFormProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: UpdateSpotDto;
}

function SpotForm({
  onSubmit, formId, initialValues,
}: SpotFormProps) {
  const [countryCode, setCountryCode] = useState<string>(
    initialValues?.country || "",
  );
  const [dataCities, setDataCities] = useState([]);
  const { data: dataCountries } = useCountries();
  const countryOptions: any = dataCountries?.map((country: ICountry) => ({
    value: country.country_code,
    label: country.country_name,
  }));
  const cityOptions = dataCities
    ? dataCities.map((city: City) => ({
      value: city.city,
      label: city.city,
    }))
    : [];
  const { data: spotTypes } = useSpotTypes({ limit: 1000 });

  useEffect(() => {
    const fetchCities = async () => {
      const data = await getCities(countryCode);
      setDataCities(data);
    };

    if (countryCode) {
      fetchCities();
    }
  }, [countryCode]);

  return (
    <Form
      initialValues={initialValues}
      mutators={{
        setBonusToken: (args, state, utils) => {
          const { type } = state.formState.values as any;
          const spotType = spotTypes?.items.find((e) => e.type_code === type);
          utils.changeValue(state, 'bonus_token', () => spotType?.bonus_token);
        },
      }}
      onSubmit={onSubmit}
      render={({ form, handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <VStack spacing={4} direction="row">
            <LabeledTextField name="name" label="Name" required />

            <LabeledTextField
              // disabled
              name="lat"
              label="Latitude"
              type="number"
              required
            />
            <LabeledTextField
              // disabled
              name="long"
              label="Longitude"
              type="number"
              required
            />
            <LabeledSelectSpotType
              name="type"
              label="Select type"
              onValueChange={form.mutators.setBonusToken}
            />
            <LabeledTextField
              name="bonus_token"
              label="Bonus token"
              type="number"
              defaultValue={0}
              required
            />
            <LabeledSelectField
              options={countryOptions}
              name="country"
              label="Select country"
              onValueChange={(e: string) => {
                setCountryCode(e);
                // refetchCities();
              }}
            />
            <LabeledSelectField
              options={cityOptions}
              disabled={!countryCode || isEmpty(cityOptions)}
              name="city"
              label="Select city"
            />
            <LabeledTextareaField name="description" label="Description" />
          </VStack>
        </form>
      )}
    />
  );
}

function SpotTableWrapper({
  onSubmit,
  formId,
  initialValues,
}: SpotFormProps) {
  return (
    <Suspense fallback="loading...">
      <SpotForm
        onSubmit={onSubmit}
        formId={formId}
        initialValues={initialValues}
      />
    </Suspense>
  );
}

export default SpotTableWrapper;

import { Icon, IconButton, useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { FiEdit } from 'react-icons/fi';
import { IMintConfig } from 'src/interfaces/mint-config';
import { getMintConfig, useMintConfigs } from '../../../api/item';
import Table from "../../../components/Table";
import MintConfigModalEdit from './MintConfigModalEdit';

function MintConfigTable() {
  const { isOpen: isEditOpen, onClose: onEditClose, onOpen: onEditOpen } = useDisclosure();
  const { data } = useMintConfigs();
  const [editingData, setEditingData] = React.useState<IMintConfig>();
  const editConfig = async (id: number) => {
    const responseData = await getMintConfig(id);
    setEditingData(responseData);
    onEditOpen();
  };

  const colums = React.useMemo(() => [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Parent 1",
      accessor: "first_item_category_name",
    },
    {
      Header: "Parent 2",
      accessor: "second_item_category_name",
    },
    {
      Header: "Action",
      Cell: ({ value, row }: any) => (
        <IconButton
          icon={<Icon boxSize={4} as={FiEdit} />}
          variant="ghost"
          colorScheme="blue"
          aria-label="edit"
          onClick={() => {
            editConfig(row.original.id);
          }}
        />
      ),
    },
  ], []);
  return (
    <>
      <MintConfigModalEdit isOpen={isEditOpen} onClose={onEditClose} editingData={editingData} />
      <Table columns={colums} data={data ?? []} />
    </>
  );
}

function MintConfigTableWrapper() {
  return (
    <React.Suspense fallback="loading...">
      <MintConfigTable />
    </React.Suspense>
  );
}

export default MintConfigTableWrapper;

import { Suspense, useMemo } from "react";
import {
  Box,
  Flex,
  HStack,
  Heading,
  Spacer,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  useTransactions,
  SEARCH_TYPE_OPTIONS, SEARCH_TYPE_PLACEHOLDER,
} from "src/api/transaction";
import FilterSingle from "src/components/FilterSingle";
import FilterMultiple from "src/components/FilterMultiple";
import FilterBySearchKeyAndSearchType from "src/components/FilterBySearchKeyAndSearchType";
import FilterByPage from "src/components/FilterByPage";
import FilterByRangerDate from "src/components/FilterByRangerDate";
import {
  EARN_TRANSACTION_OPTIONS, SPEND_TRANSACTION_OPTIONS, TOKENS, TRANSACTION_STATE_OBJ,
  TRANSACTION_STATE_OPTIONS, TRANSACTION_TYPE_OBJ, TRANSACTION_TYPE_OPTIONS, TRAN_TYPE_OBJ,
} from "src/constants/transaction";
import useSearchParams from "../../utils/useSearchParams";
import SortByCreatedTime from "../../components/SortByCreatedTime";
import TransactionTable from "./TransactionTable";
import FilterByPageSize from "../../components/FilterByPageSize";
// import FilterByReviewType from "./components/FilterByReviewType";

interface TransactionsProps {
  type?: string,
  userId?: string
}

function Transactions({ type, userId }: TransactionsProps) {
  const { params } = useSearchParams();

  let transFlow: string = (type === 'earn' ? 'add' : 'sub');

  if (userId) {
    transFlow = (params.trans_flow as string);
  }

  const { data } = useTransactions({
    ...params,
    trans_flow: transFlow,
    user_id: userId,
  });

  const CATEGORIES: any = useMemo(() => {
    if (userId) {
      return [
        ...EARN_TRANSACTION_OPTIONS,
        ...SPEND_TRANSACTION_OPTIONS,
      ];
    }
    return type === 'earn' ? EARN_TRANSACTION_OPTIONS : SPEND_TRANSACTION_OPTIONS;
  }, [userId, type]);

  return (
    <Flex direction="column" p={userId ? 0 : 6} h="full">
      <HStack mb={4} spacing={4}>
        <Heading size="md" textTransform="uppercase" color="gray.400">
          {userId ? 'User transactions' : 'Manage transactions'}
        </Heading>
        <Spacer />

      </HStack>
      <SimpleGrid
        templateRows="auto 1fr"
        borderRadius="md"
        bg="gray.800"
        flex={1}
        overflow="auto"
      >
        <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
          <Flex mb={2}>
            <HStack>
              { !userId
                && (
                <FilterBySearchKeyAndSearchType
                  searchTypes={SEARCH_TYPE_OPTIONS}
                  searchTypePlaceholder={SEARCH_TYPE_PLACEHOLDER}
                />
                )}

              { userId
                && (
                <FilterSingle
                  title="Type"
                  name="trans_flow"
                  options={TRANSACTION_TYPE_OPTIONS}
                  optionsObj={TRANSACTION_TYPE_OBJ}
                />
                )}

              <FilterMultiple
                title="Category"
                name="trans_type"
                options={CATEGORIES}
                optionsObj={TRAN_TYPE_OBJ}
              />
              <FilterByRangerDate />
              <FilterSingle
                title="Status"
                name="trans_state"
                options={TRANSACTION_STATE_OPTIONS}
                optionsObj={TRANSACTION_STATE_OBJ}
              />
              <FilterSingle
                title="Token"
                name="token"
                options={TOKENS}
                optionsObj={{}}
              />
              <SortByCreatedTime />
              {/* <FilterByReviewType /> */}
            </HStack>
            <Spacer />
            <FilterByPageSize />
            <FilterByPage
              totalPages={data?.meta?.totalPages}
              totalDocs={data?.meta?.totalItems}
            />
          </Flex>
        </Box>
        <Box overflow="auto" flex={1} px={5}>
          <TransactionTable data={data} userId={userId} />
        </Box>
      </SimpleGrid>
    </Flex>
  );
}

function TransactionsWrapper(props: TransactionsProps) {
  return (
    <Suspense fallback="">
      <Transactions {...props} />
    </Suspense>
  );
}

export default TransactionsWrapper;

import React, { Suspense, useState } from "react";
import { CellValue, Column } from "react-table";
import {
  Tooltip,
  Text,
  HStack,
  IconButton,
  Icon,
  useDisclosure,
  Link,
} from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link as RouterLink } from "react-router-dom";
import Table from "../../components/Table";
import useSearchParams from "../../utils/useSearchParams";
import ItemModalEdit from "./ItemModalEdit";
import { useItems, ItemDocument } from "../../api/item";
import ItemDelete from "./ItemModalDelete";

function ItemTable() {
  const { params } = useSearchParams();
  const { data } = useItems(params);
  const [editingData, setEditingData] = useState<ItemDocument>();
  const [deleteData, setDeleteData] = useState<string>();
  const { isOpen: isEditOpen, onClose: onEditClose, onOpen: onEditOpen } = useDisclosure();
  const { isOpen: isDeleteOpen, onClose: onDeleteClose, onOpen: onDeleteOpen } = useDisclosure();

  const editItem = (item: ItemDocument) => {
    onEditOpen();
    setEditingData(item);
  };
  const DeleteItem = async (id: string) => {
    onDeleteOpen();
    setDeleteData(id);
  };
  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, row }: any) => (
          <Link
            as={RouterLink}
            to={`/item/${row.original.id}`}
            fontWeight="semibold"
            color="blue.300"
          >
            <HStack>
              <Text>{value}</Text>
            </HStack>
          </Link>
        ),
      },

      {
        Header: "Price",
        accessor: "price",
        Cell: ({ value }: CellValue) => (
          <Text>{value}</Text>
        ),
      },
      {
        Header: "efficiency",
        accessor: "efficiency",
        Cell: ({ value }: CellValue) => (
          <Text>{value}</Text>
        ),
      },
      {
        Header: "Distance",
        accessor: "distance",
        Cell: ({ value }: CellValue) => (
          <Text>{value}</Text>
        ),
      },
      {
        Header: "Durability",
        accessor: "durability",
        Cell: ({ value }: CellValue) => (
          <Text>{value}</Text>
        ),
      },
      {
        Header: "Luck",
        accessor: "luck",
        Cell: ({ value }: CellValue) => (
          <Text>{value}</Text>
        ),
      },
      {
        Header: "Item category id",
        accessor: "item_category_id",
        Cell: ({ value }: CellValue) => (
          <Text>{value}</Text>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ value, row }: CellValue) => (
          <HStack spacing={4}>
            <Tooltip label="Edit">
              <IconButton
                icon={<Icon boxSize={4} as={FiEdit} />}
                variant="ghost"
                colorScheme="blue"
                aria-label="delete"
                onClick={() => editItem(row.original as ItemDocument)}
              />
            </Tooltip>

            <Tooltip label="Delete">
              <IconButton
                icon={<Icon boxSize={4} as={RiDeleteBin6Line} />}
                variant="ghost"
                colorScheme="red"
                aria-label="delete"
                onClick={() => DeleteItem(value)}
              />
            </Tooltip>
          </HStack>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <ItemModalEdit
        isOpen={isEditOpen}
        onClose={onEditClose}
        editingData={editingData as ItemDocument}
      />
      <ItemDelete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        id={deleteData as string}
      />
      <Table columns={columns} data={data?.items || []} />
    </>
  );
}

function ItemTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <ItemTable />
    </Suspense>
  );
}

export default ItemTableWrapper;

import { Suspense, useState } from "react";
import {
  Flex, VStack,
} from "@chakra-ui/react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import { useHotel } from "src/api/hotel";

function Map() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_MAP_URL || "",
    libraries: ["places"],
  });
  const { id = "" } = useParams();
  const { data } = useHotel(id || "");
  const [lat, setLat] = useState(data ? data.latitude : 0);
  const [long, setLong] = useState(data ? data.longitude : 0);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat,
    lng: long,
  };

  return (
    <Flex minH="80vh">
      <VStack flex={6} pb="60%" p={4} pt={0} alignItems="flex-start">

        <Flex width="100%" flex={1}>
          {!loadError && isLoaded && data && (
            <GoogleMap
              onRightClick={(e) => {
                if (e) {
                  setLat(e!.latLng!.lat());
                  setLong(e!.latLng!.lng());
                }
              }}
              options={{}}
              mapContainerStyle={containerStyle}
              center={center}
              zoom={17}
            >
              <Marker
                position={center}
                onClick={() => {
                }}
              />

            </GoogleMap>
          )}
        </Flex>
      </VStack>
    </Flex>
  );
}

function MapWrapper() {
  return (
    <Suspense fallback="loading...">
      <Map />
    </Suspense>
  );
}

export default MapWrapper;

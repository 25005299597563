import { Suspense, useCallback } from "react";
import {
  IconButton,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";

import dayjs from "dayjs";
import { DownloadIcon } from "@chakra-ui/icons";
import useSearchParams from "../../utils/useSearchParams";
import {
  useUserAll, useUserSate,
} from "../../api/user";

function UserDownload() {
  // const { params } = useSearchParams();
  const { params } = useSearchParams();
  params.limit = '100000';
  const { data } = useUserAll(params);
  const { data: userStates } = useUserSate();
  console.log("🚀 ~ file: UserTable.tsx:16 ~ UserTable ~ userStates", userStates);

  const renderStateText = useCallback(
    (state: number) => {
      const label = userStates?.find((d) => d[1] === state);
      return label![0];
    },
    [userStates],
  );
  let csvData = [[]] as any;
  if (data?.items) {
    csvData = data?.items.map((d, index) => {
      const obj = {} as any;
      obj.no = index + 1;

      obj.id = d.id;
      obj.email = d.email;
      obj.role_name = d.role_name;
      obj.email = d.email;
      obj.invitation_code = d.invitation_code;
      obj.name = d.full_name;
      obj.state = renderStateText(d.state);
      obj.created_at = dayjs(d.created_at).format("L");
      return obj;
    });
  }
  return (
    <CSVLink data={csvData} filename="User.csv">
      <IconButton
        mr={8}
        colorScheme="blue"
        aria-label="Search database"
        icon={<DownloadIcon />}
      />
    </CSVLink>
  );
}

function UserDownloadWrapper() {
  return (
    <Suspense fallback="loading...">
      <UserDownload />
    </Suspense>
  );
}

export default UserDownloadWrapper;

import * as React from 'react';
import {
  Box, Flex, Heading, HStack, SimpleGrid, Spacer,
} from '@chakra-ui/react';
import MintConfigTableWrapper from './MintConfigTable';

function MintingConfigList() {
  return (
    <Flex direction="column" p={6} h="full">
      <HStack mb={4} spacing={4}>
        <Heading size="md" textTransform="uppercase" color="gray.400">
          Mint Config
        </Heading>
        <Spacer />
      </HStack>
      <SimpleGrid templateRows="auto 1fr" borderRadius="md" bg="gray.800" flex={1} overflow="auto">
        <Box overflow="auto" flex={1} px={5}>
          <MintConfigTableWrapper />
        </Box>
      </SimpleGrid>
    </Flex>
  );
}

export default MintingConfigList;

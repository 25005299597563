import {
  Suspense, useState, useRef, useEffect,
} from "react";
import {
  Box, Flex, HStack, Text, Button, VStack,
} from "@chakra-ui/react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  InfoWindow,
  Polygon,
} from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import { BsSave } from "react-icons/bs";
import NearByTable from "./NearByTable";
import { useSpot, useSpotNearBy, useUpdateSpotPolygon } from "../../api/spot";

function Map() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_MAP_URL || "",
    libraries: ["places"],
  });
  const { id = "" } = useParams();
  const { data } = useSpot(id || "");
  const { data: nearBy = [] } = useSpotNearBy(id || "");
  const updateSpotPolygon = useUpdateSpotPolygon();
  const [markerId, setMarkerId] = useState(-1);
  const [polygon, setPolygon] = useState([] as any);
  const [newPolygon, setNewPolygon] = useState([] as any);
  useEffect(() => {
    let p = [] as any;
    const currentPoint = {
      lat: data?.latitude,
      lng: data?.longitude,
    };
    if (data?.polygon) {
      p = data?.polygon?.coordinates[0].map((e) => ({
        lat: e[1],
        lng: e[0],
      }));
      if (p.length > 3) {
        p.pop();
      }
    }
    while (p.length < 3) {
      p.push(currentPoint);
    }

    setPolygon(p);
    setNewPolygon(p);
  }, [data]);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const options = {
    fillColor: "lightblue",
    fillOpacity: 0.5,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: true,
    editable: true,
    geodesic: false,
    zIndex: 1,
  };

  const polygonRef: any = useRef(null);

  const onLoad = (polygonVal) => {
    polygonRef.current = polygonVal;
  };

  const onEdit = () => {
    if (polygonRef.current) {
      const polygonVal = [] as any;
      const dynamicName = Object.keys(polygonRef.current.latLngs)[0];
      const arr = polygonRef.current.latLngs[dynamicName][0][dynamicName] as [];
      for (let index = 0; index < arr.length; index += 1) {
        const element = arr[index] as any;
        polygonVal.push({
          lat: element.lat(),
          lng: element.lng(),
        });
        setNewPolygon(polygonVal);
      }
    }
  };

  const handleSavePolygon = () => {
    const dataPolygon = newPolygon.map((p) => ({
      lat: p.lat,
      long: p.lng,
    }));
    dataPolygon.push({
      lat: newPolygon[0].lat,
      long: newPolygon[0].lng,
    });
    updateSpotPolygon.mutate({
      id,
      data: {
        polygon: dataPolygon,
      },
    });
  };

  return (
    <Flex minH="80vh">
      <VStack flex={6} pb="60%" p={4} pl={0} alignItems="flex-start">
        <HStack mb={2} w="100%" justifyContent="space-between">
          <HStack />
          <HStack>
            <Button
              rightIcon={<BsSave />}
              colorScheme="green"
              variant="outline"
              onClick={handleSavePolygon}
            >
              Save
            </Button>
          </HStack>
        </HStack>

        <Flex width="100%" flex={1}>
          {!loadError && isLoaded && data && polygon.length > 0 && (
            <GoogleMap
              options={{}}
              mapContainerStyle={containerStyle}
              center={polygon[0]}
              zoom={17}
            >
              <Polygon
                onLoad={onLoad}
                paths={polygon}
                options={options}
                onDragEnd={onEdit}
                onMouseOut={onEdit}
              />

              {nearBy?.map((spot) => (
                <Marker
                  opacity={markerId === spot?.id ? 0.9 : 0.7}
                  position={{
                    lat: spot.latitude,
                    lng: spot.longitude,
                  }}
                  onClick={() => {
                    setMarkerId(spot?.id);
                  }}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                  }}
                >
                  {markerId === spot?.id && (
                    <InfoWindow>
                      <Box>
                        <Text color="blue.300" fontWeight="bold">
                          {spot.name}
                        </Text>
                        <Text color="blue.300">
                          Lat:
                          {' '}
                          {spot.latitude}
                        </Text>
                        <Text color="blue.300">
                          Long:
                          {' '}
                          {spot.longitude}
                        </Text>
                      </Box>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </GoogleMap>
          )}
        </Flex>
      </VStack>
      <Box flex={4} p={4}>
        <Text fontWeight="bold" mb={4}>
          Near by
        </Text>
        <Box maxHeight={1000} overflow="scroll">
          <NearByTable />
        </Box>
      </Box>
    </Flex>
  );
}

function MapWrapper() {
  return (
    <Suspense fallback="loading...">
      <Map />
    </Suspense>
  );
}

export default MapWrapper;

import { Suspense, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Button,
  Heading,
  Grid,
  GridItem,
  SimpleGrid,
  Text,
  Input,
  FormErrorMessage,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { useHotel, useUpdateHotel } from "src/api/hotel";
import { ICountry } from "src/interfaces/country";
import {

  City,
} from "../../api/spot";
import MapPoint from "./MapPoint";
import { useCountries, getCities } from "../../api/country";

function SpotDetail() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const { id = "" } = useParams();
  const updateHotel = useUpdateHotel();
  const { data: dataCountries } = useCountries();
  const countryOptions = dataCountries?.map((country: ICountry) => ({
    value: country.country_code,
    label: country.country_name,
  }));
  const { data } = useHotel(id || "");
  const [lat, setLat] = useState(data ? data.latitude : 0);
  const [long, setLong] = useState(data ? data.longitude : 0);

  const [countryCode, setCountryCode] = useState<string>(data?.country || "");
  const [dataCities, setDataCities] = useState([]);
  const cityOptions = dataCities
    ? dataCities.map((city: City) => ({
      value: city.city,
      label: city.city,
    }))
    : [];

  useEffect(() => {
    const fetchCities = async () => {
      const cities = await getCities(countryCode);
      setDataCities(cities);
    };

    if (countryCode) {
      fetchCities();
    }
  }, [countryCode]);

  const onUpdate = (values: any) => {
    updateHotel.mutate({
      id,
      data: {
        ...values,
        city: values.city || data?.city,
        description: values.description.replace(/'/g, "\\'"),
      },
    });
  };

  return (
    <Box p={4}>
      <Flex>
        <Heading as="h2" size="lg" flex={1}>
          Hotel detail - ID:
          {' '}
          {id}
        </Heading>
      </Flex>

      <SimpleGrid columns={2} spacing={10} mt={4}>
        <Box bg="gray.800" p={4}>
          <form>
            <Grid
              templateColumns="repeat(2, 1fr)"
              gap={4}
            >
              <GridItem colSpan={2} alignItems="center">
                <Text mb={2}> Name</Text>
                <Input
                  id="name"
                  placeholder="name"
                  required
                  defaultValue={data?.name}
                  {...register("name")}
                  h="40px"
                  borderRadius="6px"
                />
                <FormErrorMessage>
                  {errors.name?.message as any}
                </FormErrorMessage>
              </GridItem>
              <GridItem colSpan={1} alignItems="center">
                <Text mb={2}> Agoda ID</Text>
                <Input
                  id="agoda_id"
                  placeholder="agoda_id"
                  required
                  defaultValue={data?.agoda_id}
                  {...register("agoda_id")}
                  h="40px"
                  borderRadius="6px"
                />
                <FormErrorMessage>
                  {errors.name?.message as any}
                </FormErrorMessage>
              </GridItem>
              <GridItem colSpan={1} alignItems="center">
                <Text mb={2}> Booking.com ID</Text>
                <Input
                  id="booking_id"
                  placeholder="booking_id"
                  required
                  defaultValue={data?.booking_id}
                  {...register("booking_id")}
                  h="40px"
                  borderRadius="6px"
                />
                <FormErrorMessage>
                  {errors.name?.message as any}
                </FormErrorMessage>
              </GridItem>
              <GridItem colSpan={2} alignItems="center">
                <Text mb={2}> Address</Text>
                <Input
                  id="address"
                  placeholder="address"
                  required
                  defaultValue={data?.address}
                  {...register("address")}
                  h="40px"
                  borderRadius="6px"
                />
                <FormErrorMessage>
                  {errors.address?.message as any}
                </FormErrorMessage>
              </GridItem>
              <GridItem colSpan={1}>
                <Text mb={2}>Lat</Text>
                <Input
                  h="40px"
                  id="lat"
                  placeholder="lat"
                  required
                  type="number"
                  value={lat}
                  {...register("lat", {
                    value: lat,
                    onChange: (e) => {
                      setLat(parseFloat(e?.target?.value));
                    },
                  })}
                  borderRadius="6px"
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Text mb={2}>Long</Text>
                <Input
                  id="long"
                  placeholder="long"
                  required
                  value={long}
                  h="40px"
                  type="number"
                  {...register("long", {
                    onChange: (e) => {
                      setLong(parseFloat(e?.target?.value));
                    },
                  })}
                  borderRadius="6px"
                />
              </GridItem>
              <GridItem colSpan={1}>
                <Text mb={2}>Country</Text>
                <Select
                  placeholder="Select option"
                  {...register("country", {
                    onChange: (e) => {
                      setCountryCode(e.target.value);
                    },
                  })}
                  h="40px"
                  bgColor="rgba(255, 255, 255, 0.04)"
                  border="0"
                >
                  {countryOptions?.map((country) => (
                    <option
                      value={country.value}
                      selected={country.value === data?.country}
                      key={country.value}
                    >
                      <Text textTransform="capitalize">{country.label}</Text>
                    </option>
                  ))}
                </Select>
              </GridItem>

              <GridItem colSpan={1}>
                <Text mb={2}>City</Text>
                <Select
                  placeholder="Select option"
                  {...register("city")}
                  bgColor="rgba(255, 255, 255, 0.04)"
                  border="0"
                >
                  {cityOptions?.map((city) => (
                    <option
                      value={city.value}
                      selected={city.value === data?.city}
                      key={`${city.value}-${data?.country}`}
                    >
                      <Text textTransform="capitalize">{city.label}</Text>
                    </option>
                  ))}
                </Select>
              </GridItem>
              <GridItem colSpan={2} rowSpan={2}>
                <Text mb={2}>Description</Text>
                <Textarea
                  id="description"
                  rows={5}
                  variant="filled"
                  defaultValue={data?.description}
                  {...register("description")}
                  borderRadius="6px"
                />
              </GridItem>
              <GridItem colSpan={2} display="flex" justifyContent="flex-end">
                <Button
                  colorScheme="blue"
                  variant="solid"
                  type="submit"
                  name="action"
                  value="reject"
                  mr={4}
                  onClick={handleSubmit(onUpdate)}
                >
                  Update
                </Button>
              </GridItem>

            </Grid>
          </form>
        </Box>
        <MapPoint />

      </SimpleGrid>
    </Box>
  );
}

function ItemTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <SpotDetail />
    </Suspense>
  );
}

export default ItemTableWrapper;

import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "src/config/axios";
import { TimeStamp } from "src/types/TimeStamp";
import { ObjectId } from "src/types/ObjectId";
import { Partial } from "src/types/Partial";
import generateQueryKey from "src/utils/queryKeyFactory";
import { QueryOptionParams } from "src/types/QueryOptionParams";
import { PaginateDocument } from "src/types/PaginateDocument";
import { useToast } from "@chakra-ui/react";
import { DEFAULT_PAGE_SIZE } from "../constants/pagination";

export const SEARCH_TYPE_OPTIONS = [{
  label: 'Name',
  value: 'full_name',
}, {
  label: 'Email',
  value: 'email',
}, {
  label: 'Invitation code',
  value: 'invitation_code',
}];

export const SEARCH_TYPE_PLACEHOLDER = {
  full_name: 'Name',
  email: 'Email',
  invitation_code: 'Invitation code',
};

export interface CreateUserDto {
  fullName: string;
  email: string;
  password: string;
  role_id: number;
}
export interface GetCustomerDto extends Partial<CreateUserDto> {
  balance?: number;
}

export interface UserDocument extends ObjectId, TimeStamp {
  email?: string;
  fullName?: string;
}

export interface UserDetail extends ObjectId, TimeStamp {
  full_name: string;
  email: string;
  gender: number;
  profile_avatar: string;
  ref_code: string;
  role_id: number;
  state: number;
  deleted_at: string;
  invitation_code: string;
  role_name: string;
  wallets: any
}
export const currentUserKey = "current-user";
export const userKeys = generateQueryKey("user");

export interface LoginDto {
  email: string;
  password: string;
}

export const login = async (loginDto: LoginDto) => {
  const { data } = await axios.post("/auth/login", {
    ...loginDto,
  });
  return data;
};

export const getCurrentUser = async () => {
  try {
    const { data } = await axios.get<UserDocument>("/user/info");
    return data;
  } catch (e) {
    return null;
  }
};

export const createUser = async (user: CreateUserDto) => {
  const { data } = await axios.post("/admin/user", user);
  return data;
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });

  return useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.all);
      toast({ status: "success", description: "Update successful!" });
    },
    onError: () => {
      toast({ status: "error", description: "Error!" });
    },
  });
};

export const useCurrentUser = () => useQuery(currentUserKey, getCurrentUser, { retry: 0 });

export const getUser = async (id: string) => {
  const { data } = await axios.get<UserDetail>(`/admin/user/${id}`);
  return data;
};

export const useUser = (id: string) => useQuery(userKeys.detail(id), () => getUser(id), {
  staleTime: Infinity,
});
export const getUserAll = async (
  params?: QueryOptionParams & GetCustomerDto,
) => {
  try {
    const { data } = await axios.get<PaginateDocument<UserDetail>>(
      "/admin/user",
      {
        params: { page: 1, limit: DEFAULT_PAGE_SIZE, ...params },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useUserAll = (params?: QueryOptionParams & GetCustomerDto) => (
  useQuery(userKeys.list(params), () => getUserAll(params))
);

export const getUserState = async () => {
  const { data } = await axios.get<Object>(`/app-config/user-state`);
  if (data) return Object.entries(data);
  return [];
};

export const useUserSate = () => useQuery(`user-state`, () => getUserState(), {
  staleTime: Infinity,
});

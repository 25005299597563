import { Suspense } from "react";
import { Form } from "react-final-form";
import { VStack } from "@chakra-ui/react";
import LabeledTextField from "../../components/LabeledTextField";
import { UpdateSpotDto } from "../../api/spot";
import LabeledSelectField from "../../components/LabeledSelectField";

interface SpotTypeFormProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (e: any) => void;
  formId: string;
  initialValues?: UpdateSpotDto;
  isEdit: boolean;
}

function SpotTypeForm({
  onSubmit,
  formId,
  initialValues,
  isEdit,
}: SpotTypeFormProps) {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id={formId} onSubmit={handleSubmit}>
          <VStack spacing={4} direction="row">
            <LabeledTextField name="name" label="Name" required />
            <LabeledTextField
              name="type_code"
              label="Code"
              required
              disabled={isEdit}
            />
            <LabeledTextField
              name="check_in_token"
              label="Add token"
              type="number"
              min={0}
              required
            />
            <LabeledTextField
              name="bonus_token"
              label="Bonus token"
              type="number"
              min={0}
              required
            />
            <LabeledSelectField
              name="check_in_earn"
              label="Allow CheckIn"
              required
              options={[
                { value: false, label: "Not Allow" },
                { value: true, label: "Allow" },
              ]}
            />
          </VStack>
        </form>
      )}
    />
  );
}

function SpotTableWrapper({
  onSubmit,
  formId,
  initialValues,
  isEdit,
}: SpotTypeFormProps) {
  return (
    <Suspense fallback="loading...">
      <SpotTypeForm
        onSubmit={onSubmit}
        formId={formId}
        initialValues={initialValues}
        isEdit={isEdit}
      />
    </Suspense>
  );
}

export default SpotTableWrapper;

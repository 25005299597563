import { Suspense, useState } from "react";
import {
  Box, Flex, HStack, Text, Button, VStack,
} from "@chakra-ui/react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import { BiReset, BiSync } from "react-icons/bi";
import NearByTable from "./NearByTable";
import { useSpot, useSpotNearBy } from "../../api/spot";

interface MapProps {
  // eslint-disable-next-line no-unused-vars
  handleUpdateLatLong: (lat: number, long: number) => void;
}

function Map({ handleUpdateLatLong }: MapProps) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_MAP_URL || "",
    libraries: ["places"],
  });
  const { id = "" } = useParams();
  const { data } = useSpot(id || "");
  const { data: nearBy = [] } = useSpotNearBy(id || "");
  const [markerId, setMarkerId] = useState(-1);
  const [lat, setLat] = useState(data ? data.latitude : 0);
  const [long, setLong] = useState(data ? data.longitude : 0);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat,
    lng: long,
  };

  return (
    <Flex minH="80vh">
      <VStack flex={6} pb="60%" p={4} pl={0} alignItems="flex-start">
        <HStack mb={2} w="100%" justifyContent="space-between">
          <HStack>
            <Text color="blue.300">
              Coordinates:
              {' '}
              {lat}
              ,
              {' '}
              {long}
              <Text
                fontStyle="italic"
                justifySelf="left"
                color="gray.300"
                fontSize={12}
              >
                Right click to change main Marker&apos;s location
              </Text>
            </Text>
          </HStack>
          <HStack>
            <Button
              rightIcon={<BiSync />}
              colorScheme="blue"
              variant="outline"
              onClick={() => {
                handleUpdateLatLong(lat, long);
              }}
            >
              Update
            </Button>
            <Button
              rightIcon={<BiReset />}
              colorScheme="red"
              variant="outline"
              onClick={() => {
                setLat(data!.latitude);
                setLong(data!.longitude);
                handleUpdateLatLong(data!.latitude, data!.longitude);
              }}
            >
              Reset
            </Button>
          </HStack>
        </HStack>

        <Flex width="100%" flex={1}>
          {!loadError && isLoaded && data && (
            <GoogleMap
              onRightClick={(e) => {
                if (e) {
                  setLat(e!.latLng!.lat());
                  setLong(e!.latLng!.lng());
                }
              }}
              options={{}}
              mapContainerStyle={containerStyle}
              center={center}
              zoom={17}
            >
              <Marker
                position={center}
                onClick={() => {
                  setMarkerId(data!.id);
                }}
              />

              {nearBy?.map((spot) => (
                <Marker
                  opacity={markerId === spot?.id ? 0.9 : 0.7}
                  position={{
                    lat: spot.latitude,
                    lng: spot.longitude,
                  }}
                  onClick={() => {
                    setMarkerId(spot?.id);
                  }}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                  }}
                >
                  {markerId === spot?.id && (
                    <InfoWindow>
                      <Box>
                        <Text color="blue.300" fontWeight="bold">
                          {spot.name}
                        </Text>
                        <Text color="blue.300">
                          Lat:
                          {' '}
                          {spot.latitude}
                        </Text>
                        <Text color="blue.300">
                          Long:
                          {' '}
                          {spot.longitude}
                        </Text>
                      </Box>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </GoogleMap>
          )}
        </Flex>
      </VStack>
      <Box flex={4} p={4}>
        <Text fontWeight="bold" mb={4}>
          Near by
        </Text>
        <Box maxHeight={1000} overflow="scroll">
          <NearByTable />
        </Box>
      </Box>
    </Flex>
  );
}

function MapWrapper({ handleUpdateLatLong }: MapProps) {
  return (
    <Suspense fallback="loading...">
      <Map handleUpdateLatLong={handleUpdateLatLong} />
    </Suspense>
  );
}

export default MapWrapper;

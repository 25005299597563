import React, { Suspense } from "react";
import useSearchParams from "../../utils/useSearchParams";
import { useSpotTypes } from "../../api/spot";
import FilterByPage from "../../components/FilterByPage";

function SpotPaging() {
  const { params } = useSearchParams();
  const { data } = useSpotTypes(params);

  return (
    <FilterByPage
      totalDocs={data?.meta?.totalItems}
      totalPages={data?.meta?.totalPages}
    />
  );
}

function SpotPagingWrapper() {
  return (
    <Suspense fallback="">
      <SpotPaging />
    </Suspense>
  );
}

export default SpotPagingWrapper;

import Pagination from "src/components/Pagination";
import {
  HStack, Text, Center, Badge,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";

interface FilterByPageProps {
  totalPages: number | undefined,
  totalDocs: number | undefined
}

function FilterByPage({ totalPages = 2, totalDocs = 10 }: FilterByPageProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;

  return (
    <HStack spacing={4}>
      <Pagination
        onPageChange={(e) => {
          searchParams.set("page", `${e.selected + 1}`);
          setSearchParams(searchParams);
        }}
        pageCount={totalPages}
        forcePage={+page - 1}
      />
      <Center borderRadius="md" w={8} h={8} bg="gray.900">
        <Badge variant="outline" colorScheme="blue" fontSize="sm" fontWeight="600">
          {totalDocs}
        </Badge>
      </Center>
    </HStack>
  );
}

export default FilterByPage;

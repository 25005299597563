import React, { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";
import {
  Input,
  InputGroup,
  InputRightElement,
  CloseButton,
} from "@chakra-ui/react";

interface FilterBySearchKeyProps {
  placeholder?: string;
}

function FilterBySearchKey({ placeholder = "Search" }: FilterBySearchKeyProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [value, setValue] = useState(searchParams.get("search") || "");
  const firstUpdate = useRef(true);
  const debounced = React.useCallback(

    debounce((v) => {
      searchParams.set("search", v);
      setSearchParams(searchParams);
    }, 600),
    [],
  );
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    debounced(value);
  }, [value, debounced]);

  return (
    <InputGroup w="xs">
      <Input
        autoFocus
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
        type="text"
        placeholder={placeholder}
        borderRadius="6px"
      />
      {value && (
        <InputRightElement>
          <CloseButton
            onClick={() => {
              setValue("");
            }}
            size="sm"
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}

export default FilterBySearchKey;

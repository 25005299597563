import React, { useEffect, useState } from 'react';
import {
  Box,
  Button, Flex, GridItem, Icon, IconButton, Image, Text, Tooltip, useDisclosure,
} from '@chakra-ui/react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useParams } from "react-router-dom";
import { Photo, useReviewImages, useUpdateImagesOrder } from 'src/api/review';
import { CiEdit } from 'react-icons/ci';
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import ModalEditPhoto from './ModalEditPhoto';

const SortableGridItem: any = SortableElement(({ value }: { index: number; value: Photo }) => (
  <GridItem
    key={`item-${value.id}`}
    boxShadow="md"
    p={4}
    borderWidth={1}
    borderColor="gray.600"
    w="150px"
    _hover={{
      cursor: "grab",
    }}
    height="150px"
  >
    <Image src={value.image} objectFit="contain" height="100%" margin="auto" verticalAlign="middle" />
  </GridItem>
));

const SortableGrid: any = SortableContainer(({ children }: { children: React.ReactNode }) => (
  <Flex flexWrap="wrap" gap={6} mt={4}>
    {children}
  </Flex>
));
const MODE = {
  EDIT: 'edit',
  ORDER: 'order',
};

export default function MySortableGrid() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState<Photo>();
  const { id = "" } = useParams();
  const defaultImages = React.useRef<Photo[]>([]);
  const updateImagesOrder = useUpdateImagesOrder(id);
  const [itemsState, setItemsState] = useState<Photo[]>([]);
  const { data: images } = useReviewImages(id);
  const [mode, setMode] = useState(MODE.EDIT);
  const [saveOrder, setSaveOrder] = useState(false);
  useEffect(() => {
    if (images) {
      setItemsState(images);
      defaultImages.current = images;
    }
  }, [images]);
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setItemsState((prevItems) => {
      const newItems = [...prevItems];
      newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);
      return newItems;
    });
    setSaveOrder(true);
  };

  const handleSaveOrder = () => {
    const order = itemsState.map((item, index) => ({ id: item.id, order: index }));
    updateImagesOrder.mutate(order);
    console.log(order);
    setSaveOrder(false);
  };

  return (
    <Box mt={4}>
      {itemsState.length === 0 ? <Text>No images to show </Text>
        : (
          <>
            <Tooltip label="Edit">
              <IconButton
                mr={4}
                icon={<Icon boxSize={4} as={CiEdit} />}
                colorScheme={mode === MODE.EDIT ? "blue" : "gray"}
                aria-label="edit"
                onClick={() => {
                  setMode(MODE.EDIT);
                  setItemsState(defaultImages.current);
                  // if (isDeleted === Status.soft_delete) {
                  //   searchParams.set("isDeleted", Status.active);
                  // } else {
                  //   searchParams.set("isDeleted", Status.soft_delete);
                  // }
                  // setSearchParams(searchParams);
                }}
              />
            </Tooltip>
            <Tooltip label="Arrange">
              <IconButton
                mr={4}
                icon={<Icon boxSize={4} as={CgArrowsExchangeAlt} />}
                colorScheme={mode === MODE.ORDER ? "blue" : "gray"}
                aria-label="order"
                onClick={() => {
                  setMode(MODE.ORDER);
                }}
              />
            </Tooltip>

            {mode === MODE.EDIT
              && (
                <Flex flexWrap="wrap" gap={6} mt={4}>
                  {itemsState.map((value, index) => (
                    <GridItem
                      key={`item-${value.id}`}
                      boxShadow="md"
                      p={4}
                      // borderWidth={1}
                      // borderColor="gray.600"
                      w="150px"
                      _hover={{
                        cursor: "pointer",
                      }}
                      height="150px"
                    >
                      <Image
                        src={value.image}
                        objectFit="contain"
                        height="100%"
                        margin="auto"
                        verticalAlign="middle"
                        onClick={() => {
                          setSelectedImage(value);
                          onOpen();
                        }}

                      />
                    </GridItem>
                  ))}
                </Flex>
              )}
            {mode === MODE.ORDER
              && (
                <>
                  <Button colorScheme="teal" onClick={handleSaveOrder} disabled={!saveOrder}>
                    Save order
                  </Button>
                  <SortableGrid onSortEnd={onSortEnd} axis="xy">
                    {itemsState.map((value, index) => (
                      <SortableGridItem key={`item-${value.id}`} index={index} value={value} />
                    ))}
                  </SortableGrid>
                </>
              )}
          </>
        )}
      <ModalEditPhoto isOpen={isOpen} onClose={onClose} image={selectedImage} />
    </Box>
  );
}

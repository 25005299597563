import React, { Suspense, useState } from "react";
import { CellValue, Column } from "react-table";
import Table from "src/components/Table";
import dayjs from "dayjs";
import {
  Text,
  HStack,
  Link,
  Badge,
  Tooltip,
  IconButton,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
// import { FiEdit } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";
import { renderTripStateColor } from "src/utils/color";
import { FiMap } from "react-icons/fi";
import { isEmpty } from "lodash";
import useSearchParams from "../../utils/useSearchParams";
import { TripDocument, useTrips } from "../../api/trip";

import TripMapModal from "./TripMapModal";

function TripTable() {
  const {
    isOpen,
    onClose,
    onOpen,
  } = useDisclosure();
  const [trip, setTrip] = useState({} as TripDocument);
  const showMap = (data: TripDocument) => {
    setTrip(data);
    onOpen();
  };
  const { params } = useSearchParams();
  const { data } = useTrips(params);
  const columns = React.useMemo<Column[]>(
    () => [
      {
        Header: "Id",
        accessor: "id",
        Cell: ({ value }: any) => (
          <Text>{value}</Text>
        ),
      },
      {
        Header: "State",
        accessor: "state",
        Cell: ({ value }: CellValue) => (
          <Badge
            variant="outline"
            colorScheme={renderTripStateColor(value)}
            px="2"
            py="1"
          >
            {value}
          </Badge>
        ),
      },
      {
        Header: "Map",
        accessor: "",
        Cell: ({ row }: CellValue) => (
          <HStack spacing={4}>
            <Tooltip label="Show map">
              <IconButton
                icon={<Icon boxSize={4} as={FiMap} />}
                variant="ghost"
                colorScheme="green"
                aria-label="Show map"
                onClick={() => showMap(row.original)}
              />
            </Tooltip>
          </HStack>
        ),
      },
      {
        Header: "user id",
        accessor: "user_id",
        Cell: ({ value }: CellValue) => (
          <Link
            as={RouterLink}
            to={`/user/${value}`}
            fontWeight="semibold"
            color="blue.300"
            target="_blank"
          >
            <HStack>
              <Text>{value}</Text>
            </HStack>
          </Link>
        ),
      },
      {
        Header: "xtr reward",
        accessor: "xtr_reward",
        Cell: ({ value }: CellValue) => <Text>{value}</Text>,
      },
      {
        Header: "Distance",
        accessor: "finshed_distance",
        Cell: ({ value, row }: CellValue) => (
          <Text>
            {value}
            /
            {row.original.max_distance}
          </Text>
        ),
      },
      {
        Header: "spots",
        accessor: "spots",
        Cell: ({ value }: CellValue) => <Text>{value}</Text>,
      },
      {
        Header: "bonus token",
        accessor: "bonus_token",
        Cell: ({ value }: CellValue) => <Text>{value}</Text>,
      },
      {
        Header: "bonus spots",
        accessor: "bonus_spots",
        Cell: ({ value }: CellValue) => <Text>{value}</Text>,
      },
      {
        Header: "max token",
        accessor: "max_token",
        Cell: ({ value }: CellValue) => <Text>{value}</Text>,
      },
      {
        Header: "device id",
        accessor: "device_id",
        Cell: ({ value }: CellValue) => <Text>{value}</Text>,
      },

      {
        Header: "Create",
        accessor: "created_at",
        Cell: ({ value }: CellValue) => <Text>{dayjs(value).format("l")}</Text>,
      },

    ], // eslint-disable-next-line
    []
  );

  return (
    <>
      {!isEmpty(trip)
        && (
        <TripMapModal
          isOpen={isOpen}
          onClose={onClose}
          trip={trip}
        />
        )}
      <Table columns={columns} data={data?.items || []} />
    </>
  );
}

function TripTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <TripTable />
    </Suspense>
  );
}

export default TripTableWrapper;

import * as React from "react";
import {
  Box,
  Flex,
  HStack,
  Heading,
  Spacer,
  SimpleGrid,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import FilterBySearchKeyAndSearchType from "src/components/FilterBySearchKeyAndSearchType";
import { SEARCH_TYPE_OPTIONS, SEARCH_TYPE_PLACEHOLDER } from "src/api/user";
import SortByCreatedTime from "../../components/SortByCreatedTime";
import UserTable from "./UserTable";
import UserPaging from "./UserPaging";
import FilterByPageSize from "../../components/FilterByPageSize";
import FilterByUserState from "../../components/FilterByUserState";

import UserModalAdd from "./UserAdd";
import UserDownload from "./UserDownload";

function User() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <UserModalAdd isOpen={isOpen} onClose={onClose} />
      <Flex direction="column" p={6} h="full">
        <HStack mb={4} spacing={4}>
          <Heading size="md" textTransform="uppercase" color="gray.400">
            User
          </Heading>
          <Spacer />
          <Button onClick={onOpen} leftIcon={<AddIcon />}>
            Add user
          </Button>
        </HStack>
        <SimpleGrid
          templateRows="auto 1fr"
          borderRadius="md"
          bg="gray.800"
          flex={1}
          overflow="auto"
        >
          <Box px={5} py={4} borderBottom="1px" borderColor="gray.700">
            <Flex mb={2}>
              <HStack>
                <FilterBySearchKeyAndSearchType
                  searchTypes={SEARCH_TYPE_OPTIONS}
                  searchTypePlaceholder={SEARCH_TYPE_PLACEHOLDER}
                />
                <FilterByUserState />
                <SortByCreatedTime />
              </HStack>
              <Spacer />
              <UserDownload />
              <FilterByPageSize />
              <UserPaging />
            </Flex>
          </Box>
          <Box overflow="auto" flex={1} px={5}>
            <UserTable />
          </Box>
        </SimpleGrid>
      </Flex>
    </>
  );
}

export default User;

import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button } from "@chakra-ui/react";
import SpotTypeForm from "./SpotTypeForm";
import { SpotTypeDto, useUpdateSpotType } from "../../api/spot";

const FORM_ID = "add-spot";

interface SpotModalAddProps {
  isOpen: boolean;
  onClose: () => void;
  editingData: SpotTypeDto;
}

function SpotModalEdit({ isOpen, onClose, editingData }: SpotModalAddProps) {
  const mutation = useUpdateSpotType();

  const onSubmit = async (values: SpotTypeDto) => {
    mutation.mutateAsync(values);
    onClose();
  };

  const isEdit = true;

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit spot type</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SpotTypeForm
            initialValues={{ ...editingData }}
            onSubmit={onSubmit}
            formId={FORM_ID}
            isEdit={isEdit}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SpotModalEdit;

import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useDisclosure,
  PopoverFooter,
  ButtonGroup,
  Flex,
  Input,
  Box,
  Text,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Suspense, useMemo, useState } from "react";
import { DATE_FORMAT } from "src/constants/date";

interface DateRanger {
  fromDate: string,
  toDate: string
}
interface FilterByRangerDateProps {
  handleDeleteFilter?: () => void,
  handleApplyFilter?: (fromDate, toDate) => void,
  date?: DateRanger,
  isCloseClickOutside?: boolean
}

function Filter({
  handleDeleteFilter,
  handleApplyFilter,
  date,
  isCloseClickOutside = true,
}: FilterByRangerDateProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fromDate, setFromDate] = useState(date ? date.fromDate : searchParams.get('start_date'));
  const [toDate, setToDate] = useState(date ? date.toDate : searchParams.get('end_date'));

  const fromDateParams = date ? date.fromDate : searchParams.get('start_date');
  const toDateParams = date ? date.toDate : searchParams.get('end_date');

  const displayDate = useMemo(() => {
    if (fromDateParams && toDateParams) {
      return `${dayjs(fromDateParams).format(DATE_FORMAT)} - ${dayjs(toDateParams).format(DATE_FORMAT)}`;
    }

    if (fromDateParams) {
      return `>= ${dayjs(fromDateParams).format(DATE_FORMAT)}`;
    }

    if (toDateParams) {
      return `<= ${dayjs(toDateParams).format(DATE_FORMAT)}`;
    }

    return 'Date';
  }, [fromDateParams, toDateParams]);

  const currentDate = dayjs().format('YYYY-MM-DD');

  return (
    <Popover onClose={isCloseClickOutside ? onClose : undefined} isOpen={isOpen} size="2xl">
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={2.5} />}
          colorScheme="gray"
          color={(fromDate || toDate) ? "blue.500" : undefined}
          variant="ghost"
          // textTransform="capitalize"
          onClick={onOpen}
        >
          {displayDate}
        </Button>
      </PopoverTrigger>
      <PopoverContent w="500px">
        <PopoverCloseButton onClick={onClose} />
        <PopoverHeader>Date</PopoverHeader>
        <PopoverBody>
          <Flex py="20px">
            <Input
              value={fromDate || ""}
              onChange={(e) => {
                if (e.target.value <= currentDate && (!toDate || e.target.value <= toDate)) {
                  setFromDate(e.target.value);
                }
              }}
              type="date"
              placeholder="From date"
              borderRadius="6px"
              w="220px"
              max={toDate || currentDate}
            />

            <Box mx="4">
              <Text>-</Text>
            </Box>

            <Input
              value={toDate || ""}
              onChange={(e) => {
                if (e.target.value <= currentDate && (!fromDate || e.target.value >= fromDate)) {
                  setToDate(e.target.value);
                }
              }}
              type="date"
              placeholder="To date"
              borderRadius="6px"
              w="220px"
              min={fromDate || undefined}
              max={currentDate}
            />
          </Flex>
        </PopoverBody>
        <PopoverFooter>
          <Flex justifyContent="space-between">
            <ButtonGroup size="sm">
              <Button
                variant="outline"
                colorScheme="blue"
                onClick={() => {
                  if (handleDeleteFilter) {
                    handleDeleteFilter();
                  } else {
                    setFromDate('');
                    setToDate('');
                    searchParams.delete('start_date');
                    searchParams.delete('end_date');
                    setSearchParams(searchParams);
                  }

                  onClose();
                }}
              >
                Delete
              </Button>
            </ButtonGroup>
            <ButtonGroup size="sm">
              <Button
                colorScheme="blue"
                onClick={() => {
                  if (handleApplyFilter) {
                    handleApplyFilter(fromDate, toDate);
                  } else {
                    if (fromDate) {
                      searchParams.set('start_date', `${fromDate}`);
                    } else {
                      searchParams.delete('start_date');
                    }

                    if (toDate) {
                      searchParams.set('end_date', `${toDate}`);
                    } else {
                      searchParams.delete('end_date');
                    }

                    setSearchParams(searchParams);
                  }

                  onClose();
                }}
              >
                Apply
              </Button>
            </ButtonGroup>
          </Flex>

        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterByRangerDate({
  handleDeleteFilter,
  handleApplyFilter,
  date,
  isCloseClickOutside = true,
}: FilterByRangerDateProps) {
  return (
    <Suspense
      fallback={(
        <Button variant="ghost" isLoading>
          Date
        </Button>
      )}
    >
      <Filter
        handleDeleteFilter={handleDeleteFilter}
        handleApplyFilter={handleApplyFilter}
        date={date}
        isCloseClickOutside={isCloseClickOutside}
      />
    </Suspense>
  );
}

export default FilterByRangerDate;

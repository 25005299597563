import { ACCESS_TOKEN } from "src/constants/localStorage";

export function isLoggedIn() {
  return !!localStorage.getItem(ACCESS_TOKEN);
}

export function formatStringToNumber(value, isComma = true) {
  if (!value && value !== 0) {
    return '-';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(value).replace(/,/g, isComma ? ',' : '.');
}

export const getFileName = (url) => url?.split('/').pop();

import {
  Button, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast,
} from '@chakra-ui/react';
import * as React from 'react';
import { useUpdateMintConfig } from 'src/api/item';
import { IMintConfig, IMintConfigDto } from 'src/interfaces/mint-config';
import MintConfigForm from './MintConfigForm';

interface MintConfigModalEditProps {
  isOpen: boolean;
  onClose: () => void;
  editingData?: IMintConfig;
}
const FORM_ID = 'edit-config';

function MintConfigModalEdit({ isOpen, onClose, editingData }: MintConfigModalEditProps) {
  const toast = useToast({ position: 'top' });
  const updateMintConfigMutation = useUpdateMintConfig();
  const onSubmit = async (values: IMintConfig) => {
    let totalPosibility = 0;
    const posibility = values?.posibility?.map((p) => {
      totalPosibility += +p.percentage; return { ...p, percentage: +p.percentage };
    });
    if (totalPosibility !== 100) {
      toast({ status: 'error', description: "Invalid posibility! Total posibilities must be equal 100" });
      return;
    }

    try {
      await updateMintConfigMutation.mutateAsync({
        id: values.id,
        body: { ...values, posibility } as IMintConfigDto,
      });
      toast({ status: "success", description: "Mint config has been saved" });
    } catch (e) {
      toast({ status: "success", description: "Have error when saving config. Please try again later!" });
    } finally {
      onClose();
    }
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Mint Config</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MintConfigForm onSubmit={onSubmit} initialValues={editingData} formId={FORM_ID} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>Cancel</Button>
          <Button type="submit" colorScheme="blue" form={FORM_ID}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
export default MintConfigModalEdit;

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button } from "@chakra-ui/react";
import CustomerForm from "./UserForm";
import { CreateUserDto, useCreateUser } from "../../api/user";

const FORM_ID = "add-spot";

interface CustomerModalAddProps {
  isOpen: boolean;
  onClose: () => void;
}

function CustomerModalAdd({ isOpen, onClose }: CustomerModalAddProps) {
  const mutation = useCreateUser();

  const onSubmit = async (values: CreateUserDto) => {
    mutation.mutateAsync(values);
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add user</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CustomerForm onSubmit={onSubmit} formId={FORM_ID} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost" mr={3}>
            Cancel
          </Button>
          <Button form={FORM_ID} type="submit" colorScheme="blue">
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CustomerModalAdd;
